import React, { Component } from 'react';
import { CommonUtil } from '../utils/CommonUtil';
import Parser from 'html-react-parser';
import axios from 'axios';
import { PremiumUtil } from '../utils/PremiumUtil.js';
import {Settings} from '../Settings.js';

class SiteHeader extends Component {
  constructor(props) {
    super(props);
    const searchPrompt = CommonUtil.findHeaderString('Search Header');
    const switchLabel = CommonUtil.findHeaderString('Switch Label');

    //[TODO] if we dont initialize here, it goes to buy-premium and throw here
    //we need to fix this
    this.state = {
      searchPrompt,
      switchLabel,
      translatedSearchPrompt: Parser(searchPrompt),
      translatedSwitchLabel: Parser(switchLabel),
      message: "",
      errorTranslation: true,
      toggleEnabled: false,
      safeSearchToggled: localStorage.getItem('safeSearchToggled') === 'true',
    };
    this.isGCELoaded = false;
    this.translateLang = CommonUtil.lang;
  }

  componentDidMount() {    
    this.translateLang = CommonUtil.lang;
    if (!CommonUtil.IsHomePage(this.props.location)) {
      CommonUtil.loadGCE();
      this.setState(c => {
        c.toggleEnabled = !CommonUtil.IsEnglishLang();
      }, this.getTranslation());
    }
  }
  componentDidUpdate(prevProps) {
    if(!this.isGCELoaded) {
      CommonUtil.loadGCE();
      this.isGCELoaded = true;
    }
    if(this.translateLang !== CommonUtil.lang) {
      this.translateLang = CommonUtil.lang;
      this.getTranslation();      
    }
  }
  parseEnTranslation() {
      this.setState( c => {
          c.translatedSearchPrompt = Parser(this.state.searchPrompt);
          c.translatedSwitchLabel = Parser(this.state.switchLabel);
          return c;
    });
  }
  getTranslation() {
    if (CommonUtil.IsEnglishLang()) {
      this.parseEnTranslation();
      return;
    }

    const titleListString = "search/searchPrompt@search/switchLabel";
    const titleDict = {
                       "search/searchPrompt": this.state.searchPrompt,
                       "search/switchLabel": this.state.switchLabel
                      };
                        
    const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
    let hasCachedText;
    axios.get(checkTextURL) // check if we need to send text over
    .then((response) => {
      hasCachedText = response.data;
    }, (error) => {
      hasCachedText = false;
    })
    .finally(() => {
      const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=header`
                            : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=header`;
      const data = (hasCachedText) ? null : titleDict;
      axios.post(translateURL, data)
      .then((response) => {
        this.setState( c => {
          c.translatedSearchPrompt = Parser(response.data["search/searchPrompt"]);
          c.translatedSwitchLabel = Parser(response.data["search/switchLabel"]);
          c.message = "";
          c.errorTranslation = false;
          return c;
        });
      }, (error) => {
        this.setState( c => {
          c.message = "API error, could not translate";
          c.errorTranslation = true;
          return c;
        });
      })
   });
  }  
  handleLanguageToggle() {
    if (CommonUtil.oldLang) {
      CommonUtil.SetBackOldLang();
    } else {
      CommonUtil.oldLang = CommonUtil.lang;
      CommonUtil.SetLanguage("en");
    }    
    let newpath = CommonUtil.UpdateLangPath(this.props.location, CommonUtil.lang, CommonUtil.oldLang);
    this.props.history.push(newpath);
  }
  handleSafeSearchToggle() {
    this.setState(c => {
        localStorage.setItem('safeSearchToggled', !c.safeSearchToggled);
        return {safeSearchToggled: !c.safeSearchToggled}
    });
  }

  render() {
    let toggledClass = CommonUtil.oldLang ? 'slider-toggled' : 'slider-untoggled';
    if (CommonUtil.IsHomePage(this.props.location)) {
      return ("");
    }
    return (
        <div className="search-searchbar-custom-header-wrap">
          <div className="search-searchbar-custom-header">
            <div className="search-searchbox-text">
                <a className="header item" href="/">
                  <img src="/images/Blur_logo_white.png" className="ui image navmenu-logo-img" style={{width: "100px", height: "auto", marginBottom: "2px"}} />
                </a>
              </div>
              {this.state.toggleEnabled && !CommonUtil.IsBlogPage(this.props.location) ?  
                  <div className="search-english-switch" onClick={this.handleLanguageToggle.bind(this)}>
                      <label className="switch">
                          <span className={`slider round search-slider ${toggledClass}`}></span>
                      </label>
                      <span style={{marginLeft: '7px', color: 'white'}}>{this.state.translatedSwitchLabel}</span>
                  </div> 
              : ""}
          </div>
        </div>
    );
  }
}

export default SiteHeader;