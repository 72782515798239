import React, { Component } from 'react';
import { Container, Header, Table } from 'semantic-ui-react';

class PremiumPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    //console.log("premium page");
    return (
      <div>
        <Container>
          <div className="page-header">
            <Header as='h1'>
              Premium Page
            </Header>
          </div>
          <p><i><small>Updated: October 17, 2019</small></i></p>
          <br/>
          <p>
            <strong>What did you do?</strong>
          </p>
          <p>
            This shows how your usage of Blur.live compares to other customers like you. 
          </p>
          <br/>
        </Container>
      </div>
    );
  }
}

export default PremiumPage;