import React, { Component } from 'react';
import {Container, Menu, Form, Dropdown, Button} from 'semantic-ui-react';
import { PremiumUtil } from '../utils/PremiumUtil.js';
import { ReactGAInstance } from '../connectors/ReactGAInstance.js';


class ManageEnrollment extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        subscriptions: [],
        selectedSubscriptionId: "",
        selectedEnrollmentId: "",
        selectedUser: "",
        email: "",
        activeItem: "browsers",
        browserError: "",
        userError: "",
        blurIdError: "",
      }
    }
    componentDidMount(){
      this.init();
    }
    init() {
      this.resetErrors();
      var subscriptions = PremiumUtil.GetOwnedSubscriptions();
      if(subscriptions.length > 0) {
        this.setState(c=> {
          c.subscriptions = subscriptions;
          if(!this.state.selectedSubscriptionId) {
            c.selectedSubscriptionId = subscriptions[0].id;
          }
          c.selectedEnrollmentId = "";
          c.selectedUser = "";
          return c;
        }, this.loadBlurIds.bind(this));
      }
    }
    resetErrors() {
      this.setState(c=> {
        c.blurIdError = "";
        c.browserError = "";
        c.userError = "";
        return c;
      })
    }
    loadBlurIds() {
      if(!this.state.selectedSubscriptionId) {
        return "";
      }
      this.resetErrors();
      this.setState(c=> {
        c.isLoading = true;
        return c;
      })
      PremiumUtil.LoadBlurIdsDetail(this.state.selectedSubscriptionId).then((resp) => {
        this.setState(c=> {
          c.isLoading = false;
          return c;
        })
      }).catch((err) => {
        this.setState(c=> {
          c.isLoading = false;
          c.blurIdError = PremiumUtil.Translate("ppErrorInLoadingMsg", "Error in loading, please try again!");
          return c;
        })
      });
    }
    autoEnroll() {
      if(!this.state.email) {
        return;
      }
      this.resetErrors();
      if(!PremiumUtil.IsEmailValid(this.state.email)) {
        this.setState(c=> {
          c.userError = PremiumUtil.Translate("ppEnterEmailInFormatMsg", "Please enter email in format name@example.com");
          return c;
        });
        return;
      }
      let subscObj = PremiumUtil.GetOwnedSubscription(this.state.selectedSubscriptionId);
      if(!subscObj) {
        return ("");
      }
      let userList = subscObj.autoEnrollEmailList ? subscObj.autoEnrollEmailList.map((el) => {return el}) : [];
      userList.push(this.state.email);
      this.setState(c=> {
        c.isLoading = true;
        return c;
      });
      PremiumUtil.Invite(this.state.selectedSubscriptionId, userList).then(resp => {
        PremiumUtil.GetWhoAmI().then(resp => {}).catch(err => {}).then(() => {
          this.setState(c=> {
            c.isLoading = false;
            c.selectedUser = "";
            c.email = "";
            return c;
          });
        });
      }).catch(err => {
        this.setState(c=> {
          c.isLoading = false;
          c.userError = PremiumUtil.Translate("ppErrorInInvitingUserMsg", "Error in inviting user, please try again!");
          return c;
        })
      });
    }
    deleteAutoEnrollment() {
      if(!this.state.selectedUser) {
        return;
      }
      this.resetErrors();
      let subscObj = PremiumUtil.GetOwnedSubscription(this.state.selectedSubscriptionId);
      if(!subscObj || !subscObj.autoEnrollEmailList || subscObj.autoEnrollEmailList.length < 1) {
        return ("");
      }
      let userList = subscObj.autoEnrollEmailList.filter((value, index, arr) => { return (value != this.state.selectedUser) });
      this.setState(c=> {
        c.isLoading = true;
        return c;
      });
      PremiumUtil.Invite(this.state.selectedSubscriptionId, userList).then(resp => {
        PremiumUtil.GetWhoAmI().then(resp => {}).catch(err => {}).then(() => {
          this.setState(c=> {
            c.isLoading = false;
            c.selectedUser = "";
            return c;
          });
        });
      }).catch(err => {
        this.setState(c=> {
          c.isLoading = false;
          c.userError = PremiumUtil.Translate("ppErrorDeletingEnrollmentMsg", "Error deleting enrollment, please try again!");
          return c;
        })
      });
    }
    unEnrollBrowser() {
      if(!this.state.selectedEnrollmentId) {
        return;
      }
      this.resetErrors();
      this.setState(c=> {
        c.isLoading = true;
        return c;
      })
      PremiumUtil.UnEnrollThem(this.state.selectedEnrollmentId, this.state.selectedSubscriptionId).then(resp => {
        this.init();
      }).catch(err => {
        this.setState(c=> {
          c.isLoading = false;
          c.browserError = PremiumUtil.Translate("ppErrorInUnenrollingBrowserMsg", "Error in unenrolling browser, please try again!");
          return c;
        })
      }).then(() => {
        PremiumUtil.PingExtension();
      });
    }
    handleSubscriptionDropdown(evt, {value}) {
      this.resetErrors();
      this.setState(c=> {
        c.selectedSubscriptionId = value;
        c.selectedUser = "";
        c.selectedEnrollmentId = "";
        return c;
      }, this.loadBlurIds.bind(this))
    }
    handleEnrollmentDropdown(evt, {value}) {
      this.resetErrors();
      this.setState(c=> {
        c.selectedEnrollmentId = value;
        return c;
      })
    }
    handleUserDropdown(evt, {value}) {
      this.resetErrors();
      this.setState(c=> {
        c.selectedUser = value;
        return c;
      })
    }
    handleAutoEmail(evt, {value}) {
      this.resetErrors();
      this.setState(c=> {
        c.email = value;
        return c;
      })
    }
    renderSubscriptions() {
      if(this.state.subscriptions.length < 2) {
        return ("");
      } 
      return (
        <div className="popup_header">
          <Dropdown
            placeholder={PremiumUtil.Translate("ppSelectSubscription", "Select Subscription")}
            fluid
            selection
            value={this.state.selectedSubscriptionId}
            onChange={this.handleSubscriptionDropdown.bind(this)}
            options={this.state.subscriptions.map((subsc, ix) => {return {key: ix, value: subsc.id, text: PremiumUtil.Translate("ppSubscription", "Subscription -") + " " + (ix+1)}})}
          />
        </div>
      )
    }
    renderBrowserEnrolled() {
      if(this.state.blurIdError) {
        return PremiumUtil.RenderError(this.state.blurIdError);
      }
      if(this.state.activeItem !== 'browsers') {
        return("");
      }
      ReactGAInstance.PageView("/premium/popup/manageBrowsers");
      let blurIdList = PremiumUtil.GetBlurIdsDetails();
      return [
        <Dropdown
          fluid
          selection
          placeholder={PremiumUtil.Translate("ppSelectBrowserEnrollment", "Select Browser enrollment")}
          value={this.state.selectEnrollment}
          onChange={this.handleEnrollmentDropdown.bind(this)}
          options={blurIdList}
        />
      ]
    }
    renderUserEnrolled() {
      if(this.state.activeItem !== 'users' || !this.state.selectedSubscriptionId) {
        return ("");
      }
      ReactGAInstance.PageView("/premium/popup/manageUsers");
      let subscObj = PremiumUtil.GetOwnedSubscription(this.state.selectedSubscriptionId);
      if(!subscObj) {
        return (
          <div className="ui info message center aligned grid premium-info">
            <p>
              {PremiumUtil.Translate("ppYouDontOwnAnySubscriptionMsg", "You dont own any subscription, please subscribe using 'Manage Payment' link")}
            </p>
          </div>
        );
      }
      var userList = subscObj.autoEnrollEmailList ? subscObj.autoEnrollEmailList : [];
      var remLicense = subscObj.numLicenses - userList.length;

      if(subscObj.numLicenses < 2) {
        return (
          <div className="ui info message center aligned grid premium-info">
            <p>
              {PremiumUtil.Translate("ppYouDontHaveMultipleUserSubscriptionMsg", "You dont have multiple user subscription, please change using 'Manage Payment' link")}
            </p>
          </div>
        )
      }
      let userOptions = [];
      if(remLicense > 1) {
        userOptions = [{key:0, value:"new", text: PremiumUtil.Translate("ppAddNew", "Add New")}];
      }
      for(var ix in userList) {
        userOptions.push({key: (ix+2), value: userList[ix], text: userList[ix]});
      }
      return [
        <Dropdown
          fluid
          selection
          placeholder={PremiumUtil.Translate("ppSelectUser", "Select User")}
          value={this.state.selectedUser}
          onChange={this.handleUserDropdown.bind(this)}
          options={userOptions}
        />
      ]
    }
    renderBrowserActions() {
      //do this when user is added
      if(this.state.activeItem !== 'browsers' || !this.state.selectedEnrollmentId) {
        return ("");
      }
      let subscName = "";
      let blurIdList = PremiumUtil.GetBlurIdsDetails();
      for(let ix in blurIdList) {
        if(blurIdList[ix].value === this.state.selectedEnrollmentId) {
          subscName = blurIdList[ix].text;
          break;
        }
      }
      return [
        PremiumUtil.RenderError(this.state.browserError),
        <Button fluid color='google plus' className="btm_button" onClick={this.unEnrollBrowser.bind(this)}>{PremiumUtil.Translate("ppUnenrollBrowser", "Unenroll browser")}</Button>
      ]
    }
    renderUserActions() {
      if(this.state.activeItem !== 'users' || !this.state.selectedUser) {
        return ("");
      }
      if(this.state.selectedUser === "new") {
        return [
          <p>&nbsp;</p>,
          <Form.Input
            fluid
            name="email"
            placeholder={PremiumUtil.Translate("ppAutoEnrollEmail", "Auto enroll email")}
            value={this.state.email}
            error={this.state.emailSyntaxError}
            onChange={this.handleAutoEmail.bind(this)}
          />,
          PremiumUtil.RenderError(this.state.userError),
          <Button className="btm_button" fluid color='blue' disabled={!this.state.email} 
            onClick={this.autoEnroll.bind(this)}>
              {PremiumUtil.Translate("ppEnrollUser", "Enroll user")}
          </Button>
        ]        
      }
      return [
          PremiumUtil.RenderError(this.state.userError),
          <Button fluid color='google plus' className="btm_button" onClick={this.deleteAutoEnrollment.bind(this)}>
            {PremiumUtil.Translate("ppRemoveUserEnrollment", "Remove user enrollment")}
          </Button>
      ]
    }
    renderTabs() {
      return (
        <Menu tabular fluid>
          <Menu.Item
            name={PremiumUtil.Translate("ppBrowserEnrollments", "Browser Enrollments")}
            active={this.state.activeItem === 'browsers'}
            onClick={() => {this.setState(c => {c.activeItem = 'browsers'; return c;})}}
          />
          <Menu.Item
            name={PremiumUtil.Translate("ppUserEnrollments", "User Enrollments")}
            active={this.state.activeItem === 'users'}
            onClick={() => {this.setState(c => {c.activeItem = 'users'; return c;})}}
          />      
        </Menu>
      )
    }
    render() {
      if(this.state.isLoading) {
        return (
          <div className="ui inverted active dimmer">
            <div className="ui massive text loader">{this.state.loadingMessage}</div>
          </div>
        )
      }
      //now display the unenrollemnt
      return (
        <Form className="enrollForm">
          {this.renderSubscriptions()}
          {this.renderTabs()}
          {this.renderBrowserEnrolled()}
          {this.renderBrowserActions()}
          {this.renderUserEnrolled()}
          {this.renderUserActions()}
        </Form>
      )
    }
}

export default ManageEnrollment;
