import React, { Component } from 'react';
import {Grid, Icon} from 'semantic-ui-react';
import { PremiumUtil } from '../utils/PremiumUtil';

class PremiumHeader extends Component {
    goBack() {
      PremiumUtil.View = "";
      this.props.reRender();
    }
    render() {
      var header = "";
      if(PremiumUtil.View === "manageEnrollment") {
        header = PremiumUtil.Translate("ppManageEnrollment", "Manage Enrollment");
      }
      if(PremiumUtil.View === "emailVerification") {
        header = PremiumUtil.Translate("ppVerifyEmail", "Verify Email");
      }
      if(PremiumUtil.View === "signIn") {
        header = PremiumUtil.Translate("ppSignIn", "Sign In");
      }
      if(PremiumUtil.View) {
        return (
          <Grid.Row className="headerAction">
            <Grid.Column width="2" className="hIcon">
              <Icon name='angle left' size='big' onClick={this.goBack.bind(this)}/>
            </Grid.Column>
            <Grid.Column width="13" className="htext">
              {header}
            </Grid.Column>
          </Grid.Row>
        )
      }
      return (
        <Grid.Row >
          <img src="/images/Blur_logo.png" alt="Blur Logo" height="40" width="150"></img>
        </Grid.Row>
      )
    }
}

export default PremiumHeader;
