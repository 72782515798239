import React, { Component } from 'react';
import { Container, Grid, Header, Accordion, Icon, Divider, Segment} from 'semantic-ui-react';
import axios from 'axios';
import Violation from './Violation';
import AppsImage from './AppsImage';
import windowSize from 'react-window-size';
import { Settings } from './Settings';
import MetaTags from 'react-meta-tags';
import InstallAd from './premium/InstallAd';
import AppSearchBar from './AppSearchBar';
import Parser from 'html-react-parser';

import './theme/custom.css';
import { CommonUtil } from './utils/CommonUtil';

class Apps extends Component {
  constructor(props) {
    super(props);
    const cracksiteHeading = 'Do not visit this website - It distributes cracked/nulled software.';
    const phishsiteHeading = 'Do not visit this website - It is a phishing attempt.'
    const tricksiteHeading = 'Do not visit this website - It is a scam attempt.';
    const violationsHeading = 'Why you should avoid installing this app';
    const numAntiVirusesHeading = ' anti-virus companies flag this app as deceptive';
    const deceptorHeading = 'Do not install this app version - it is a Deceptor!';

    this.state = {
      isTranslation: false,
      isSignedIn: false,
      userProfile: {},
      isDevMode: false,
      activeDropdowns: {
        0: true, 
        1: true,
        2: true,
        3: true
      },
      date: '',
      loading: true,
      encyclopediaText: null,
      translatedViolationsHeading : Parser(violationsHeading),
      translatedNumAntiVirusesHeading: Parser(numAntiVirusesHeading),
      translatedDeceptorHeading: Parser(deceptorHeading),
      translatedCracksiteHeading: Parser(cracksiteHeading),
      translatedPhishsiteHeading: Parser(phishsiteHeading),
      translatedTricksiteHeading: Parser(tricksiteHeading),
      violationsHeading,
      numAntiVirusesHeading,
      deceptorHeading,
      cracksiteHeading,
      phishsiteHeading,
      tricksiteHeading,
      message: "",
      errorTranslation: true
    };
      
    this.errorText = "";
    this.myText = "";
    this.certified = false;
    this.vrs = 0;

    this.majorViolations = [];
    this.minorViolations = [];
    this.logoLinks = [];

    this.secondarySource = false; //Secondary sources, prefixed with "AE-".
    
    this.appName = "";
    this.companyName  = "";

    this.landingPageLink = ""; //deceptors *and* certified
    this.type = "";
    this.avBlockList = [];
    this.avAllowList = [];
    this.avBDate = "";
    this.avADate = "";
    
    this.valueImages = [];
    this.offerImages = [];
    this.interactionImages = [];
    this.logoImages = [];
    this.deceptorReasons = [];
    this.metaDescription = "";

    this.majorViolationsTranslated = [];
    this.minorViolationsTranslated = [];
    this.avBDateTranslated = "";
    this.avADateTranslated = "";

    this.translateLang = CommonUtil.lang;
    // unused variables
    // this.minorViolationIntroText = "";
    // this.violationIntroText = "";
    // this.targetOS = "";
    // this.familySentence = "";
    // this.browserSentence = ""
    // this.monetizationSentence= "";
  }

    componentDidMount() {
      this.appId = this.props.match.params.appId;
      this.getAppInfo();
      this.setTranslationVariable();

      //Used for some headings
      this.getTranslation();
    }
    componentDidUpdate(prevProps) {
      if(this.translateLang !== CommonUtil.lang) {
        this.translateLang = CommonUtil.lang;
        let is = CommonUtil.IsEnglishLang();
        this.initVariables();
        this.setTranslationVariable();

        //Used for some headings
        this.getTranslation();

        this.setState( c => {
          c.encyclopediaText = null;
          return c;
        }, this.getAppInfo.bind(this));
      }
    }
    setTranslationVariable() {
      if (!CommonUtil.IsEnglishLang()) {
        this.setState( c => {
          c.isTranslation = true;
          return c;
        });
      } else {
        this.setState( c => {
          c.isTranslation = false;
          return c;
        });
      }
    }
    initVariables() {
      this.errorText = "";
      this.myText = "";
      this.certified = false;
      this.vrs = 0;

      this.majorViolations = [];
      this.minorViolations = [];
      this.logoLinks = [];
      
      this.appName = "";
      this.companyName  = "";

      this.landingPageLink = ""; //deceptors *and* certified
      this.type = "";
      this.avBlockList = [];
      this.avAllowList = [];
      this.avBDate = "";
      this.avADate = "";
      
      this.valueImages = [];
      this.offerImages = [];
      this.interactionImages = [];
      this.logoImages = [];
      this.deceptorReasons = [];
      this.metaDescription = "";

      this.majorViolationsTranslated = [];
      this.minorViolationsTranslated = [];
      this.avBDateTranslated = "";
      this.avADateTranslated = "";
    }
    setTranslationLang() {
      PremiumUtil.SetBlurMessages('', ',', CommonUtil.IsEnglishLang() ? "" : this.translateLang);
    }
    getAppInfo() {
      axios.get(Settings.AppInfoUrl, { 'params': {'id': this.appId, 'toLang': CommonUtil.IsEnglishLang() ? "" : this.translateLang}}).then((response) => {
        this.setState( c => {
          c.encyclopediaText = response.data.encyclopediaText;
          c.loading= false;
          return c;
        }, this.buildWebPage.bind(this, response.data));
      }).catch(this.errorProcessing.bind(this));
    }

    handleClick = (e, titleProps) => {
      const { index } = titleProps;
      let { activeDropdowns } = this.state;
      activeDropdowns[index] = !activeDropdowns[index];
      this.setState({ activeDropdowns: activeDropdowns });
    }

    buildDeceptor(r){
      this.avBlockList = this.buildBlockList(r);
      this.avAllowList = this.buildAllowList(r);
      
      this.createViolationList(r);

      // These variables were assigned but never used, do somthing with it in the future?
      // this.targetOS = this.getTargetOSList(r);
      // this.minorViolationIntroText = this.createMinorViolationIntroText(r);
      // this.familySentence = this.createFamilySentence(r);
      // this.browserSentence = this.createBrowserSentence(r);
      // if(r.app.targetCustomer === "consumer"){
      //   this.customerSentence = "It's designed for consumers";
      // }

      // if(this.getViolationNum(r) === 1){
      //   this.violationIntroText = " 1 major violation: ";
      // }
      // else{
      //   this.violationIntroText = this.getViolationNum(r) + " major violations: ";
      // }
    }

    buildCertified(r){
      this.valueImages = this.getCertifiedImages(r, 'Values');
      this.offerImages = this.getCertifiedImages(r, 'Offers');
      this.interactionImages = this.getCertifiedImages(r, 'Interactions');

      this.logoImages = this.getCertifiedImages(r, "Logo", "Logos");
      this.getLogoLink(r);
    }

    renderMonetizationList() { // this.monetizationList
      if (!Array.isArray(this.monetizationList)) {  // must be string
        return this.monetizationList;
      } else if (this.monetizationList.length === 1) {
        return this.monetizationList[0];
      } else {
        return (this.monetizationList.map((x) => {
          return (
            <li key={x}>{x}</li>
          )
        }));
      }
    }

    buildBlockList(r){
      let tempList = [];
      if(!r.app.avBlockList || r.app.avBlockList.length === 0)
        return tempList;
      let latestdate = r.app.avBlockList.map(el => el.since)
                                             .reduce((max, cur) => (max >= cur ? max : cur), "");
      this.avBDate = this.getDate(r, new Date(latestdate));
      if (this.state.isTranslation) {
        this.getTranslatedDate(r, new Date(latestdate)).then((date) => {this.avBDateTranslated = date});
      }
      let avlist = {};
      for(let i = 0; i < r.app.avBlockList.length; i++){
        // tempList.push(<div className="three wide column" key={i}>{r.app.avBlockList[i].av} </div>); 
        if(r.app.avBlockList[i].since === latestdate) {
          if(!avlist[r.app.avBlockList[i].av]) {
            avlist[r.app.avBlockList[i].av] = true;
            tempList.push(<li key={'Block'+i}>{r.app.avBlockList[i].av}</li>)
          }
        }
      }
      return tempList;
    }

    getBlockSection(){
      if(this.avBlockList.length > 0){
        return (
          <div id="blocked-by">
          <Grid.Row className="apps-blocked">
            <b>
              {this.state.isTranslation && this.state.encyclopediaText && this.state.encyclopediaText.translation ? this.state.encyclopediaText.translation['anti-virus'].blockheader : (this.state.encyclopediaText ? this.state.encyclopediaText['anti-virus'].blockheader : "")} (
              {this.state.isTranslation ? this.avBDateTranslated : this.avBDate}):
            </b>
          </Grid.Row>
          <Grid.Row>
            <div className={"apps-av-column-set-"+this.getIdealColumnNum(this.avBlockList.length)}>
              <ul className="ul-apps">
                {this.avBlockList}
              </ul>
            </div>
          </Grid.Row>
          </div>
        );
      }
    }

    getIdealColumnNum(length){
      if(this.props.windowWidth < 800 || length <= 10){
        return "1";
      }

      if(this.props.windowWidth < 1180 || length <= 15){
        return "2";
      }

      if(this.props.windowWidth > 1400 && length >= 20){
        return "4";
      }

      return "3";
    }

    buildAllowList(r){
      let tempList = [];
      if(!r.app.avAllowList || r.app.avAllowList.length === 0)
        return tempList;
      // get the latest date from this array with map/reduce
      let latestdate = r.app.avAllowList.map(el => el.since)
                                             .reduce((max, cur) => max >= cur ? max : cur, "");
      this.AvADate = this.getDate(r, new Date(latestdate));
      if (this.state.isTranslation) {
        this.getTranslatedDate(r, new Date(latestdate)).then((date) => {this.AvADate = date});
      }
      let avlist = {};
      for(let i = 0; i < r.app.avAllowList.length; i++){ 
        if(r.app.avAllowList[i].since === latestdate) {
          if(!avlist[r.app.avAllowList[i].av]) {
            avlist[r.app.avAllowList[i].av] = true;
            tempList.push(<li key={'Allow'+i}>{r.app.avAllowList[i].av}</li>)
          }
        }
      }
      return tempList;
    }

    getAllowSection(){
      if(this.avAllowList.length > 0){
        return (
          <div>
            <Grid.Row className="apps-blocked">
                  <b>Allowed by Anti-Virus programs (as of {this.avADate}):</b>
                </Grid.Row>

                <Grid.Row>
                  <div className={"wrapper-"+this.getIdealColumnNum(this.avAllowList.length)}>
                    <ul className="ul-apps">
                      {this.avAllowList}
                    </ul>
                  </div>
                </Grid.Row>
          </div>
        );
      }
    }

    getCertifiedImages(r, keyword1, keyword2){
      let vi = [];

      for(let j = 0; j < r.app.images.length; j++){
        if(this.theCertifiedImageContains(r.app.images[j], keyword1, keyword2)){
          if(this.isVideo(r.app.images[j])){
            vi.push(this.createAppsImage(j+r.app.images[j], r.app.images[j], r.app.images[j].id, "video"));
          }
          else{
            vi.push(this.createAppsImage(j+r.app.images[j], r.app.images[j], r.app.images[j].id, "image"));
          }
        }
      }

      return vi;
    }

    createAppsImage(keyP, sourceP, altText, typeP){
      if(typeP === "image"){
        return <AppsImage  
        key={'Image'+keyP}
        className={""}
        myType={typeP}
        src={sourceP}
        alt={altText} // SEO: AltText of ACR violation

        onClick={() => {
          var m=document.getElementById("modalid-img");
          var i=document.getElementById("modalimgid");   
          
          if(i != null){
            if(i.src === (sourceP).replace(/ /g, '%20')){
              i.src = "";
            }
            else{
              i.src = sourceP;
            }

            i.style.display = "block";
          }

          if(m != null){
            m.style.display = "block";
          }
         
        }}>
      </AppsImage>
      }
      if(typeP === "video"){
        return <AppsImage  
        key={'Video'+keyP}
        className={""}
        myType={typeP}
        src={sourceP}
        alt={"evidence of deception"}

        onClick={() => {
          var m=document.getElementById("modalid-vid");
          var v=document.getElementById("modalvideoid");

          if(v !== null){
            v.src = sourceP;
            v.style.display = "block";
          }

          if(m != null){
            m.style.display = "block";
          }
         
        }}>
      </AppsImage>
      }
      return null;
    }

    theCertifiedImageContains(imageLink, text1, text2){
      let splitText = imageLink.split('/');

      for(let i = 0; i < splitText.length; i++){
        if(splitText[i] === text1 || splitText[i] === text2){
          return true;
        }
      }

      return false;
    }

  buildWebPage(r) {
    this.secondarySource = r.app.appID.startsWith("AE-");

      this.checkCertification(r);
      this.companyName = r.app.company;
    this.appName = r.app.name + (this.secondarySource ? "" : " (" + r.app.version + ")");

      if (!this.state.isTranslation) {
        this.type = r.app.type;
        this.monetizationList = r.app.monetization;
      } else {
        this.type = r.translation.app.type;
        this.monetizationList = r.translation.app.monetization;
      }
 
      if (this.certified) {
        this.buildCertified(r);
        if (!this.state.isTranslation) {
          this.setState({ dateAdded: this.getDate(r, new Date(r.app.certificationDate)) });
        } else {
          this.getTranslatedDate(r, new Date(r.app.certificationDate)).then((date) => {this.setState({ dateAdded: date })});
        }
      } else {
        this.buildDeceptor(r);
        if (!this.state.isTranslation) {
          this.setState({ dateAdded: this.getDate(r, new Date(r.app.dateAdded)) });
        } else {
          this.getTranslatedDate(r, new Date(r.app.dateAdded)).then((date) => {this.setState({ dateAdded: date })});
        }
      }
    }

    getLogoLink(r){
      for(let i = 0; i < r.app.images.length; i++){
        if(this.isLogo(r.app.images[i])){
          this.logoLinks.push(<img  key={'Logo'+i}
                                    className="logo"
                                    src={r.app.images[i]}
                                    alt="logos"></img> );
                                    
          this.logoLink = r.app.images[i];
        }
      }
    }
    
    isLogo(imgPath){
      let imgArray = imgPath.split("/");
      
      for(let i = 0; i < imgArray.length; i++){
        if(imgArray[i] === "Logos" || imgArray[i] === "logos"){
          return true;
        }
      }
      return false;
    }

    checkCertification(r) {
        if(r.app.classification === "deceptor"){
          this.certified = false;
        }
        else {
          this.certified = true;
        }  
    }

    getDate(r, d) {
      var timestamp = d;
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      return `${timestamp.getDate()} ${monthNames[timestamp.getMonth()]} ${timestamp.getFullYear()}`;
    }

    async getTranslatedDate(r, d) {
      var date = this.getDate(r, d);
      return axios.post(Settings.TranslationURL, {message: date}, { 'params': {'fromLang': 'en', 'toLang': this.translateLang}}).then((response) => {
        return response.data.message;
      });
    }


    getViolationNum(r){ //get how many violations the app has
      return r.app.violations.filter(i => i.isDeceptor).length;
    }

    getNonDeceptorViolationNum(r){
      return r.app.violations.filter(i => !i.isDeceptor).length;
    }

    errorProcessing(error){
      this.errorText = "Data retrieval from the JSON failed. :(";
    }

    createViolationList(r) {
      for (let i = 0; i < r.app.violations.length; i++) {
        if (r.app.violations[i].isDeceptor) {
          this.majorViolations.push(this.newViolation(r, i, (i + 1) + '. '));
          if (this.state.isTranslation) { 
            this.majorViolationsTranslated.push(this.newViolation(r, i, (i + 1) + '. ', true));
          }
        }
        // else{
        //   this.majorViolations.push(this.newViolation(r, i, "", "In our review, we also found the following potential issues with " 
        //     + r.app.name + ': '));
        // }
      }
        this.createMetaDescription(r);
    }

    newViolation(r, num, heading){
      var acrId = r.app.violations[num].id;
      if (!this.state.isTranslation && r.app && r.app.violations && r.app.violations[num] && r.app.violations[num].message) {   //  deceptor reasons to include in metaData
        this.deceptorReasons.push(r.app.violations[num].message)
      } else 
      if (r.translation && r.translation.violations && r.translation.violations[acrId] && r.translation.violations[acrId].message) {
        this.deceptorReasons.push(r.translation.violations[acrId].message)
      }
      return (
        <Violation  
          number={num}
          key={'Violation'+num}
          violationNum={heading + acrId} //the acr tag of the violation // Now using ACR name, instead of violation 1, 2, etc.
          req={this.state.isTranslation && r.translation && r.translation.violations && r.translation.violations[acrId] && r.translation.violations[acrId].prescription ? r.translation.violations[acrId].prescription : (r.acrs && r.acrs[acrId] && r.acrs[acrId].prescription ? r.acrs[acrId].prescription : "")} //the rule for the acr to not be violated
          actual={this.state.isTranslation && r.translation.violations ? r.translation.violations[acrId].message : (r.app && r.app.violations ? r.app.violations[num].message : "")} //the details of how the app broke the rule, and created a violation
          mimg={this.newViolationImages(r, num)}
          headerTranslation={this.state.isTranslation && r.translation && r.translation.violations && r.translation.violations.header ? r.translation.violations.header : null}
        ></Violation>); 
    }
    
    createMetaDescription(r) { // header1: AppName classifies as a + (Deceptor)
                               // header2: because + (reasons)
      if (!this.state.isTranslation && r.app && r.app.name && r.encyclopediaText &&  r.encyclopediaText.metadata) {
        this.metaDescription = r.app.name + r.encyclopediaText.metadata.header1 + "Deceptor" + r.encyclopediaText.metadata.header2;
      } else 
      if (r.app && r.app.name && r.encyclopediaText && r.encyclopediaText.translation && r.encyclopediaText.translation.metadata) {
        this.metaDescription = r.app.name + r.encyclopediaText.translation.metadata.header1 + "Deceptor" + r.encyclopediaText.translation.metadata.header2;
      }
      this.deceptorReasons.forEach(reason => {
        this.metaDescription += reason;
      });
    }
    
    newViolationImages(r, num){
      let mimgs = [];

      for(let i = 0; i < r.app.violations[num].images.length; i++){
        if(this.isVideo(r.app.violations[num].images[i])){
          mimgs.push(this.createAppsImage(num+i, r.app.violations[num].images[i], r.app.violations[num].id, "video"));
        }
        else{
          mimgs.push(this.createAppsImage(num+i, r.app.violations[num].images[i], r.app.violations[num].id, "image"));
        }
      }

      return mimgs;
    }

    isVideo(textLink){
      if(textLink.length >= 4){
        if( textLink.substring(textLink.length-4, textLink.length) === ".mp4" || 
            textLink.substring(textLink.length-4, textLink.length) === ".MP4"){
              return true;
            }
      }
      return false;
    }

    theImageContains(r, i, vk){
      let link = [];
      if(i < r.app.images.length){
        link = r.app.images[i].split('/');
      }
      
      for(let j in link){
        if(link[j].length === 7){
          if(link[j].substring(4, 7) === vk.substring(4, 7)){
            return true;
          }
        }
      }

      link = [];
      if(i < r.app.nonDeceptorImages.length){
        link = r.app.nonDeceptorImages[i].split('/');
      }
      
      for(let j in link){
        if(link[j].length === 7){
          if(link[j].substring(4, 7) === vk.substring(4, 7)){
            return true;
          }
        }
      }

      return false;
    }

    getLogoSection() {
      if(this.logoImages.length > 0){
        return (
          <div>
            <div className="apps-logo-title">
                <div>{!this.state.isTranslation && this.state.encyclopediaText && this.state.encyclopediaText.certified && this.state.encyclopediaText.certified.logo ? this.state.encyclopediaText.certified.logo : 
                (this.state.encyclopediaText && this.state.encyclopediaText.translation && this.state.encyclopediaText.translation.certified && this.state.encyclopediaText.translation.certified.logo ? this.state.encyclopediaText.translation.certified.logo : "")}</div>
            </div>

            <div className="apps-section-title">
              <div className="ui grid">
                {this.logoImages}
              </div>
            </div>
          </div>
        );
      }
    }

    getOffersSection(){
      if(this.offerImages.length > 0){
        return(
          <div>
            <div className="apps-logo-title">
              <div>{!this.state.isTranslation ? this.state.encyclopediaText.certified.offer : this.state.encyclopediaText.translation.certified.offer}</div>
            </div>

            <div className="apps-section-title">
              <div className="ui grid">
                {this.offerImages}
              </div>
            </div>
          </div>
        );
      }
    }

    getValuesSection(){
      if(this.valueImages.length > 0){
        return(
          <div>
            <div className="apps-logo-title">
              <div>{!this.state.isTranslation ? this.state.encyclopediaText.certified.value : this.state.encyclopediaText.translation.certified.value}</div>
            </div>

            <div className="apps-section-title">
              <div className="ui grid">
                {this.valueImages}
              </div>
            </div>
          </div>
        );
      }
    }

    renderSystemMonitoring() {
      let descr = "";
      if (this.state.isTranslation && this.state.encyclopediaText && this.state.encyclopediaText.translation && this.state.encyclopediaText.translation.systemUtil) {
        descr = this.state.encyclopediaText.translation.systemUtil.description
      } else if (this.state.encyclopediaText && this.state.encyclopediaText.systemUtil) {
        descr = this.state.encyclopediaText.systemUtil.description;
      }
      if (descr) {
        return (
          descr.map((x) => {
            return (
              <div key={x.term}>
                <Header as='h5'>{x.term}</Header>
                  <li>{x.definition}</li>
                <br></br>
              </div>
            )
          })
        ) 
      } else return ""
    }

    renderDeceptorDescription() {
      if (this.state.encyclopediaText && this.state.encyclopediaText.translation && this.state.encyclopediaText.translation.deceptorDesc) {
        return (
          <> 
            {Parser(this.state.encyclopediaText.translation.deceptorDesc)}
          </>
        )
      } else 
      if (this.state.encyclopediaText && this.state.encyclopediaText.deceptorDesc) {
        return (
          <>
          {Parser(this.state.encyclopediaText.deceptorDesc)}
          </>
        )
      } else {
        return ""
      }
    }

    renderAccordions() {
      if (this.state.encyclopediaText && this.state.encyclopediaText.deceptorDesc) {
        return (
          <div>
            {this.state.isTranslation ? this.renderDeceptorDescription() : Parser(this.state.encyclopediaText.deceptorDesc)}
            <div className="apps-help-links">
              <div><a href="#violations">{this.state.translatedViolationsHeading}</a></div>
              <div>{this.avBlockList.length > 0 ? <a href="#avBlockList">{this.avBlockList.length} {this.state.translatedNumAntiVirusesHeading}</a> : ""}</div>
              {!this.secondarySource && <div><a href="#systemUtil">{this.renderHeader('systemUtil')}</a></div>}
            </div>
            {this.accordion()}
            <br></br><br></br>
            {<InstallAd fromPage="Apps" checkSupport={false} showLogo={true} hideDesc={false} verboseDesc={true}/>}
          </div>
        )
      } else return "";
    }

    accordion() {
      const { activeDropdowns } = this.state;
      //console.log("this.majorViolationsTranslated", this.majorViolationsTranslated);
      return (
        <Accordion>
          <Accordion.Title id="violations"  active={activeDropdowns[0]} index={0} onClick={this.handleClick} className="page-section-padding">
            <div className={activeDropdowns[0] ? 'accordion-title-active' : 'accordion-title'}>
              {/*NEED NEW TRANSLATION*/}
              {/*{this.appName} {this.renderHeader('violations', isTranslation)} <span className="accordion-title-icon"><Icon name='dropdown'/></span>*/}
              {this.state.translatedViolationsHeading} <span className="accordion-title-icon"><Icon name='dropdown'/></span>
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeDropdowns[0]}>
            {this.state.isTranslation ? this.majorViolationsTranslated : this.majorViolations}
          </Accordion.Content>

          {(this.avBlockList.length > 0) ?
            <div>
              <Accordion.Title id="avBlockList" active={activeDropdowns[1]} index={1} onClick={this.handleClick} className="page-section-padding">
                <div className={activeDropdowns[1] ? 'accordion-title-active' : 'accordion-title'}>
                  {/*NEED NEW TRANSLATION*/}
                  {/*{this.renderHeader('anti-virus', isTranslation)} <span className="accordion-title-icon"><Icon name='dropdown'/></span>*/}
                  {this.avBlockList.length} {this.state.translatedNumAntiVirusesHeading} <span className="accordion-title-icon"><Icon name='dropdown'/></span>
                </div>
              </Accordion.Title>
              <Accordion.Content active={activeDropdowns[1]}>
                <br></br>
                <p> 
                  {this.renderDescription('anti-virus')}
                </p>
                {this.getBlockSection()}
              </Accordion.Content>
            </div>
          :<div/>
          }

          {/*<Accordion.Title active={activeDropdowns[2]} index={2} onClick={this.handleClick}>
            <div className={activeDropdowns[2] ? 'accordion-title-active' : 'accordion-title'}>
              {this.renderHeader('alternatives', isTranslation)} <span className="accordion-title-icon"><Icon name='dropdown'/></span>
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeDropdowns[2]}>
            <br></br>
            {isTranslation ? <AppSearchBar certifiedOnly={true} toLang={this.translateLang}></AppSearchBar> : <AppSearchBar certifiedOnly={true} ></AppSearchBar>}
            <br></br>
          </Accordion.Content>*/}

          {!this.secondarySource && 
            <div>
              <Accordion.Title id="systemUtil" active={activeDropdowns[3]} index={3} onClick={this.handleClick} className="page-section-padding">
                <div className={activeDropdowns[3] ? 'accordion-title-active' : 'accordion-title'}>
                  {this.renderHeader('systemUtil')}<span className="accordion-title-icon"><Icon name='dropdown' /></span>
                </div>
              </Accordion.Title>
              <Accordion.Content active={activeDropdowns[3]}>
                <br></br>
                {this.renderSystemMonitoring()}
              </Accordion.Content>
            </div>
          }
        </Accordion>
      );
    }

    renderInfoBanner(section) {
      if (!CommonUtil.IsEnglishLang() && this.state.encyclopediaText && this.state.encyclopediaText.translation && this.state.encyclopediaText.translation.infobanner && this.state.encyclopediaText.translation.infobanner[section]) {
        return this.state.encyclopediaText.translation.infobanner[section];
      } else 
      if (this.state.encyclopediaText && this.state.encyclopediaText.infobanner && this.state.encyclopediaText.infobanner[section]) {
        return this.state.encyclopediaText.infobanner[section];
      } else 
      return ("");
    }

    renderHeader(sectionName) {
      if (this.state.isTranslation && this.state.encyclopediaText && this.state.encyclopediaText.translation && this.state.encyclopediaText.translation[sectionName]) {
        return this.state.encyclopediaText.translation[sectionName].header;
      } else 
      if (this.state.encyclopediaText &&this.state.encyclopediaText && this.state.encyclopediaText[sectionName]) {
        return this.state.encyclopediaText[sectionName].header;
      } else 
      return ("");
    }

    renderDescription(sectionName) {
      if (this.state.isTranslation && this.state.encyclopediaText && this.state.encyclopediaText.translation && this.state.encyclopediaText.translation[sectionName] && this.state.encyclopediaText.translation[sectionName].description) {
        return this.state.encyclopediaText.translation[sectionName].description;
      } else 
      if (this.state.encyclopediaText && this.state.encyclopediaText[sectionName] && this.state.encyclopediaText[sectionName].description) {
        return this.state.encyclopediaText[sectionName].description;
      } else 
      return ("");
    }

    renderMediaModal() {
      return ( 
        <div>
          <div id="modalid-img" className="mediamodal">
            <span id="modalcloseid" className="closemodal" onClick={() => {document.getElementById('modalid-img').style.display='none';
                                                                      document.getElementById('modalimgid').src='';
                                                                    }}>&times;</span>
            <img className="contentmodal" id="modalimgid" alt=""/>
          </div>

          <div id="modalid-vid" className="mediamodal">
            <span id="modalcloseid" className="closemodal" onClick={() => {document.getElementById('modalid-vid').style.display='none';
                                                                      document.getElementById('modalvideoid').src='';
                                                                    }}>&times;</span>
            <video className="contentmodal" id="modalvideoid" controls autoPlay/>
          </div>
        </div>
      )
    }

    renderCertified() {
      var tag_description_txt = "AppEsteem Certified: " + this.appName;
        return (
          <div>
            <div className="wrapper">
              <MetaTags>
                <title>{this.appName} (AppEsteem Certified)</title>
                <meta id="meta-description" name="description" content={tag_description_txt} />
              </MetaTags>
            </div>
            <div>
              <div className="apps-hero-deceptor-content hero-bottom-margin hero-top-margin">
                <div className="apps-certified-text">Certified</div>
                <div className="apps-hero-deceptor-details">
                  <Header as='h1'>
                    {this.appName}
                  </Header>
                  <div>
                    <div><strong>{this.renderInfoBanner('type')} </strong>{this.type}</div>
                    <div><strong>{this.renderInfoBanner('owner')} </strong>{this.companyName}</div>
                    <div><strong>{CommonUtil.IsEnglishLang() && this.state.encyclopediaText && this.state.encyclopediaText.infobanner && this.state.encyclopediaText.infobanner ? this.state.encyclopediaText.infobanner.certifiedDate 
                      : (this.state.encyclopediaText && this.state.encyclopediaText.translation && this.state.encyclopediaText.translation.infobanner && this.state.encyclopediaText.translation.infobanner.certifiedDate ? this.state.encyclopediaText.translation.infobanner.certifiedDate : "")}</strong>{this.state.dateAdded}</div>
                    <div><strong>{this.renderInfoBanner('monetization')} </strong>{this.renderMonetizationList()}</div>
                  </div>
                </div>
              </div>
            </div>
            <Container>
              <Grid className='stackable'>
                <Grid.Row>
                  <Grid.Column width={11}>
                    {this.getLogoSection()}
                    {this.getOffersSection()}
                    {this.getValuesSection()}
                    {this.getAllowSection()}
                    <p className="page-section-padding">
                      {CommonUtil.IsEnglishLang() && this.state.encyclopediaText.certified && this.state.encyclopediaText.certified.certifiedDesc ? this.state.encyclopediaText.certified.certifiedDesc : 
                      (this.state.encyclopediaText && this.state.encyclopediaText.translation && this.state.encyclopediaText.translation.certified && this.state.encyclopediaText.translation.certified.certifiedDesc ? this.state.encyclopediaText.translation.certified.certifiedDesc : "")}
                    </p>
                    {this.renderMediaModal()}
                  </Grid.Column>
                  <Grid.Column width={5}>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
        )
    }

    renderDeceptor() {
      return (
        <div>
          <div className="wrapper">
            <MetaTags>
              <title>{this.appName} (AppEsteem Deceptor)</title>
              <meta id="meta-description" name="description" content={this.metaDescription} />
            </MetaTags>
          </div>
          <div>
            <div className="apps-hero-deceptor-content hero-bottom-margin">
              <div className="apps-deceptor-text"><i class="exclamation triangle orangeDark icon"></i>&nbsp;{this.secondarySource ? this.getSecondaryHeading(this.appName) : this.state.translatedDeceptorHeading}</div>
              <div className="apps-hero-deceptor-details hero-top-margin">
                <Header as='h1'>
                  {this.appName}
                </Header>
                {!this.secondarySource && 
                <table className="apps-description-table">
                  <tr><td>{this.renderInfoBanner('type')}</td><td>{this.type}</td></tr>
                  <tr><td>{this.renderInfoBanner('owner')}</td><td>{this.companyName}</td></tr>
                  <tr><td>{this.renderInfoBanner('date')}</td><td>{this.state.dateAdded}</td></tr>
                  <tr><td>{this.renderInfoBanner('monetization')}</td><td>{this.renderMonetizationList()}</td></tr>
                  </table>
                }
              </div>
            </div>
          </div>
            {/*CommonUtil.IsEnglishLang() ? <AppSearchBar certifiedOnly={false}></AppSearchBar> : <AppSearchBar certifiedOnly={false} toLang={this.translateLang}></AppSearchBar>*/}
            {this.renderAccordions()}
            {this.renderMediaModal()}
        </div>
      );
    }
  
  getSecondaryHeading(appId) {
    appId = appId.toLowerCase();
    if (appId === "phishsite")
      return this.state.translatedPhishsiteHeading;
    else if (appId === "tricksite")
      return this.state.translatedTricksiteHeading;
    return this.state.translatedCracksiteHeading;
  }

    parseEnTranslation() {
        this.setState( c => {
            c.translatedViolationsHeading = Parser(this.state.violationsHeading);
            c.translatedNumAntiVirusesHeading = Parser(this.state.numAntiVirusesHeading);
          c.translatedDeceptorHeading = Parser(this.state.deceptorHeading);
          
          c.translatedCracksiteHeading = Parser(this.state.cracksiteHeading);
          c.translatedPhishsiteHeading = Parser(this.state.phishsiteHeading);
          c.translatedTricksiteHeading = Parser(this.state.tricksiteHeading);
        });
    }

    getTranslation() {
        if (CommonUtil.IsEnglishLang()) {
          this.parseEnTranslation();
          return;
        }
    
        const titleListString = "apps/violationsHeading@apps/numAntiVirusesHeading@apps/deceptorHeading@apps/cracksiteHeading@apps/phishsiteHeading@apps/tricksiteHeading";
        const titleDict = {
                           "apps/violationsHeading": this.state.violationsHeading,
                           "apps/numAntiVirusesHeading": this.state.numAntiVirusesHeading,
                           "apps/deceptorHeading": this.state.deceptorHeading,
                           "apps/cracksiteHeading": this.state.cracksiteHeading,
                           "apps/phishsiteHeading": this.state.phishsiteHeading,
                           "apps/tricksiteHeading": this.state.tricksiteHeading
        };
                            
        const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
        let hasCachedText;
        axios.get(checkTextURL) // check if we need to send text over
        .then((response) => {
          hasCachedText = response.data;
        }, (error) => {
          hasCachedText = false;
        })
        .finally(() => {
          const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=apps`
                                : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=apps`;
          const data = (hasCachedText) ? null : titleDict;
          axios.post(translateURL, data)
          .then((response) => {
            this.setState( c => {
    
              c.translatedViolationsHeading = Parser(response.data["apps/violationsHeading"]);
              c.translatedNumAntiVirusesHeading = Parser(response.data["apps/numAntiVirusesHeading"]);
              c.translatedDeceptorHeading = Parser(response.data["apps/deceptorHeading"]);

              c.translatedCracksiteHeading = Parser(response.data["apps/cracksiteHeading"]);
              c.translatedPhishsiteHeading = Parser(response.data["apps/phishsiteHeading"]);
              c.translatedTricksiteHeading = Parser(response.data["apps/tricksiteHeading"]);
    
              c.message = "";
              c.errorTranslation = false;
              return c;
            });
          }, (error) => {
            this.setState( c => {
              c.message = "API error, could not translate";
              c.errorTranslation = true;
              return c;
            });
          })
       });
    }

    render() {
      if (this.state.loading) {
        return (<Grid.Column style={{display:"flex", "justifyContent":"center"}}>
                  <div className="dimmable" style={{margin:"0 auto", top:"30%", position:"fixed"}}>
                      <div className="ui active inverted dimmer">
                          <div className="ui massive text loader" ></div>
                      </div>
                  </div>
              </Grid.Column>);
      } else {
        if (this.certified){
          return this.renderCertified()
        }
        else {
          return this.renderDeceptor()
        }
      }
     }
}

export default windowSize(Apps)