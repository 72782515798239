import React, { Component } from 'react';
import { Container, Grid} from 'semantic-ui-react';
import { PremiumUtil } from './utils/PremiumUtil';
import HomeSideBar from './components/HomeSideBar';
import HomeBlogs from './components/HomeBlogs.js';
import { Settings } from './Settings';
import axios from 'axios';
import Parser from 'html-react-parser';
import { CommonUtil } from './utils/CommonUtil';
import BlogCarousel from './components/BlogCarousel';

class Home extends Component {
    constructor(props) {
        super(props);
        const searchPrompt = CommonUtil.findHeaderString('Search Header');
        const blogsHeading = CommonUtil.findHeaderString('Blog Header');

        this.state = {
          isShowLogo: false,
          isSignedIn: false,
          userProfile: {},
          isDevMode: false,
          isLoading: true,
          topsData: [],
          blogTitlesAndUrls: [],
          translatedSearchPrompt: Parser(searchPrompt),
          translatedBlogsHeading: Parser(blogsHeading),
          searchPrompt,
          blogsHeading,
          message: "",
          errorTranslation: true,
          safeSearchToggled: localStorage.getItem('safeSearchToggled') === 'true',
          activePanel: "",
          showFloatPanels: false
        };
        this.translateLang = CommonUtil.lang;
        this.count = 0;
        this.checkDelta = 200;
        this.maxCount = 1000/this.checkDelta;
    }
    componentDidMount() {
      window.addEventListener('resize', this.isShowLogo.bind(this));
      this.isShowLogo();
      let currImg = PremiumUtil.selectCurrentBgImage();
      document.body.style.background = "url(/images/home/" + currImg + ".jpg) no-repeat center center fixed";//, url(/images/home/" + currImg + ".png), url(/images/home/bg-1-1.jpg)
      document.body.style.backgroundSize = "cover";
      this.loadTopData();
      this.getTranslation();
    }
    componentDidUpdate(prevProps) {
      if(this.translateLang !== CommonUtil.lang) {
        this.translateLang = CommonUtil.lang;
        this.getTranslation();      
      }
    }
  
    componentWillUnmount() {
      if(this.timeoutObj) {
        clearTimeout(this.timeoutObj);
      }
      document.body.style.backgroundImage = "";
      document.body.style.backgroundPosition = "";
      document.body.style.backgroundSize = "";
      window.removeEventListener('resize', this.isShowLogo.bind(this));
    }
    isShowLogo() {
      if (window.innerWidth < 1024) {
        this.setState(c=> {
          c.isShowLogo = true;
          return c;
        });
      } else {
        this.setState(c=> {
          c.isShowLogo = false;
          return c;
        });
      }
    }
    loadTopData() {
      if(this.props.noApiCall) {
        this.setState(c => {
          if (typeof window.ntGlobalTopObj !== 'undefined' && window.ntGlobalTopObj) {
            c.topsData = window.ntGlobalTopObj
          }
          if (typeof window.ntGlobalBlogObj !== 'undefined' && window.ntGlobalBlogObj) {
            let blogs = window.ntGlobalBlogObj;
            let blogTitleAndUrls = [];
            for(var ix in blogs) {
              if(!blogs[ix].title || !blogs[ix].slug || !blogs[ix].imgUrl) {
                continue;
              }
              blogTitleAndUrls.push({title: blogs[ix].title, url: `${CommonUtil.lang}/blog/${blogs[ix].slug}`, imgUrl: blogs[ix].imgUrl});
            }
            c.blogTitlesAndUrls = blogTitleAndUrls;
          }
          c.isLoading = false;
          return c;
        }, CommonUtil.loadGCE)
      } else {
        this.setState(c => {
          c.loading = false;
          return c;
        }, CommonUtil.loadGCE())
      }
    }
    parseEnTranslation() {
        this.setState( c => {
            c.translatedSearchPrompt = Parser(this.state.searchPrompt);
            c.translatedBlogsHeading = Parser(this.state.blogsHeading);
            return c;
        });
    }
    getTranslation() {
        if (CommonUtil.IsEnglishLang() && !this.props.noApiCall) {
          this.parseEnTranslation();
          return;
        } else if(this.props.noApiCall) {
          this.setState( c => {
            c.translatedSearchPrompt = PremiumUtil.Translate(this.state.searchPrompt, this.state.searchPrompt);
            c.translatedBlogsHeading = PremiumUtil.Translate(this.state.blogsHeading, this.state.blogsHeading);
            return c;
          });
          return; //we dont need to do any other translations
        }
        const titleListString = "search/searchPrompt@search/blogsHeading@search";
        const titleDict = {
                           "search/searchPrompt": this.state.searchPrompt,
                           "search/blogsHeading": this.state.blogsHeading,
        };
                            
        const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
        let hasCachedText;
        axios.get(checkTextURL) // check if we need to send text over
        .then((response) => {
          hasCachedText = response.data;
        }, (error) => {
          hasCachedText = false;
        })
        .finally(() => {
          const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=home`
                                : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=home`;
          const data = (hasCachedText) ? null : titleDict;
          axios.post(translateURL, data)
          .then((response) => {
            this.setState( c => {
              c.translatedSearchPrompt = Parser(response.data["search/searchPrompt"]);
              c.translatedBlogsHeading = Parser(response.data["search/blogsHeading"]);
    
              c.message = "";
              c.errorTranslation = false;
              return c;
            });
          }, (error) => {
            this.setState( c => {
              c.message = "API error, could not translate";
              c.errorTranslation = true;
              return c;
            });
          })
       });
    }
    handleSafeSearchToggle() {
        this.setState(c => {
            localStorage.setItem('safeSearchToggled', !c.safeSearchToggled);
            return {safeSearchToggled: !c.safeSearchToggled}
        });
    }
    toggleFloatPanel(panelType) {
      if(this.state.activePanel === panelType) {
        this.setState(c => {
          c.activePanel = "";
          return c;
        });
      } else {
        this.setState(c => {
          c.activePanel = panelType;
          return c;
        })
      }
    }
    canShowFloatPanel() {
      if(this.props.isNewTab && window.innerWidth && window.innerWidth >= 1024) {
        return true;
      }
      return false;
    }
    toggleFloatPlanels() {
      this.setState(c => {
        c.showFloatPanels = !c.showFloatPanels;
        return c;
      })
    }
    renderActivePanel() {
      if(this.state.showFloatPanels) {
        let activePanel = this.state.activePanel;
        if(activePanel === "blog") {
          return (
            <div id="topBlogsId" className="home-card blog-card">
              <HomeBlogs {...this.props} usedLang={this.translateLang}  hideHeader={true} blogTitlesAndUrls={this.state.blogTitlesAndUrls} topsDataCount={5} translatedHeading={this.state.translatedBlogsHeading} />
            </div>
          )
        }
      }
    }
    renderFloatingDetails() {
      if(this.state.showFloatPanels) {
        return (
          <div className="newtab-float-details">
            <div className="newtab-float-icon">
              <div className={this.state.activePanel === "blog" ? "newtab-float-title active" : "newtab-float-title"} 
                  onClick={() => this.toggleFloatPanel("blog")}>
                <div className="newtab-float-title-icon displayNone"><i className="edit large icon"></i></div>
                <div className="newtab-float-title-header">{this.state.translatedBlogsHeading}</div>
                <div><i className="caret right icon"></i></div>
              </div>
            </div>
            {this.renderActivePanel()}
          </div>
        )        
      }
    }
    renderFloatingPanels() {
      if(this.canShowFloatPanel()) {
        return (
          <div className="newtab-float-section">
            <div className="newtab-internet-security" onClick={this.toggleFloatPlanels.bind(this)}>
              {PremiumUtil.Translate("safetyPortal")} <i className="angle down icon"></i>
            </div>
            {this.renderFloatingDetails()}
          </div>
        )
      }
    }
    renderPanels() {
      if(this.canShowFloatPanel()) {
        return ("");
      }
      return (
        <div className="home-cards-wrap">
          <div id="topBlogsId" className="home-card blog-card">
            <HomeBlogs {...this.props} usedLang={this.translateLang} blogTitlesAndUrls={this.state.blogTitlesAndUrls} topsDataCount={3} translatedHeading={this.state.translatedBlogsHeading} />
          </div>
        </div>

      )      
    }
    renderBlogCarousel() {
      if(this.canShowFloatPanel()) {
        return (
          <div className="newtab-blog-carousel">
            <BlogCarousel blogTitlesAndUrls={this.state.blogTitlesAndUrls} />
          </div>
        )
      }      
    }
    renderTopSites() {
      if(this.props.isNewTab) {
        let maxCount = 10;
        if(window.innerWidth) {
            if(window.innerWidth < 400) {
                maxCount = 3;
            } else if(window.innerWidth < 768){
                maxCount = 6;
            }  else if(window.innerWidth < 1024){
                maxCount = 8;
            }
        }
        return (
          <div className="topsite-container">
            <iframe id="top-site-frame" width="100%" height="280px" style={{border: "none"}} sitecount={maxCount}/>
          </div>
        )
      }
    }
    render() {
      if(this.state.isLoading) {
        return (
          <div className="home-hero-bg">
            <Container>
              <Grid className='stackable'>
                <div className="ui inverted active dimmer">
                  <div className="ui massive text loader"></div>
                </div>
              </Grid>
            </Container>
          </div>
        )
      }
      return (
        <div className="home-page-wrapper">
          <div className="home-content-wrap">
            <div>
              <div className="container">
                {this.state.isShowLogo ? (
                                          <a className="header item" href="/">
                                            <img src="/images/Blur_logo_white.png" className="ui image navmenu-logo-img" />
                                          </a>) 
                                        : ""
                }
              </div>
            </div>
            <div className="home-searchbox-text">
              {this.renderTopSites()}
              {this.renderBlogCarousel()}
              {this.renderPanels()}
            </div>
          </div>
          <div className="home-side-panel">
            <HomeSideBar {...this.props} showLogo={!this.state.isShowLogo} showAd={false} hideBlurInfo={false}/>
          </div>
          {this.renderFloatingPanels()}  
        </div>
      );  
    }
}

export default Home