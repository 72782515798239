import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Confirm } from 'semantic-ui-react';
import { PremiumUtil } from '../utils/PremiumUtil.js';

const AeConfirm = (unmountTime = 200) => {
    const wrapper = document.body.appendChild(document.createElement('div'));
    const promise = new Promise((resolve, reject) => {
      try {
        var dispose = function() {
          setTimeout(() => {
            ReactDOM.unmountComponentAtNode(wrapper);
            setTimeout(() => wrapper.remove());
          }, unmountTime);
        };
        var handleCancel = function() {
            dispose();
            reject("Cancel");
        }
        var handleConfirm = function() {
            dispose();
            resolve("Confirm");
        }
    
        ReactDOM.render(
          <Confirm
            open={open}
            content={PremiumUtil.Translate("ppAreYouSure", "Are you sure?")}
            cancelButton={PremiumUtil.Translate("ppCancel", "Cancel")}
            confirmButton={PremiumUtil.Translate("ppOK", "OK")}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            size='tiny'
          />,
          wrapper
        );
      } catch (e) {
        reject(e);
      }
  });
  return promise;
}

export default AeConfirm;
