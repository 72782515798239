import React, { Component } from 'react';
import { Header, Grid } from 'semantic-ui-react';

const Page404 = (props) => {
    return (
        <div style={{"padding-top": "50px", "text-align": "center", "color": "#506D84"}}>
            <div>
                <h1 style={{"font-size": "8em"}}>404</h1>
            </div>
            <div blurmessagekey="pageNotFound">
                Page not found
            </div>
            <div>
                <div blurmessagekey="sorryNotFound">
                    Sorry! but the requested page is not found.
                </div>
            </div>
        </div>
    );
}
export default Page404;
