import React, { Component } from 'react';
import Subscribed from "./Subscribed.js";
import SubscriptionsAvailable from "./SubscriptionsAvailable.js";
import OfferPremium from "./OfferPremium.js";
import { PremiumUtil } from '../utils/PremiumUtil.js';
import ManageEnrollment from './MangeEnrollment.js';


class PremiumBody extends Component {
    render() {
      if(this.props.loading) {
        return (
          <div className="ui inverted active dimmer">
            <div className="ui massive text loader">{this.props.loadingMessage}</div>
          </div>
        )
      }
      if(PremiumUtil.View === "manageEnrollment") {
        return <ManageEnrollment reRender={this.props.reRender.bind(this)} onClose={this.props.onClose.bind(this)}/>
      }
      if(PremiumUtil.View === "signIn" || PremiumUtil.View === "emailVerification") {
        return <OfferPremium reRender={this.props.reRender.bind(this)} onClose={this.props.onClose.bind(this)}/>
      }
      if(PremiumUtil.HasSubscriptions(false)) {
        // if subscriptions are available
        //subscription is available
        return (
          <SubscriptionsAvailable  
            selectedSubscId={this.props.selectedSubscId}
            errorMessage={this.props.errorMessage}
            reRender={this.props.reRender.bind(this)} 
            unEnrollMe={this.props.unEnrollMe.bind(this)} 
            onClose={this.props.onClose.bind(this)} 
            selectSubscription={this.props.selectSubscription.bind(this)}
          />
        )
      } else if(PremiumUtil.IsSubscribed()) { //this means user is subscribed and 
        //if subscribed main body dont need to show any thing
        //should we let user signin as well to get emailId
        return (
          <Subscribed reRender={this.props.reRender.bind(this)} onClose={this.props.onClose.bind(this)}/>
        )
      } else {
          //this means user is not subscribed and no subscription is availabled to it
        return (
          <OfferPremium  reRender={this.props.reRender.bind(this)} onClose={this.props.onClose.bind(this)}/>
        )
      }
    }
}

export default PremiumBody;