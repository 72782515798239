module.exports = {
    adClient: "ca-pub-7978968669118944",
    sideSlots: {
        desktop: {width:"300px", height: "250px", displayType: "inline-block", adSlot: "3486394537"},
        tablet: {width:"728px", height: "90px", displayType: "inline-block", adSlot: "9702875561"},
        mobile: {width:"320px", height: "50px", displayType: "inline-block", adSlot: "7199468322"}
    },
    searchSlots: {
        desktop: {width:"300px", height: "250px", displayType: "inline-block", adSlot: "9518376457"},
        tablet: {width:"728px", height: "90px", displayType: "inline-block", adSlot: "9790026540"},
        mobile: {width:"320px", height: "50px", displayType: "inline-block", adSlot: "6719928503"}
    },
    getAdTest: function() {
        let adTest = "off";
        if(window.location && window.location.href &&
           (window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("staging.blur.live") > -1)) {
               adTest = "on"
        }
        return adTest;
    },
    getDeviceType: function() {
        if(!window || !window.innerWidth) {
            return "desktop"; //default to desktop;
        } else if (window.innerWidth < 767) {
            return "mobile";
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
            return "tablet";
        } else {
            return "desktop";
        }              
    },
    getSlotDetails: function(adSlotType, deviceType, isReact=true) {
        let adSlots = adSlotType === "searchSlot" ? this.searchSlots : this.sideSlots;
        if(deviceType && adSlots[deviceType]) {
            deviceType = deviceType;
        } else {
            deviceType = "desktop";
        }
        let settings = adSlots[deviceType];   
        let style = {display: settings.displayType,width: settings.width,height: settings.height}
        let adSlot = settings.adSlot;
        if(!isReact) {
            style = `display:${settings.displayType};width:${settings.width};height:${settings.height}`;
        }
        return {adClient: this.adClient, adSlot: adSlot, style: style, adTest: this.getAdTest()};
    }
}
