import _ from 'lodash'
import axios from 'axios';
import React, { Component } from 'react'
import { Search, } from 'semantic-ui-react'
import { Settings } from './Settings';

import './theme/custom.css';
import { CommonUtil } from './utils/CommonUtil';

const initialState = { isLoading: false, results: [], value: '',}

class AppSearchBar extends Component {
  
  constructor(props) {
    super(props);
    this.state = initialState
    this.source = []
    this.state.placeholder = ""
    this.placeholders = {
      allApps: 'Search deceptors and certified apps...',
      certifiedOnly: 'Search certified apps...',
    };
    this.translateLang = CommonUtil.lang;
  }

  componentDidMount() {
    if (this.props.certifiedOnly) {
      this.getPlaceholder('certifiedOnly');
    } else {
      this.getPlaceholder('allApps')
    }
  }
  componentDidUpdate(prevProps) {
    if(this.translateLang !== CommonUtil.lang) {
      this.translateLang = CommonUtil.lang;
      if (this.props.certifiedOnly) {
        this.getPlaceholder('certifiedOnly');
      } else {
        this.getPlaceholder('allApps')
      }
    }
}
  
  getPlaceholder(section) {
    if (!CommonUtil.IsEnglishLang()) {
      var data = {message: this.placeholders[section]};
      axios.post(Settings.TranslationURL, data, { 'params': {'fromLang': 'en', 'toLang': this.translateLang}}).then((response) => {
          this.setState({ placeholder: response.data.message });
      });
    } else {
      this.setState({ placeholder: this.placeholders[section] })
    }
  }

  resultRenderer({name, classification}) {  // red for deceptor, green for certified
    return (
      <div className="searchbar">
        {name}<span className={classification == 'Deceptor' ? 'searchbar-deceptor' : 'searchbar-certified'}>{classification}</span>
      </div>
    )
  }

  handleResultSelect = (e, { result }) => {
      window.open("/" + CommonUtil.lang + "/apps/" + result.appid);
  }

  handleSearchChange = (e, { value }) => {
      this.source = [];
      this.setState({ isLoading: true, value })
      if (this.state.value.length > 0) {  // prevent searching without paramaters
        this.searchApps(value);
      }

      setTimeout(() => {
        if (this.state.value.length < 1) return this.setState(initialState)
  
        this.setState({
          isLoading: false,
          results: this.source,
        })
      }, 1000)
  }

  searchApps(searchTerm) {
    axios.get(Settings.SearchAppsURL, { 'params': {'searchTerm': searchTerm, 'certifiedOnly': this.props.certifiedOnly,}})
    .then((response) => {
      for (let i = 0; i < response.data.length; i++) {
        var app = response.data[i];
        this.source.push(
          {
            title:          i.toString(),
            // name:           app.name + ' ' + app.version, // add version number in C# api?
            name:           app.name,
            appid:          app.appId,
            classification: app.classification,
          }
        );
      }
    });
  }

  render() {
    const { isLoading, value, results } = this.state

    return (
      <Search
        fluid
        input={{ fluid: true }}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        placeholder={this.state.placeholder}
        onSearchChange={_.debounce(this.handleSearchChange, 10, {loading: true})}
        results={results}
        resultRenderer={this.resultRenderer}
        value={value}
      />
    )
  }
}

export default AppSearchBar