module.exports = {
    headerStrings: {
        // Domain page
        "domainHeading": "Statistics for",
        "countryStatisticsHeading": "Statistics around the world",
        "loadingText": "Loading Chart",
        "entrycount_pm": "Sessions per thousand",
        "blurs_pe": "Blurs per session",
        "blurs_pi": "Blurs per impression",
        "downloads_pe": "Downloads per session",
        "referrertraffic_pe": "Referrer traffic per session",
        "searchtraffic_pe": "Search traffic per session",
        "searches_pe": "Searches per session",
        "adnetworkHeading": "Adnetworks", 
        "downloadsHeading": "Downloads",
        "referrerHeading": "Files Download",
        "queryKeyHeading": "Query Keys Clicked",
        "blurredRankHeading" : "Total Blurred Ads",
        "showRanksHeading"   : "Show Ranks",
        "categoryHeading"    : "Category",
        "countryHeading"     : "Country",
        "valueHeading"       : "Value",
        "rankHeading"        : "Rank",
        "dropdownPropHeading": "Select a property",
        "topTensHeading"     : "See Top Tens",

        // Wordcloud Descriptions
        "adnetworkWordCloud": "These ad networks placed/injected ads on the pages of {}",
        "referrerWordCloud": "These domains sent visitors to {}",
        "downloadsWordCloud": "Vistors to {} downloaded these files",
        "queryKeyWordCloud": "Visitors searched for these terms to get to web pages on {}",

        "adnetworkRelatedWordCloud": "These ad networks showed ads on the same domains as {}",
        "adnetworkTopDomainWordCloud": "Visitors searched for these terms to get to web pages on {}",
        "referrerRelatedWordCloud": "These domains referred to the same domains as {}",
        "referrerTopDomainWordCloud": "{} referred its visitors to these domains",
        "downloadRelatedWordCloud": "These files were downloaded from the same sites as {}",
        "downloadTopDomainWordCloud": "These search terms sent visitors to the same domains as {}",
        "queryKeyRelatedWordCloud": "These search terms sent visitors to the same domains as {}",
        "queryKeyTopDomainWordCloud": "These domains had the most clicks from the search term {}",

        // QueryKey Page
        "querykeyHeading": "Query Key",
        "adnetworkHeading": "Ad Network",
        "downloadHeading": "Download",
        "referrerHeading": "Referrer",
        "statisticsHeading": "Statistics for",
        "queryKeyCountryValueHeader": "Queries per thousand entries",
        "queryKeyRelatedCloudHeading": "Related Query Keys",
        "queryKeyTopDomainHeading": "Domains with most clickthroughs",
        "adnetworkCountryValueHeader": "Adnetwork traffic per entry",
        "adnetworkRelatedCloudHeading": "Related Ad Networks",
        "adnetworkTopDomainHeading": "Domains with high adnetwork usage",
        "downloadCountryValueHeader": "Downloads popularity per entry",
        "downloadRelatedCloudHeading": "Related Downloads",
        "downloadTopDomainHeading": "Domains with most download clicks",
        "referrerCountryValueHeader": "Referrer popularity per entry",
        "referrerRelatedCloudHeading": "Related referrers",
        "referrerTopDomainHeading": "Top domains",

        // Ranks Page
        "ranks": "Ranks",
        "topTenCountries": "Countries with Top Ten Data",
        "adnetworks": "Top Ad Networks Seen",
        "downloads": "Most Downloaded Files",
        "querykeys": "Most Queried Words",
        "blurred": "Most Blurred Ads Seen",
        "blurs_pe": "Most Blurred Ads per Session",
        "blurs_pi": "Most Blurred Ads per Page",
        "downloaded": "Top Domains with Downloads",
        "entrycount": "Most Visited Domains",
        "referred": "Most Referred-from Domains",

        // Stats Text
        "dataHeading" : "Data explanation",
        "dataExplanation" : "Data is anonymized and aggregated Blur.live customer data for the past seven days ending on",

        // Global
        "globalHeading" : "Global",
        "emptyWordCloudText": "No applicable information"
    }
}