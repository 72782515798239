import axios from 'axios';
import { Settings } from './Settings';
import { CommonUtil } from './utils/CommonUtil';

export class BlurMessages {
	static resetVars() {
		BlurMessages.blurmessages = {};
		BlurMessages.isDomLoaded = false;
		BlurMessages.isMessagesLoaded = false;
		BlurMessages.notFilledParamMessage = [];
	}
	static init(lang='', messageIds='', delimiter=",") {
		//return promise to not block the caller
		var promise = new Promise((resolve, reject) => {
			BlurMessages.blurmessages = (BlurMessages.blurmessages == undefined || !BlurMessages.blurmessages) ?  {} : BlurMessages.blurmessages;
			BlurMessages.isDomLoaded = false;
			BlurMessages.isMessagesLoaded = false;
			BlurMessages.notFilledParamMessage = [];
			let headers = {
				'Accept': 'application/json',
				'Content-Type': 'application/json; charset=utf-8'
			};
			let withcredapi = axios.create({ 
				withCredentials: true,
				headers: headers
			});
			if(!lang && CommonUtil.lang) {
				lang = CommonUtil.lang;
			}
			if (typeof window.globalTransObj !== 'undefined' && window.globalTransObj) {
				let globalMessageObj = {};
				for(var key in window.globalTransObj) {
					var blang = lang.toLocaleLowerCase();
					var slang = blang.length > 2 ? blang.substr(0, 2) : blang;
					var obj = window.globalTransObj[key];
					if(obj[blang]) {
						globalMessageObj[key] = obj[blang];
					} else if(obj[slang]) {
						globalMessageObj[key] = obj[slang];
					}
				}
				// save messages
				BlurMessages.isMessagesLoaded = true;
				BlurMessages.blurmessages = globalMessageObj;
				if(BlurMessages.isDomLoaded) {
					BlurMessages.translate();					
				}; 
				resolve();
			} else if (typeof window.ntGlobalTransObj !== 'undefined' && window.ntGlobalTransObj) {
				let globalMessageObj = {};
				for(var key in window.ntGlobalTransObj) {
					globalMessageObj[key] = window.ntGlobalTransObj[key];
				}
				// save messages
				BlurMessages.isMessagesLoaded = true;
				BlurMessages.blurmessages = globalMessageObj;
				if(BlurMessages.isDomLoaded) {
					BlurMessages.translate();					
				}; 
				resolve();
			} else if(CommonUtil.noApiCalls) {
				BlurMessages.isMessagesLoaded = true;
			} else {
				withcredapi.get(Settings.BlurMessagesUrl, { 'params': {'lang': lang, 'messageIds': messageIds, delimiter: delimiter}}).then((response) => {
					// save messages
					BlurMessages.isMessagesLoaded = true;
					BlurMessages.blurmessages = response.data.blurMessages;
					if(BlurMessages.isDomLoaded) {
						BlurMessages.translate();					
					}; 
					resolve();
				}).catch((err) => {
					//console.log("BlurMessages error invoking", err, Settings.BlurMessagesUrl);
					resolve();
				});			
			}
		});
		return promise;
	}

	static domLoaded() {
		//return promise to not block the caller
		var promise = new Promise((resolve, reject) => {
			try {
				BlurMessages.isDomLoaded = true;
				if(BlurMessages.isMessagesLoaded) {
					BlurMessages.translate();
				}		
			} catch(err) {
				//never throwing error, no point processing now;
			}
			resolve();
		});
		return promise;
	}
	static setInnerHtml(node, messageId) {
		var args = Array.prototype.slice.call(arguments).splice(1);
		if(!BlurMessages.blurmessages || Object.keys(BlurMessages.blurmessages).length === 0) {
			BlurMessages.notFilledParamMessage.push({node: node, args: args});
			return;
		}
		var trans = BlurMessages.getMessage(...args); // args holds the messageId
		if(trans != messageId) {
			node.innerHTML = trans;
		}
	}
	static getMessage(messageId) {
		return BlurMessages.GetBlurMessageInternal(messageId, Array.prototype.slice.call(arguments));
	}
	static GetBlurMessageInternal(messageId, args, locale="") {
		let message = BlurMessages.GetBlurMessageBase(messageId);
		if(args.length > 1) {
			// translate each arg
			for (let ix=1; ix<args.length; ix++) {
				let key = args[ix];
				let type = typeof key;
				if(type === "string") {
					// if it's a string, translate it
					let keytrans= BlurMessages.GetBlurMessageBase(key); //replace this with getBlurMessage
					message=message.replace(new RegExp("\\{"+ix+"\\}", "g"), keytrans);
				} else if(type === "number" && locale) {
					//if its number and locale is passed, then change that too
					message=message.replace(new RegExp("\\{"+ix+"\\}", "g"), key.toLocaleString(locale));
				} else {
					// if it's not a string and number, just drop it in
					message=message.replace(new RegExp("\\{"+ix+"\\}", "g"), key);
				}
			}
		}
		return message;
	}
	static GetBlurMessageBase(messageId) {
		if(BlurMessages.blurmessages && BlurMessages.blurmessages[messageId]) {
			return BlurMessages.blurmessages[messageId];
		}
		// return the key if we didn't find anything
		return messageId;
	}
	static translate() {
		// initialize localized messages
		// for each document.querySelectorAll('.BlurMessageKey'), set innerHTML to BlurMessages.getMessage(messageKey)
		let translatelist = document.querySelectorAll('[blurmessagekey]');
		for(let i = 0; i < translatelist.length; i++) {
			let node = translatelist[i];
			let key = node.getAttribute('blurmessagekey');
			let trans = BlurMessages.getMessage(key);
			// only set if it's not the key and it's not already set
			if(trans !== key && node.innerHTML != trans) {
				//tagname is always upper case, and changing title through node was not reflecting
				if(node.tagName === "TITLE") {
					document.title = trans;
					node.innerHTML = trans;
				} else if(key.tagName === "META") {
					trans = BlurMessages.getMessage(key, "https://blur.live/lp");
					node.setAttribute("content", trans);
				} else {
					node.innerHTML = trans;
				}
			}
		}
		// handle the un-done stuff from setInnerHTML()
		for(let ix =0; ix < BlurMessages.notFilledParamMessage.length; ix++) {
			BlurMessages.setInnerHtml(BlurMessages.notFilledParamMessage[ix].node, ...BlurMessages.notFilledParamMessage[ix].args); // array contains the key
		}
		// clean up the un-done stuff
		BlurMessages.notFilledParamMessage = [];
	}
}

