import axios from 'axios';
import {Settings} from '../Settings.js';

/*
IMPORTANT: this component is used by safety page keep it light
*/

export class SafetyUtil {
    static SearchFragData(type, frag, take = 10, skip = 0) {
        var url = Settings.FindDataUrl + '?type=' + type + 
                                         '&frag=' + frag +
                                         '&take=' + take +
                                         '&skip=' + skip;
        return axios.get(url, { withCredentials: true });      
    }
}