import React, { Component } from 'react';
import windowSize from 'react-window-size';

class Covid extends Component {
    constructor(props) {
        super(props);
        this.state = {
          height: 700,
          width: 933
        }
    }

    componentDidMount() {
      this.handleResize();
      window.addEventListener('resize', this.handleResize.bind(this));
    }
    
    handleResize() {
      this.setState(c=> {
        c.width = window.innerWidth > 20 ? (window.innerWidth -20) : 0;
        if(window.innerHeight < 850) {
          c.height = window.innerHeight - 150;
        } else {
          c.height = 700;
        }
        return c;
      });
    }

    render() {
      return (
        <div style={{paddingLeft: "10px", paddingRight: "10px", paddingTop: "20px"}}>
          <iframe width={this.state.width} height={this.state.height} src="https://app.powerbi.com/view?r=eyJrIjoiNmQzOGFjYTQtNmNiZS00NmJhLWJiMTMtZWJmZGQyZTg4MWExIiwidCI6ImNmYTk1MjAxLWMyYTEtNGM1YS1hYTFmLTlhYThhNjFhZmVmNSIsImMiOjZ9" frameborder="0" allowFullScreen="true">            
          </iframe>
        </div>
      )
    }
}

export default Covid