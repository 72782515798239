import React, { Component } from 'react';
import {Header, Form, Dropdown, Button, Divider} from 'semantic-ui-react';
import { PremiumUtil } from '../utils/PremiumUtil.js';
import { ReactGAInstance } from '../connectors/ReactGAInstance.js';


class SubscriptionsAvailable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollDescriptor: "",
      enrollStatus: "",
      isLoading: false,
      errorMessage: "",
      subscriptionToEnroll: ""
    }
  }
  componentDidMount(){
    ReactGAInstance.PageView("/premium/popup/subscribe");
    this.setState(c=> {
      c.errorMessage = this.props.errorMessage;
      c.subscriptionToEnroll = this.props.selectedSubscId;
      return c;
    })
  }
  componentDidUpdate(prevProps) {
    if(this.props.location != prevProps.location) {
      this.setState(c=> {
        c.errorMessage = this.props.errorMessage;
        c.subscriptionToEnroll = this.props.selectedSubscId;
        return c;
      });
    }
  }
  resetError() {
    this.setState(c=> {
      c.errorMessage = "";
      return c;
    })
  }
  handleInputChange(evt, {name, value}) {
    this.resetError();
    if(!name) {
      return;
    }
    this.setState(c => { 
      c[name] = value;
      return c; 
    });
  }
  handleSubscriptionDropdown(evt, {value}) {
    this.resetError();
    this.setState(c => {
      c.subscriptionToEnroll = value;
      return c;
    });
    this.props.selectSubscription(value);
  }
  enrollUser() {
    this.resetError();
    this.setState(c=> {
      c.isLoading = true;
      c.loadingMessage = PremiumUtil.Translate("ppEnrolling", "Enrolling ...");
      return c;
    });
    PremiumUtil.EnrollUser(this.state.subscriptionToEnroll, this.state.enrollDescriptor).then((resp) => {
      //we need to refresh the whoami data
      this.props.reRender(true, false, "Loading ...");
      ReactGAInstance.PageView("/premium/popup/subscribed");
    }).catch(err => {
      this.setState(c=> {
        c.errorMessage = PremiumUtil.Translate("ppErrorEnrollingBrowserMsg", "Error enrolling browser, please try again");
        c.isLoading = false;
        return c;
      })
    }).then(() => {
      PremiumUtil.PingExtension();
    });
  }
  renderEnroll() {
    //dont enroll when its already enrolled
    if(PremiumUtil.GetSubscriptionObj() || !this.state.subscriptionToEnroll) {
      return ("");
    }
    return [
      <Divider clearing> </Divider>,
      <Form.Input
        className="enroll_descriptor"
        name="enrollDescriptor"
        label={PremiumUtil.Translate("ppEnterNameForThisBrowser", "Enter a name for this browser")}
        placeholder={PremiumUtil.Translate("ppJoesPcChrome", "Joe's PC Chrome")}
        value={this.state.enrollDescriptor}
        onChange={this.handleInputChange.bind(this)}
      />
    ]
  }
  render() {
    if(this.state.isLoading) {
      return (
        <div className="ui inverted active dimmer">
          <div className="ui massive text loader">{this.props.loadingMessage}</div>
        </div>
      )
    }
    var subscriptions = PremiumUtil.GetSubscriptions(true, false);
    let numberSubscriptions = Object.keys(subscriptions).length;
    return (
      <Form>
        {PremiumUtil.RenderError(this.state.errorMessage)}
        <div className="popup_header">{PremiumUtil.TranslateWithParam("ppYouHaveSubscriptions", "You have " + numberSubscriptions + " subscription(s) available", numberSubscriptions)}</div>
        <Dropdown
          placeholder={PremiumUtil.Translate("ppSelectSubscriptionToEnroll", "Select subscription to enroll")}
          selection
          value={this.state.subscriptionToEnroll}
          onChange={this.handleSubscriptionDropdown.bind(this)}
          options={Object.values(subscriptions)}
        />

        {this.renderEnroll()}
        <Button className="btm_button" fluid color='blue' onClick={this.enrollUser.bind(this)} disabled={!this.state.subscriptionToEnroll || !this.state.enrollDescriptor}>
          {PremiumUtil.Translate("ppEnrollThisBrowser", "Enroll this browser")}
        </Button>
      </Form>
    )
  }
}

export default SubscriptionsAvailable;
