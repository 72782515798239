import React, { Component } from 'react';
import axios from 'axios';
import Parser from 'html-react-parser';
import { Settings } from './Settings';
import { CommonUtil } from './utils/CommonUtil';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        const header = `AppEsteem Privacy Statement`;
        const para1 = `<p><i><small>Updated: May 29, 2019</small></i></p>
        <br/>
        <p>
          Consumer welfare is core to our existence, therefore, protecting your privacy is essential to us. This Privacy
          Statement describes our practices relating to the personal information that we collect and process from users of
          our websites, applications (apps), and services, and explains some of the rights you have over your personal
          information. In this Privacy Statement, we use the term “personal information” to include any information that
          can identify you personally, including contact information, such as your name, address, email address, or phone
          number.
        </p>
        <p>
          By using our website, apps, or services, you signify to us that you have read and understand the data practices
          described in this Statement. All references in this Statement to our collection and use of data through our
          website, also includes the collection and use of data through our apps and services.
        </p>
        <p>
          We are the data controller with respect to the information we collect, as described in this Statement. We do not
          rent or sell this data to any other companies for marketing purposes. You can contact us with any questions about
          this Statement by sending an email to <a href="mailto:privacy@appesteem.com" target="_blank" rel="noopener noreferrer" >privacy@appesteem.com</a>, or by
          mail to AppEsteem Corporation, 655 156<sup>th</sup> Ave, SE, Suite 275,
          Bellevue, Washington, USA 98007.
        </p>
        <p>
            <strong>INFORMATION WE COLLECT</strong>
        </p>
        <p>
            <u>Information You Voluntarily Provide to Us</u>
            .
        </p>
        <p>
            <em>
                When You Register or Contact us through our websites, apps, and
                services
            </em>
            : Most of our services do not require any form of registration, allowing
            you to visit our websites, download our apps, and review our Deceptor and
            Certification lists, blog postings, and other information without providing
            us with any information about who you are. However, to use some of our
            services, such as signing up for our Blur “Pro” services, obtaining app
            certification, or clearing your active Deceptor, we require that you
            provide us with basic contact information for your or your business,
            including your or your company’s name and address, and a contact, including
            an email address and phone number. For our Certification services, we ask
            you to create a password to secure your account, and we manage this process
            using Microsoft Azure, which is a cloud-based service we use to collect and
            store user log-in data.
        </p>
        <p>
            We use your contact information to reply to your requests and perform our
            services. You may provide us with this information by filling in forms on
            our website, or by corresponding with us by phone, email, or otherwise. If
            you contact us, we will have access to the information you provide in your
            correspondence, which will generally include your email address, name,
            phone number, and any other information included in the signature block to
            your email or letter. If you use our online contact link, we will have
            access to any information you include with your message, such as your
            company name, contact name, telephone number, email address, and any
            information you include in the text of your message. If you contact us by
            telephone, your phone number, and the date and time of your call may be
            processed by our third-party telephone service provider, located in the
            United States.
            <strong>
              By providing us with your information, as described above, you expressly consent to our use of your personal
              information to respond to your requests or perform the services you request as described in this Privacy
              Statement
            </strong>
            . You may withdraw your consent at any time. In some situations, if you choose not to disclose
            personal information requested by us, it may not be possible for you to gain access to certain parts of our website
            (such as the Certification customer portal), or for us to respond to your inquiry or provide you with certain
            services.
        </p>
        <p>
            <em>When You Register for a Conference or Event</em>
            . From time to time, we may provide an opportunity for you to register on
            our website for a conference or event that may be relevant to you or your
            business. If you choose to register for any such conference or event, you
            will be asked to provide personal information, which may include your name,
            email address, company name, street address, phone number, and, as
            relevant, your dietary restrictions (for meals at the conference) or
            clothing size (for conference-branded clothing). This information may be
            shared with other parties working with us, and therefore may be transferred
            and stored outside of the United States (for example, if you register for a
            conference that will be held outside of the United States), and treatment
            of that information is further subject to the privacy policies of those
            parties.
        </p>
        <p>
            <u>Information We Collect Automatically</u>
            .
        </p>
        <p>
            <em>When You Use Our Services or Visit Our Website:</em>
            When you use our services or visit our website, we may collect information
            about your usage of the services, visit to our website, and your web
            browsing. This may include information that identifies your IP address,
            operating system, browser, browsing activity, geographical indicators,
            referral source, length of visit on our website, and pages viewed. We refer to this information collectively in this
            Statement as “navigational information.” We may collect navigational information as part of log files, or through
            the use of cookies and other tracking technologies discussed in more detail below under “<u>Navigational Information</u>.”
        </p>
        <p>
            <em>When You Receive Our Email Communication</em>
            . We use certain third-party technologies, such as cookies and pixel
            technologies, which allow us to statistically analyze the success or
            failure of our online marketing campaigns. Based on this navigational
            information, we may see if and when an email was opened, and which links in
            the email were clicked on. For more information, please see “    <u>Navigational Information</u>” and “<u>HubSpot</u>,” below. <u></u>
        </p>
        <p>
            <u>Information from Other Sources</u>
            .
        </p>
        <p>
            <em>Collection of Publicly Available Information for Marketing. </em>
            We may review publicly accessible sources, such as your website, to derive
            information about businesses that we believe may be interested in our apps
            and services. We will collect and store this information to aid in our
            marketing efforts. Our contact with any such
            business regarding our services and potential contractual relationship will be governed by this Statement,
            including the right of any such party to be deleted from our records.
        </p>
        <p>
            <em>Requesting Information from Our Network. </em>
            From time to time, we may ask a member of our Better World Network, a
            vendor, or a security company we work with to provide information to
            augment data we have about a current or potential customer or a business
            that is a registered user of our Certification services. We may request
            this information if, for example, we need to better understand the
            customer’s business, or if we have a legitimate interest in contacting a
            particular business or person but do not have accurate contact information.
            We will not collect or use any personal information relating to any end
            users of either our current or potential customers or registered user of
            our services.
        </p>
        <p>
            <strong>HOW WE USE YOUR INFORMATION</strong>
        </p>
        <p>
            <u>To Provide Requested Information Regarding Our Programs and Services</u>
            . You may sign up on our website to receive information from us about our
            programs, services, and business, or the businesses of third parties we are
            associated with (e.g., members of our Better World Network or security
            companies). Information about our services and business may include blog
            posts, emails, and information about apps and services that we currently
            offer, or new programs and services we may develop and offer over time. In
            addition, our programs may include conferences or events hosted or
            co-sponsored by AppEsteem or a third party we are associated with. At your
            option, you may receive this information by post, email, or other means.
        </p>
        <ul>
          <li>
            We provide this information to you based on your consent, your potential
            or actual contractual relationship with us, or our legitimate interests to
            register and administer your accounts on our website, so that you can
            receive the services you have registered for, and we can run our business
            operations. You may review or update your personal information or
            unsubscribe from receiving this information at any time. Please see “<u>Your Rights Regarding Your Information</u>,” below.
          </li>
        </ul>
        <p>
            <u>To Provide Unsolicited Information About Our Programs and Services</u>
            . If you provide us with personal information when you visit our website,
            and you do not sign up for any of our services, we may send you an email
            providing you with information about our services and inviting you to sign
            up. If you use any of our services and we think you may benefit from using
            another service we offer, we may send you an email about that as well. In
            addition, if we independently find a business that we believe may have a
            legitimate interest in our programs or services, we may use information
            from other sources (described above) to communicate with it. We include an
            “unsubscribe” option in every promotional email we send.
        </p>
        <ul>
          <li>
            Our unsolicited contact with any business regarding our services and
            potential contractual relationship is based on our legitimate interests to
            protect consumers by expanding the breadth of our programs, and our belief
            that such businesses may be interested in the opportunity to use our
            services. Any party we contact in this manner has the rights to control our
            use of their personal information, as described in this Privacy Statement,
            including the right to be deleted from our records. <u></u>
          </li>
        </ul>
        <p>
            <u>To Enable Use of Our Apps and Services</u>
            . We use information provided by you in downloading our apps, or
            registering for our services, to provide our apps and services to you and communicate with you about our apps
            or services, respond to your information or support requests, and otherwise to fulfill our obligations as
            necessary under our legal agreements with you.
        </p>
        <p>
            Although many of our apps and services are free, if you agree to purchase any paid apps or services from us, we
            may collect payment from you through your use of a personal check, wire transfer, or a credit card. If you pay by
            credit card, we use a third-party service provider to manage this processing. This provider has been contracted by
            us to process your information on our behalf and at our direction, and will collect, use, and process your
            information in accordance with their privacy policy, found here: <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer" >https://stripe.com/privacy</a>.<strong></strong>
        </p>
        <p>
            We may also use your contact information to provide you with system alert
            messages, such as if there are any changes to our services, Certification
            Requirements, policies, or agreements. In addition, we may communicate with
            you about your account, to provide customer support, to enforce our
            agreements with you, and to protect the rights and safety of consumers, our
            customers, and ourselves.
        </p>
        <ul>
          <li>
            Our use of your personal information to enable use of our apps and
            services is based on our legal agreement with you regarding the terms of
            such apps or services, including for us to provide the services, and for
            you to pay for the services, as applicable. You can terminate your use of
            our apps and services pursuant to the applicable agreement which governs
            such services, including our <Link to="/termsofuse">Terms of Use </Link>
            and <a href="https://appesteemstorage.blob.core.windows.net/public-documents/certification-agreement.pdf" target="_blank" rel="noopener noreferrer" >Master Services Agreement</a>.
          </li>
        </ul>
        <p>
            <u>To Reply to Your Contact</u>
            . We may use your email address, phone number, or other information, that
            you provide to us when you contact us, to respond to your questions or
            comments, and to maintain records of our correspondence. In our reply
            email, we will generally include certain navigational information to help
            us identify if our email has been opened by you, and to know if any links
            in our email have been clicked on. Please see “    <u>Navigational Information</u>” and “<u>HubSpot</u>” below for more
            information. If you contact us without providing information on how to
            reply, we will be unable to respond to your questions or comments.
        </p>
        <ul>
          <li>
            We process this information in order to perform a service that you request, such as responding to your
            comments or questions. Our processing of this information is further based on our legitimate interests
            in being responsive and providing information to consumers, and potential customers. We may also
            process this information based on your consent, or as required to carry out our obligations under a
            current or pending contact with you. You can terminate further contact with us as described in this
            Privacy Statement.
          </li>
        </ul>
        <p>
            <u>To Help Secure Our Website</u>
            . We, and the third-party host of our website, use navigational information
            to provide IT and network security, by reviewing log files for access
            breaches, violations of our Terms of Use, and other security threats. We
            collect navigational information in a way that does not identify you and we
            will not associate this data with any personal information from any source
            with the limited exception if we, or our third-party server, finds
            potentially suspicious or criminal activity associated with your usage.
        </p>
        <ul>
          <li>
            We process navigational information in this way to fulfill our legal
            obligation to implement appropriate technical and organizational measures
            to ensure a level of security appropriate to the risk of our information
            processing, and for the legitimate interests of using this information to
            ensure the security of our network and information.
          </li>
        </ul>
        <p>
            <u>To Operate and Improve our Website, Apps, and Services</u>
            . We use personal and navigational information to operate and improve our
            website, apps, and services, and to improve the quality of your experience
            with our website, apps, and services. In connection with this, we use the
            following third-party data processors to help collect and review our
            website data:
        </p>
        <p>
            <em>Google</em>
            : We have integrated the component of Google Analytics on our website
            through use of a cookie (described in more detail under “    <u>Navigational Information</u>,” below), placed on the information
            technology system of our website users. Google Analytics collects, gathers,
            and analyzes data about the behavior of visitors to our website by
            collecting data about the website a person came from, which subpages were
            visited, or how often or for what duration a subpage was viewed. Google
            Analytics uses the collected information, among other reasons, to evaluate
            the use of our website and provide online reports to us, which show the
            activities on our website and to provide other services concerning the use
            of our website for others. We use this information to better understand our
            website usage, and to help us optimize our website. Google’s services are
            provided through its Terms of Service with us, and are subject to Google’s    <a href="https://policies.google.com/privacy">Privacy Policy</a>. The
            operator of the Google Analytics component is Google Inc., 1600
            Amphitheatre Pkwy, Mountain View, CA 94043-1351, United States. You may
            prevent your data from being collected and used by Google Analytics by
            opting out through the use of the Google Analytics Opt-out Browser Add-on
            available at <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank" rel="noopener noreferrer" >https://tools.google.com/dlpage/gaoptout/</a>.
          <u></u>
        </p>
        <p>
            <em>HubSpot</em>
            : We use the services of HubSpot in the context of sending out electronic
            mail and other communication, and HubSpot uses cookies and tracking pixels
            (described in more detail under “<u>Navigational Information</u>,” below)
            to collect anonymized navigational information on our behalf. We use this
            information to better understand the success of our marketing efforts (such
            as when an email is opened, and whether any links in an email were
            clicked), and to optimize our communication efforts. More information can
            be found here: <a href="http://www.hubspot.com/security" target="_blank" rel="noopener noreferrer" >http://www.hubspot.com/security</a>
            , and in HubSpot’s    <a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noopener noreferrer" >Privacy Policy</a>. The
            operating company for HubSpot is 25 First Street, 2nd Floor Cambridge, MA
            02141 United States. To terminate our use of HubSpot in this capacity,
            please see “<u>Your Rights Regarding Your Information</u>,” below.
        </p>
        <p>
            <em>Microsoft Azure Active Directory</em>
            <em>(Azure AD): </em>
            We use Azure AD as an identity and access management cloud solution to help
            provide secured access to our Certification services, and also for our
            internal operations through Office 365. Azure AD provides the platform
            through which our users sign up for and access their accounts with us, and
            enables a single sign-on process, which simplifies access to user account
            on our website. Azure AD protects user data by using multi-factor
            authentication and other security measures. More information about
            Microsoft’s privacy practices can be found here: <a href="https://privacy.microsoft.com/en-us/PrivacyStatement" target="_blank" rel="noopener noreferrer" > 
            https://privacy.microsoft.com/en-us/PrivacyStatement</a>
            . The operating company for Microsoft is 1 Microsoft Way, Redmond, WA
            98052. If you cancel your account with us, your account information will be
            deleted, subject to Azure AD’s privacy practices. <u></u>
        </p>
        <ul>
          <li>
            We process navigational information, which may include personal
            information, in this way based on our legitimate interests of understanding
            the effectiveness of our marketing efforts, and to improve the quality of
            our website, apps, and services. We also may process navigational
            information when we obtain consent for a specific processing purpose. If
            the processing of personal information is necessary for performance of a
            contract to which you are (or may be) a party, for example, if you have
            registered for services from us, the processing is based on our current or
            potential contractual relationship.
          </li>
        </ul>
        <p>
            <u>To Compile Statistical and Research Information</u>
            . We may create anonymous data records that are derived from personal
            information by excluding any information that could make the data
            personally identifiable to you. This usage may provide us with statistical
            information to better understand the market and our services. This data is
            anonymous and cannot be used to identify or re-identify any individual. We reserve
            the right to use this data for any purpose and disclose anonymous data to
            third parties in our sole discretion.
        </p>
        <p>
            <u>To Make Necessary Disclosure</u>
            . We may disclose your personal information to comply with laws, court orders,
            litigation, or other legal processes, and/or in response to requests from governmental authorities within or
            outside of your country of residence. We may also disclose your personal information if we determine that
            disclosure is reasonably necessary to enforce our rights or protect our operations or users. Additionally, we may
            disclose your personal information to a successor or acquiring entity as part of a merger, acquisition,
            reorganization, bankruptcy, or other transaction. In such event, you will be notified via email or a notice on our
            website, and this notification will include any change in usage of your personal information. We may also share
            your personal information with our agents, contractors, and employees to perform any of the functions listed
            above, or as part of our general business operations; though in all such cases, we require the agent, contractor,
            or employee to acknowledge and adhere to this Privacy Statement regarding the use of your personal
            information.
        </p>
        <ul>
          <li>
            We process information based on these necessary disclosures to comply
            with the legal requirements of jurisdictions to which we are subject, and
            in our legitimate interests to resolve disputes, effect corporate events,
            protect consumers, and manage our operations.
          </li>
        </ul>
        <p>
            <u>Legal Basis for Processing Your Personal Information</u>
            . With regard to each item above, we have summarized the legal basis we
            anticipate having for processing any of your personal information. However,
            we reserve the right to process your personal information based on any
            other legal right we have under applicable law. We do not use your personal
        information in connection with any automatic decision-making or profiling.    <u></u>
        </p>
        <p>
            <u>Questions and Comments About Our Use of Collected Information</u>
            . If you do not want us to use your personal information in any of the ways
            described above or have questions about our use or the security of your
            personal information, please contact us using the information below under,
            “<u>How to Contact Us</u>.”
        </p>
        <p>
            <strong>NAVIGATIONAL INFORMATION</strong>
        </p>
        <p>
            We collect navigational information through our website to help improve
            your experience, and to better understand our users. This information is
            generally collected through third-party analytics tracking programs, which
            include Google analytics and HubSpot, as described above. Through these
            services, we collect and analyze the following navigational information:
        </p>
        <p>
            <u>Cookies</u>
            .<strong> </strong>A cookie is a small piece of information that a website
            asks a browser to store on your information technology system. We use
            cookies to enhance your usage of our website by “remembering” your actions
            or preferences. We may also use cookies to collect general usage and
            statistical information, including the access time and location from which
            you accessed our website, and the frequency of visits to our website. Some
            cookies may remain on your computer after you leave our website, generally
            as a text file that is placed on your hard disk by a web server. Cookies
            are not used to run programs or deliver viruses to your computer. They are
            uniquely assigned to each user and can only be read by a web server in the
            domain that issued the cookie to you. <strong></strong>
        </p>
        <p>
            We use third-party analytics services and other technologies to help
            analyze use of our website, applications, and services. These services use
            cookies to collect information on our website, which is then used to
            compile reports on user activity. These services may also transfer the
            information to third parties who are their affiliates, or where required to
            do so by law. Each service’s ability to use and share analytics information
            is restricted by their own terms of use and privacy policy. By using our
            website, applications, or services, you consent to the processing of
            Personal Data about you by these services in the manner and for the
            purposes set out above.
        </p>
        <p>
            While we do not offer an opt-out for cookies, your browsers will generally
            give you control over cookies used on your computer or mobile device. If
            you decide at any time that you no longer wish to accept cookies for any of
            the purposes described above, then you can instruct your browser, by
            changing its settings, to stop accepting cookies, or to prompt you before
            accepting a cookie from the websites you visit. You can also opt out of
            collection of data from Google Analytics by downloading a browser add-on
            from <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" >https://tools.google.com/dlpage/gaoptout</a>
            . You can generally disable the cookie feature in your browser without
            affecting your ability to access our website, except in some cases where
            cookies are used as an essential feature necessary for completing
            transactions.
        </p>
        <p>
            <u>Embedded URLs and Pixel Technologies</u>
            . We may track user behavior without using cookies, but by using embedded
            URLs and pixel technologies. This allows limited information to follow
            users as they navigate our website, communications, and services. A
            tracking pixel is a miniature graphic embedded in a webpage to enable log
            file recording and analysis, to subsequently perform statistical analysis.
            We use HubSpot’s services, which utilize embedded pixel technologies and
            cookies to help us identify unique user visits to our website, identify the
            pages viewed, and determine which emails have been opened by recipients.
            This information allows us to better understand the effectiveness of
            certain communications and our marketing efforts. We generally connect
            information gathered by these technologies with personal information of our
            customers. If you would like to opt-out of these emails, or withdraw your
        consent for this usage, please see “    <u>Your Rights Regarding Your Information</u>” below.
        </p>
        <p>
            <u>Log Files</u>
            . We may use log files that collect information (including demographic
            information, and information about your computer hardware and software)
            that is not associated with your name or other personal information. This
            demographic information may include your ZIP code, age, and gender, and
            computer information may include your IP address, browser type, domain
            names, internet service provider (ISP), files viewed on our website,
            operating system, clickstream data, access times, and referring website
            address. This information helps us use and administer our website and
            services, track our users’ movements in aggregate form, and gather broad
            demographic information of aggregate use of our website and services.
        </p>
        <p>
            <u>Single Sign-On</u>
            . You may have the option to log in to our website using a single sign-on
            service, as described above under “<u>Microsoft Azure AD</u>.” This service
            will authenticate your identity and provide you the option to share certain
            information with us, such as your name and email address, to pre-populate
            our sign-in form. Any use of a single sign-on service is at your option and
            will be based on your agreement with such third-party service.
        </p>
        <p>
            <u>Do Not Track</u>
            . We do not currently respond to “do not track” signals from web browsers but will reevaluate this
            Statement if a “do not track” standard becomes finalized in an applicable jurisdiction.
        </p>
        <p>
            <u>Other Websites</u>
            . This Privacy Statement discloses the privacy practices for our website, apps, and services;
            however, we may provide links to other websites. If you go to sites that are beyond our control, these sites may
            send their own cookies to your computer, collect data, or solicit personal information. Our data practices and
            procedures described here for AppEsteem do not apply to any external links. You are encouraged to read the
            privacy policies of any site linked through our website, applications, or services, especially if you share any
            personal information.
        </p>
        `;
        const para2 = `<p>
        <strong>
            HOW WE SECURE, STORE, &amp; RETAIN YOUR PERSONAL INFORMATION
        </strong>
    </p>
    <p>
        <u>Storage and Security of Personal Information</u>
        . We are concerned with protecting your privacy and data, and we take
        measures we believe are reasonable and appropriate to protect personal
        information from loss, misuse, and unauthorized access, disclosure,
        alteration, and destruction. No method of transmission over the Internet or
        method of electronic storage is 100% secure, however. Therefore, we cannot
        guarantee its absolute security. If there is a breach of our security and
        we believe that your personal information has been unlawfully accessed by a
        third-party, we will notify you in compliance with applicable laws. If you
        have created an account with us, we require that you provide a username and
        password to log in. You must keep your username and password secure, and
        never disclose it to a third party. We do not have access to your password,
        as it is created and managed through Azure AD.
    </p>
    <p>
        <u>Retention of Personal Information</u>
        . In general, we do not set specific timeframes for deletion of data but
        will retain personal information only for as long as necessary to achieve
        the purpose of storage. Thus, we will retain such data as long as your
        account is active, as long as needed to provide you with our services, and
        as long as necessary to comply with our legal obligations, resolve
        disputes, prevent abuse, or enforce our legal agreements. We conduct an
        annual review of the personal information we are holding and determine
        whether to retain that information based on the foregoing, and further
        considering: the current and potential future value of the information; the
        costs, risks, and liability associated with retaining the information; our
        ability to ensure the information is accurate and up to date; and the
        interests of the data holder in having the information deleted. After we no
        longer need personal information, we will delete it. We will also delete
        such Information at an earlier date if the data holder requests it, as
        described under “<u>Your Rights Regarding Your Information</u>” below,
        unless there is a reasonable basis for retaining such information as
        described in this Section.
    </p>
    <p>
        <strong>TRANSFER OF INFORMATION TO OTHER COUNTRIES</strong>
    </p>
    <p>
      The offices and servers we use are located in the United States, so if you are visiting our website or using our
      services from a different country, please be aware that you are sending information (which may include personal
      information) to the United States. That information may then be transferred within the United States or back out
      of the United States to other countries outside of your country of residence, depending on the type ofinformation
      and how it is stored by us. These countries (including the United States) may not necessarily have data protection
      laws as comprehensive or protective as those in your country of residence; however, our collection, storage, and
      use of your personal information will at all times continue to be governed by this Privacy Statement.
    </p>
    <p>
        <strong>INFORMATION ABOUT CHILDREN</strong>
    </p>
    <p>
        Our website, apps, and services, are not intended for use by or targeted at children under age 16, and we do not
        intentionally gather any information about children who are under this age. If we discover that a child under 16
        has submitted personal information to us, we will attempt to delete the
        information as soon as possible. If you believe that we might have personal
        information from a child under 16, please contact us at <a href="mailto:privacy@appesteem.com" target="_blank" rel="noopener noreferrer" >privacy@appesteem.com</a>, so we may
        delete the information. <u></u>
    </p>
    <p>
        <strong>YOUR RIGHTS REGARDING YOUR INFORMATION. </strong>
    </p>
    <p>
        You have several choices regarding your personal information, including the
        rights to:
    </p>
    <ul>
        <li>
            <u>Review the personal information you have provided us</u>
            . You have the right to request access to the personal information we
            have on you. You can do this by contacting us at <a href="mailto:privacy@appesteem.com" target="_blank" rel="noopener noreferrer" >privacy@appesteem.com</a>. We
            will make sure to provide you with a copy of the personal information
            we process about you in a structured, commonly used, and
            machine-readable way. To comply with your request, we may ask you to
            verify your identity. We will fulfill your request by sending your copy
            electronically, unless the request expressly specifies a different
            method.
        </li>
        <li>
            <u>
                Request that we correct any errors, outdated information, or
                omissions in your personal information that you have provided us
            </u>
            . If you believe that any of the Information we have about you is
            incorrect, you are welcome to contact us so we can update it and keep
            your personal information accurate. Any personal information that is no
            longer needed for the purposes specified in “<u>How We Use Your Information</u>,” above, will be deleted. If at any
            point you wish for us to delete information about you, you can simply
            contact us at <a href="mailto:privacy@appesteem.com" target="_blank" rel="noopener noreferrer" >privacy@appesteem.com</a>.
        </li>
        <li>
            <u>
                Request that we use your personal information differently that we
                are currently using it
            </u>
            . For example, you can request that your Information not be used to
            contact you, or that it be removed from any marketing list that we use.
        </li>
        <li>
            <u>
                Right to opt-out of being solicited by us, including through email
                communications
            </u>
            . When you receive communications from us, you may indicate a
            preference to stop receiving further communications from us and you
            will have the opportunity to “opt-out” by following the unsubscribe
            instructions provided in the e-mail you receive, by unsubscribing on
            our website, or by contacting us directly.
        </li>
        <li>
            <u>
                Right to request that all of your personal information be deleted
                from our records
            </u>
            . This right is often
            referred to as the “right to be forgotten,” in that you may request that we delete all personal
            information from our records, which we will do without undue delay, subject to any legal bases for
            continued processing we may have as described in this Privacy Statement, and applicable law and
            contract.
        </li>
        <li>
            <u>Right to object</u>
            . You have the right to object to how we process your information, and we ask that you
            contact us directly if you have any concerns or questions about our control or processing to help ensure
            you are comfortable with, and consent to, all of the ways we may use your personal information – as set
            forth in this Privacy Statement.
        </li>
    </ul>
    <p>
        We are privileged to have customers and website users from all over the
        world. As such, you may have certain other rights regarding protection of
        your personal information that are specific to your jurisdiction. If you
        believe you are entitled to additional rights outside of the ones listed in
        this Statement, please contact us at <a href="mailto:privacy@appesteem.com" target="_blank" rel="noopener noreferrer" >privacy@appesteem.com</a>.
    </p>
    <p>
        <strong>TERMINATED DATA COLLECTION</strong>
    </p>
    <p>
        We provide an electronic “certification seal” to certain vendors of
        products that have been certified by us. This certification seal will
        electronically identify the product as being in current compliance with our
        Certification Requirements and can be “read” by security companies to help
        those companies provide consumer protection. Prior to May 25, 2018, we
        offered a feature of the certification seal that allowed a vendor to
        collect anonymized consumer data for monitoring purposes through a system
        called SRCL (Self-Regulating Client Library). Any vendors that included
        this feature in a product were contractually obligated to obtain consent
        from any end user prior to collecting any data through SRCL.
        <u>
            In early May 2018, we instructed all vendors to remove any code with
            this capability from their products, terminated all data collection
            through SRCL, and deleted all previously collected data
        </u>
        . Despite this action, some vendors may retain a historical reference to
        SRCL in their Privacy Policy or Terms of Use and may not update the
        certification seal to remove the relic SRCL code. To ensure that no data is
        collected through SRCL, our systems will block any communication from a
        vendor app that attempts to provide us with any data through this feature.
    </p>
    <p>
        <strong>AMENDMENTS </strong>
    </p>
    <p>
      We constantly update the features of our website and services to better serve our customers and consumers.
      Accordingly, this Privacy Statement may be updated from time to time for any reason. We will notify you of any
      changes to our Privacy Statement by updating the policy online and changing the “Last Updated” date above. You
      should consult this Privacy Statement regularly for any changes. Your continued use of our website, and any
      apps or services, or continued provision of personal information to us, will be subject to the terms of the then-
      current Privacy Statement.
    </p>
    <p>
        <strong>HOW TO CONTACT US</strong>
    </p>
    <p>
        If you have any questions about our collection and use of your personal
        information, or to request that we take one of the actions listed above
        under “Your Rights Regarding Your Information,” please contact us by email
        at <a href="mailto:privacy@appesteem.com" target="_blank" rel="noopener noreferrer" >privacy@appesteem.com</a>, or by
        post at our corporate address, located at:
    </p>
    <p>
        <strong>
            AppEsteem Corporation
            <br/>
        </strong>
        655 156<sup>th</sup> Ave, SE, Suite 275
        <br/>
        Bellevue, Washington, USA 98007
        <br/>
        Attention: Privacy
    </p>`;
        const englishPolicy = `      <div>
            <div className="page-header">
                <Header as='h1'>
                    ${header}
                </Header>
            </div>
            ${para1}
            ${para2}
        </div>`;
        let translatedPolicy = "";
        this.state = {
            translatedPolicy,
            englishPolicy,
            header,
            para1,
            para2,
            loading: true,
        }
        this.translateLang = CommonUtil.lang;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.translateLang = CommonUtil.lang;
        this.getTranslation();
    }
    componentDidUpdate(prevProps) {
        if (this.translateLang !== CommonUtil.lang) {
            this.translateLang = CommonUtil.lang;
            this.getTranslation();
        }
    }
    getTranslation() {
        if (CommonUtil.IsEnglishLang()) {
            this.setState( c => {
              c.loading = false;
              return c;
            });
            return;
        }

        const titleListString = "privacypolicy/header@privacypolicy/para1@privacypolicy/para2";
        const titleDict = {"privacypolicy/header": this.state.header, "privacypolicy/para1": this.state.para1, "privacypolicy/para2": this.state.para2};
        const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
        let hasCachedText;
        axios.get(checkTextURL) // check if we need to send text over or not
        .then((response) => {
            hasCachedText = response.data;
        }, (error) => {
            hasCachedText = false; // treat as if returned false
        })
        .finally(() => {
            const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=privacypolicy`
                                                : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=privacypolicy`;
            const data = (hasCachedText) ? null : titleDict;
            axios.post(translateURL, data) // get translations
            .then((response) => {
                let translatedPolicy = `<div>
                    <div>
                        <div className="page-header">
                        <Header as='h1'>
                            ${response.data["privacypolicy/header"]}
                        </Header>
                        </div>
                        ${response.data["privacypolicy/para1"]}
                        ${response.data["privacypolicy/para2"]}
                    </div>
                </div>`;
                this.setState({
                    translatedPolicy: translatedPolicy, 
                    loading: false,
                });
            }, (error) => {
                this.setState({
                    translatedPolicy: `API error, could not translate`,
                    loading: false,
                });
            });
        });
    }

    render() {
        const englishPolicyHTML = Parser(this.state.englishPolicy);
        const translatedPolicyHTML = Parser(this.state.translatedPolicy);
        return ( 
            (!CommonUtil.IsEnglishLang()) 
            ?   <div>
                    {(!this.state.loading) 
                    ? <div>{translatedPolicyHTML}</div> 
                    : <div style={{display:"flex", "justify-content":"center"}}>
                        <div className="dimmable" style={{margin:"0 auto", width:"100px", height:"100px", top:"50%", position:"fixed"}}>
                            <div className="ui active inverted dimmer">
                                <div className="ui massive text loader" ></div>
                            </div>
                        </div>
                    </div>}
                </div>
            :   <div>{englishPolicyHTML}</div>
        );
    }
}

export default PrivacyPolicy;