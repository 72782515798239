import React, { Component } from 'react';
import { Button, Image} from 'semantic-ui-react';
import { PremiumUtil } from '../utils/PremiumUtil';
import {BlurMessages} from '../BlurMessages.js';
import axios from 'axios';
import { Settings } from '../Settings';
import { CommonUtil } from '../utils/CommonUtil';
import Parser from 'html-react-parser';

// ToDo: quickly add translation
class HomeSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasExtension: false,
            hasPremium: false,
            adText: "",
            buttonText: "",
            trialFromConfig: "",
            openSignup: false,
            accountExist: false,
            loading: true,
            loadingMessg: true,
            extensionStatus: "",
            trialMinsLeft: "",
            freePremiumMinsLeft: "",
            searchBlurCount: "",
            adsBlurCount: "",
            affiliateInfo: {logo: "", infoPage: "", name: ""},
            blurIntensity: -1, //PopupUtil.GetAdConfigValue("FadeIntensityHigh") ? PopupUtil.GetAdConfigValue("FadeIntensityHigh") : 0.8,
            lowFade: 0.7, //PopupUtil.GetAdConfigValue("FadeIntensityLow"),
            mediumFade: 0.8, //PopupUtil.GetAdConfigValue("FadeIntensityMedium"),
            highFade: 0.9, //PopupUtil.GetAdConfigValue("FadeIntensityHigh"),
            low: 3, //PopupUtil.GetAdConfigValue("BlurIntensityLow"),
            medium: 5, //PopupUtil.GetAdConfigValue("BlurIntensityMedium"),
            high: 15, //PopupUtil.GetAdConfigValue("BlurIntensityHigh")
            trialExtendedBy: ""
        };
        this.checkDelta = 400;
        this.maxTry = 10;
        this.tryCnt = 0;
        this.adTextObj = {
            hasPremium: 'Thanks for being our Premium customer',
            hasExtension: 'Please consider joining the fight against internet fraud',
            install: 'Want all deceptive apps and search results to be blurred?',
            verboseInstall: 'Want all deceptive apps and search results to be blurred? \n Install Browse.live Web Safety today and get safer browsing.',
            installButton: 'Install Browse.live Web Safety',
            upgradeButton: 'Upgrade to Premium',
        };
        this.trialExtended = "Good news: we have extended your trial",
        this.advertisementStr = "Advertisement";
        this.translateLang = CommonUtil.lang;
        this.trialMins = -1;
    }

    componentDidMount() {
        this.observeExtension();
        this.setExtensionData();
        if(!this.props.noApiCall) {
            PremiumUtil.CallWhatAmI();            
        }
        this.translateLang = CommonUtil.lang;
        BlurMessages.init(this.translateLang).then(() => {
            BlurMessages.translate();
        }).catch((err) => {
            //do nothing right now
        });
        this.setAffiliateInfo();
    }
    
    componentDidUpdate(prevProps) {        
        // TODO: right now this is not what we really want, because this redownloads translations each time. Fix with LanguageUtil
        if (this.translateLang !== CommonUtil.lang) {
            this.translateLang = CommonUtil.lang;
            BlurMessages.init(this.translateLang).then(() => {
                BlurMessages.translate();
            }).catch((err) => {
                //do nothing right now
            });
            this.setExtensionData();
        }
    }
    componentWillUnmount() {
    }
    observeExtension() {
        let mutationObserver = new MutationObserver(this.setExtensionData.bind(this));
        mutationObserver.observe(document.getElementById("blurlive-ext-info"), {childList: true, subtree: true  });
    }
    setExtensionData() {
        var extInfo = PremiumUtil.readExtensionInfo();
        if(extInfo.hasExtension) {
            this.setState(c => {
                c.hasExtension = extInfo.hasExtension;
                c.hasPremium = extInfo.isPremium;
                c.extensionStatus = extInfo.blurStatus;
                c.trialMinsLeft = extInfo.trialMinsLeft;
                c.freePremiumMinsLeft = extInfo.freePremiumMinsLeft;
                c.blurIntensity = parseFloat(extInfo.blurIntensity);
                c.searchBlurCount = extInfo.blurCount;
                c.adsBlurCount = extInfo.adBlurCount;
                c.trialExtendedBy = extInfo.trialExtendedBy;
                c.affiliateInfo.name = extInfo.affName;
                c.affiliateInfo.infoPage = extInfo.affUrl;
                c.affiliateInfo.logo = extInfo.affLogo;
                return c;
            }, () => {
                this.initTextOptions();
                this.setTrialStatus();
            })
        } else {
            this.initTextOptions();
            this.getConfigTrialPeriod();
        }
        this.setAffiliateInfo();
    }

    initTextOptions() {
        if (this.state.hasPremium) {
            this.getAdText('hasPremium');
        } else if (this.state.hasExtension) {
            if (this.props.noPremOffer) {
                return "";
            } else {
                this.getAdText('hasExtension', 'upgradeButton');
            }
        } else {
            if (this.props.verboseDesc) {
                this.getAdText('verboseInstall', 'installButton');
            } else {
                this.getAdText('install', 'installButton');
            }
        }
    }

    getAdText(adType, buttonType) {
        if (!this.props.noApiCall && !CommonUtil.IsEnglishLang()) {
            var data = {ad: this.adTextObj[adType], button: this.adTextObj[buttonType]};
            axios.post(Settings.TranslationURL, data, { 'params': {'fromLang': 'en', 'toLang': this.translateLang}}).then((response) => {
                this.setState({ adText: response.data.ad, buttonText: response.data.button });
            });
        } else {
            this.setState({ adText: PremiumUtil.Translate(this.adTextObj[adType], this.adTextObj[adType]), buttonText: PremiumUtil.Translate(this.adTextObj[buttonType], this.adTextObj[buttonType]) })
        }
    }

    getConfigTrialPeriod() {
        // temp fix to not show try it free for 7 days in case of AnyTech
        if(this.props.noApiCall) {
            return;
        }
        if(this.trialMins > 0) {
            this.setTrialFromConfig(this.trialMins);
        } else {
            // else get trial mins
            axios.get(Settings.ConfigURL)
            .then((resp) => {
                let response = resp.data;
                if(response && response.clientConfig && response.clientConfig.adConfig && response.clientConfig.adConfig.trialPeriodMins) {
                    this.trialMins = response.clientConfig.adConfig.trialPeriodMins;
                    this.setTrialFromConfig(this.trialMins);
                }
            }, (error) => {
                // nothing for now
            });
        }
    }
    setAffiliateInfo() {
        if(this.props.noApiCall) {
            return;
        }
        PremiumUtil.GetWhoAmI(this.translateLang).then(resp => {
            if(resp && resp.affiliateObject) {
                if (resp.affiliateObject.logo) {
                    this.setState( c => {
                        c.affiliateInfo.logo = resp.affiliateObject.logo;
                        return c;
                    })
                }
                if (resp.affiliateObject.infoPage) {
                    this.setState ( c => {
                        c.affiliateInfo.infoPage = resp.affiliateObject.infoPage;
                        return c;
                    })
                }
                if (resp.affiliateObject.affiliateString) {
                    this.setState ( c => {
                        c.affiliateInfo.name = resp.affiliateObject.affiliateString;
                        return c;
                    })
                }
            }
        })
    }
    
    setTrialFromConfig(trialMins) {
        if(!BlurMessages.isMessagesLoaded) {
            setTimeout(this.setTrialFromConfig.bind(this), 200);
            return;
        }
        let trialHrs = Math.ceil(trialMins/60);
        let trialLeftStr = "";
        if(trialHrs > 25) {
            let trialDays = Math.ceil(trialMins/(60*24));
            if(trialDays > 1) {
                trialLeftStr = PremiumUtil.TranslateWithParamWithoutParse("{1} DaysText", trialDays + " days", trialDays);
            } else {
                trialLeftStr = PremiumUtil.TranslateWithParamWithoutParse("{1} DayText", trialDays + " days", trialDays);
            }
        } else {
            if(trialHrs > 1) {
                trialLeftStr = PremiumUtil.TranslateWithParamWithoutParse("{1} HoursText", trialHrs + " hours", trialHrs);
            } else {
                trialLeftStr = PremiumUtil.TranslateWithParamWithoutParse("{1} HourText", trialHrs + " hours", trialHrs);
            }
        }
        let tryMessage = PremiumUtil.TranslateWithParamWithoutParse("lpTryItFree", '', trialLeftStr);
        this.setState(c=> {
            c.trialFromConfig = tryMessage;
            return c;
        })
    }

    setTrialStatus() {
        //do anything for welcome page
        //[TODO] page dont get opportunity where trial period is set properly
        if(window.location.href.indexOf("/welcome") > -1) {
            return;
        }
        if(!BlurMessages.isMessagesLoaded) {
            setTimeout(this.setTrialStatus.bind(this), this.checkDelta);
            return;
        }
        if(this.state.extensionStatus) {
            let extensionInfo = "";
            if(!isNaN(this.state.trialExtendedBy)) {
                extensionInfo = '<div>' + PremiumUtil.Translate(this.trialExtended, this.trialExtended) + '</div>';
            } else if(this.state.extensionStatus === "trial") {
                if(this.state.trialMinsLeft) {
                    let trialLeftTxt = "";
                    if(this.state.trialMinsLeft > (60*25)) {
                        let daysLeft = Math.ceil(this.state.trialMinsLeft/(60*24));
                        trialLeftTxt = PremiumUtil.TranslateWithParamWithoutParse("{1} DaysText", daysLeft + " days", daysLeft);
                    } else if(this.state.trialMinsLeft > 60){
                        let hoursLeft = Math.ceil(this.state.trialMinsLeft/(60));
                        trialLeftTxt = PremiumUtil.TranslateWithParamWithoutParse("{1} HoursText", hoursLeft + " hours", hoursLeft);
                    } else {
                        trialLeftTxt = PremiumUtil.TranslateWithParamWithoutParse("{1} HourText", "1 hour", 1);
                    }
                    extensionInfo = '<div>' + PremiumUtil.TranslateWithParamWithoutParse("messageTrialOngoing", '', trialLeftTxt) + '</div>';
                }
            } else if (this.state.extensionStatus === "premium") {
                if(this.state.freePremiumMinsLeft) {
                    let freePremiumLeftTxt = "";
                    if(this.state.freePremiumMinsLeft > (60*25)) {
                        let daysLeft = Math.ceil(this.state.freePremiumMinsLeft/(60*24));
                        freePremiumLeftTxt = PremiumUtil.TranslateWithParamWithoutParse("{1} DaysText", daysLeft + " days", daysLeft);
                    } else if(this.state.freePremiumMinsLeft > 60){
                        let hoursLeft = Math.ceil(this.state.freePremiumMinsLeft/(60));
                        freePremiumLeftTxt = PremiumUtil.TranslateWithParamWithoutParse("{1} HoursText", hoursLeft + " hours", hoursLeft);
                    } else {
                        freePremiumLeftTxt = PremiumUtil.TranslateWithParamWithoutParse("{1} HourText", "1 hour", 1);
                    }
                    extensionInfo = '<div>' + PremiumUtil.TranslateWithParamWithoutParse("messageFreePremiumOngoing", '', freePremiumLeftTxt) + '</div>';
                }
            }
            else if(this.state.extensionStatus === "trialEnd") {
                extensionInfo = '<div className="text-danger">' + PremiumUtil.TranslateWithParamWithoutParse("messageTrialExpired", "Your trial has expired") + '</div>';
            } 
            else if(this.state.extensionStatus === "premiumEnd") {
                extensionInfo = '<div className="text-danger">' + PremiumUtil.TranslateWithParamWithoutParse("messagePremiumExpired", "Your license has expired") + '</div>';
            }
            this.setState(c => {
                c.trialInfoHtml = extensionInfo;
                return c;
            })
        }
    }
    installClick() {
        PremiumUtil.InstallClick(this.props.fromPage, this.translateLang);        
    }
    upgradeClick() {
        PremiumUtil.UpgradeClick(this.props.fromPage);        
    }
    supportClick() {
    }
    renderAffiliateInfo() {
        if (this.state.affiliateInfo.logo && this.state.affiliateInfo.infoPage && this.state.affiliateInfo.name) {
            return (
                <div className="affiliate-info">
                    <div>
                    <i className="info circle icon"></i>&nbsp;<span blurmessagekey="InstalledBy">{PremiumUtil.Translate("InstalledBy", "installed by")}&nbsp;</span> <a href={this.state.affiliateInfo.infoPage} target='_blank'>{this.state.affiliateInfo.name}</a>
                    </div>
                    <img src={this.state.affiliateInfo.logo} alt="affiliate logo" width="auto" />
                </div>
                
            )
        } else return ""
    }
    renderButton() {
        if (this.state.hasPremium) {
            return (
                <div>{this.state.adText}</div>
            )
        } else if (this.state.hasExtension && this.state.buttonText) {
            return (
                <Button color='green' className="home-side-btn" onClick={this.upgradeClick.bind(this)}>{this.state.buttonText}</Button>
            ) 
        } else if (!this.state.hasExtension && this.state.buttonText) {
            return (
                <Button color='green' className="home-side-btn" onClick={this.installClick.bind(this)}>{this.state.buttonText}</Button>
            ) 
        } else return "";
    }
    renderExtensionInfoFooter() {
        if(this.state.hasPremium || this.state.hasExtension) {
            return (
                <div className="home-side-card-footer">
                    <a href={"/" + CommonUtil.lang + "/support"}>{PremiumUtil.Translate("SupportText", "Support")}</a>
                    &nbsp;|&nbsp;
                    <a href={"/" + CommonUtil.lang + "/support/uninstallHow"}>{PremiumUtil.Translate("UninstallText", "Uninstall")}</a>
                    &nbsp;|&nbsp;
                    <a href={"/" + CommonUtil.lang + "/privacypolicy"}>{PremiumUtil.Translate("PrivacyPolicyText", "Privacy")}</a>
                    &nbsp;|&nbsp;
                    <a href={"/" + CommonUtil.lang + "/termsofuse"}>{PremiumUtil.Translate("TermsOfServiceText", "Terms")}</a>
                </div>
            )
        } else {
            return (
                <div className="home-side-card-footer">
                    <a href={"/" + CommonUtil.lang + "/support"}>{PremiumUtil.Translate("SupportText", "Support")}</a>
                    &nbsp;|&nbsp;
                    <a href={"/" + CommonUtil.lang + "/privacypolicy"}>{PremiumUtil.Translate("PrivacyPolicyText", "Privacy")}</a>
                    &nbsp;|&nbsp;
                    <a href={"/" + CommonUtil.lang + "/termsofuse"}>{PremiumUtil.Translate("TermsOfServiceText", "Terms")}</a>
                </div>
            )
        }

    }
    setIntensity(value) {
        this.setState(c => {
            c.blurIntensity = value;
            return c;
        });
    }
    isValueSelected(value) {
        if(this.state.blurIntensity === value) {
            return true;
        }
        if(value >= 1 && this.state.blurIntensity >= 1) {
            //this is blur
            if(value === this.state.high && this.state.blurIntensity >= (this.state.high + this.state.medium)/2) {
                return true;
            }
            if(value === this.state.medium && this.state.blurIntensity >= (this.state.medium + this.state.low)/2 && this.state.blurIntensity < (this.state.high + this.state.medium)/2) {
                return true;
            }
            if(value === this.state.low && this.state.blurIntensity < (this.state.medium + this.state.low)/2) {
                return true;
            }
        } else if(value < 1 && this.state.blurIntensity < 1){
            //this is fade
            if(value === this.state.highFade && this.state.blurIntensity >= (this.state.highFade + this.state.mediumFade)/2) {
                return true;
            }
            if(value === this.state.mediumFade && this.state.blurIntensity >= (this.state.mediumFade + this.state.lowFade)/2 && this.state.blurIntensity < (this.state.highFade + this.state.mediumFade)/2) {
                return true;
            }
            if(value === this.state.lowFade && this.state.blurIntensity < (this.state.mediumFade + this.state.lowFade)/2 && this.state.blurIntensity > 0) {
                return true;
            }
        }
        return false;
    }
    ParseIntensityValue(intensity, baseIntensity) {
        let blurVal = parseInt(intensity);
        let fadeVal = parseFloat(intensity) - blurVal;
        let hasBlur = false;
        let hasFade = false;
        if(intensity === 0 && !isNaN(baseIntensity)) {
            let baseBlurVal = parseInt(baseIntensity);
            let baseFadeVal = parseFloat(baseIntensity) - blurVal;
            if(baseFadeVal > 0) {
                hasFade = true;
            }
            if(baseBlurVal > 0) {
                hasBlur = true;
            }        
        } else {
            if(fadeVal > 0) {
                hasFade = true;
            }
            if(blurVal > 0) {
                hasBlur = true;
            }
        }
        let opacityVal = 1 - fadeVal;
        if(opacityVal > 0) {
            opacityVal = opacityVal.toFixed(2);
        }
        return {hasBlur: hasBlur, hasFade: hasFade, opacityVal: opacityVal, blurVal: blurVal};
    }    
    getBlurFilter(intensity, baseIntensity) {
        if(isNaN(parseInt(intensity))) {
            return "";
        }
        var parsedDict = this.ParseIntensityValue(intensity, baseIntensity);
        if(parsedDict.hasBlur && parsedDict.hasFade) {
            return {filter: `blur(${parsedDict.blurVal}px)`,
                    opacity: `${parsedDict.opacityVal}`};
        } else if(parsedDict.hasBlur) {
            return {filter: `blur(${parsedDict.blurVal}px)`};
        } else {
            return {opacity: `${parsedDict.opacityVal}`};
        }
    }
    renderTestBox(value) {
        let scaled = (value < 1) ? value : Math.round(value/3);
        let blurImageStyle = this.getBlurFilter(scaled);
        let isSelected = this.isValueSelected(value);
        var style = {};
        if(isSelected) {
            style = {border: "2px solid #0F75BC"}
        }
        return (
            <div className="setBlurImage" style={style} intensity={value} onClick={() => this.setIntensity(value)}>
                <div style={blurImageStyle} >
                    <div className="tc3 setBlurText">123abc</div>
                    <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 30 30">
                        <path className="st1" d="M26.92,20.01c-0.02,5.48-4.47,9.91-9.95,9.89c-0.49,0-0.97-0.04-1.44-0.11l11.27-11.27  C26.89,19.01,26.92,19.51,26.92,20.01z"/>
                        <path className="st2" d="M26.81,18.53L15.54,29.8c-0.02,0-0.04-0.01-0.05-0.01c-0.07-0.49-0.11-0.98-0.11-1.49  c0.02-5.48,4.47-9.91,9.95-9.89C25.83,18.41,26.33,18.45,26.81,18.53z"/>
                        <path className="st2" d="M14.62,28.3c0,0.5-0.03,1-0.11,1.49c-0.02,0-0.04,0.01-0.05,0.01L3.19,18.53c0.49-0.08,0.98-0.11,1.49-0.12  C10.15,18.39,14.61,22.82,14.62,28.3z"/>
                        <path className="st1" d="M14.46,29.8c-0.47,0.07-0.95,0.11-1.43,0.11c-5.48,0.02-9.93-4.42-9.95-9.89c0-0.51,0.03-1,0.11-1.49  L14.46,29.8z"/>
                        <path d="M18.15,16.56c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0L18.15,16.56C18.15,16.55,18.15,16.56,18.15,16.56z"/>
                        <path className="st3" d="M24.48,7.61c0,1.87-1.47,3.39-3.31,3.47c-0.06,0-0.11,0-0.16,0h-2.52c0-0.54-0.12-1.04-0.34-1.5  c-0.1-0.15-0.19-0.31-0.27-0.47c0,0,0,0,0,0c-0.29-0.43-0.68-0.78-1.13-1.04l1.12-1.94l0,0c0-0.01,0-0.02,0.01-0.02c0,0,0,0,0,0  c0.05-0.12,0.11-0.23,0.19-0.33c0.03-0.05,0.06-0.09,0.09-0.14c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0-0.01c0.63-0.9,1.67-1.48,2.85-1.48  C22.92,4.13,24.48,5.69,24.48,7.61z"/>
                        <path className="st4" d="M24.48,14.56c0,1.92-1.56,3.48-3.48,3.48c-1.18,0-2.22-0.59-2.85-1.48c0,0,0-0.01,0-0.01l0,0c0,0,0,0,0,0  s0,0,0,0c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.07-0.11-0.1-0.16c0,0,0,0,0,0c-0.03-0.05-0.06-0.11-0.08-0.17c0,0,0,0,0,0  c0,0,0,0,0,0c0-0.01-0.01-0.02-0.01-0.03l-1.11-1.93c0.45-0.26,0.84-0.61,1.13-1.04c0,0,0,0,0,0c0.08-0.17,0.17-0.32,0.27-0.47  c0.22-0.45,0.34-0.96,0.34-1.5H21c0.05,0,0.11,0,0.16,0C23.01,11.18,24.48,12.7,24.48,14.56z"/>
                        <path className="st3" d="M13.27,8.07c-0.48,0.27-0.88,0.65-1.17,1.11c-0.06,0.12-0.12,0.23-0.2,0.34c-0.24,0.47-0.37,1-0.37,1.56v0  l-2.41,0H8.97c0,0,0,0,0,0c-1.91-0.02-3.45-1.57-3.45-3.48c0-1.92,1.56-3.48,3.48-3.48c1.21,0,2.28,0.62,2.9,1.56c0,0,0,0,0,0  c0.02,0.02,0.03,0.04,0.04,0.06l0.04,0.06l0.08,0.14l0.07,0.13L13.27,8.07z"/>
                        <path className="st4" d="M13.27,14.1l-1.14,1.98l-0.07,0.13c-0.03,0.05-0.05,0.09-0.08,0.14c-0.01,0.02-0.03,0.05-0.04,0.07  c-0.02,0.02-0.03,0.04-0.04,0.06c-0.62,0.94-1.69,1.55-2.9,1.55c-1.92,0-3.48-1.56-3.48-3.48c0-1.88,1.49-3.41,3.35-3.47h0.24  l2.41,0c0,0.56,0.13,1.09,0.37,1.56c0.07,0.11,0.14,0.22,0.2,0.34C12.39,13.44,12.8,13.83,13.27,14.1z"/>
                        <path className="st4" d="M18.48,4.13c0,0.53-0.12,1.03-0.33,1.48l0,0.01l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.03,0.05-0.06,0.09-0.09,0.14  c-0.07,0.11-0.13,0.22-0.19,0.33c0,0,0,0,0,0c0,0.01-0.01,0.02-0.01,0.02l0,0l-1.12,1.94c-0.51-0.29-1.1-0.46-1.73-0.46  c-0.63,0-1.22,0.17-1.73,0.46l-1.14-1.98l-0.07-0.13l-0.08-0.14l-0.04-0.06l-0.08-0.14c-0.21-0.45-0.33-0.95-0.33-1.48  c0-1.92,1.56-3.48,3.48-3.48C16.93,0.66,18.48,2.21,18.48,4.13z"/>
                        <path className="st3" d="M18.48,18.04c0,1.92-1.56,3.48-3.48,3.48c-1.92,0-3.48-1.56-3.48-3.48c0-0.53,0.12-1.03,0.33-1.48l0.08-0.14  c0.02-0.02,0.03-0.05,0.04-0.07c0.03-0.05,0.05-0.09,0.08-0.14l0.07-0.13l1.14-1.98c0.06,0.04,0.13,0.07,0.2,0.11  c0.06,0.03,0.12,0.06,0.19,0.09c0.01,0,0.01,0.01,0.02,0.01c0.05,0.02,0.1,0.04,0.15,0.06c0.09,0.03,0.18,0.06,0.28,0.09  c0.04,0.01,0.08,0.02,0.12,0.03c0.04,0.01,0.08,0.02,0.12,0.03c0.07,0.02,0.14,0.03,0.22,0.04c0.15,0.02,0.3,0.03,0.45,0.03  c0.08,0,0.16,0,0.23-0.01c0.07,0,0.14-0.01,0.2-0.02c0.04,0,0.08-0.01,0.11-0.02c0.05-0.01,0.09-0.01,0.13-0.02  c0.06-0.01,0.13-0.03,0.19-0.04c0.04-0.01,0.07-0.02,0.11-0.03c0.04-0.01,0.09-0.02,0.13-0.04c0.02-0.01,0.04-0.01,0.06-0.02  c0.05-0.02,0.1-0.03,0.15-0.06c0.06-0.02,0.11-0.05,0.17-0.07c0.04-0.02,0.08-0.03,0.11-0.06c0.05-0.02,0.09-0.05,0.14-0.08  l1.11,1.93c0,0.01,0.01,0.02,0.01,0.03c0,0,0,0,0,0c0,0,0,0,0,0c0.03,0.06,0.05,0.11,0.08,0.17c0,0,0,0,0,0  c0.03,0.06,0.06,0.11,0.1,0.16c0.03,0.05,0.06,0.09,0.09,0.14c0,0,0,0,0,0s0,0,0,0l0,0c0,0,0,0.01,0,0.01  C18.36,17.01,18.48,17.51,18.48,18.04z"/>
                        <circle className="st5" cx="15" cy="11.09" r="3.48"/>
                    </svg>
                </div>
            </div>
        );
    }
    renderTrialInfo() {
        if (this.state.hasPremium || this.state.hasExtension) {
            if(this.state.trialInfoHtml) {
                return (
                    <div id="extensionInfoId" className="home-side-status">
                        {Parser(this.state.trialInfoHtml)}
                    </div>
                )
            } else {
                return (
                    <div id="extensionInfoId" className="home-side-status"></div>
                )
            }
        } else
        if (!this.state.hasPremium) {
            return (
                <div className="home-side-status">
                    <div>
                        <div>{this.state.trialFromConfig}</div>
                    </div>
                    <a href="https://browse.live/websafety/pricing" target="_blank">{PremiumUtil.Translate("lpViewPricing", "View Pricing")}</a>
                </div>
            )
        } else {
            return "";
        }

    }
    renderBlurIntensity() {
        if (this.state.hasExtension && this.state.blurIntensity >= 0) {
            return (
                <div dir= "auto" className="home-side-extension-info-section">
                    <div className="home-side-status">
                        <div className="home-side-blur-control-text">{PremiumUtil.Translate("BlurIntensityText", "Blur intensity").toUpperCase()}</div>
                        <div className="setBlurBox">
                            {this.renderTestBox(this.state.highFade)}
                            {this.renderTestBox(this.state.mediumFade)}
                            {this.renderTestBox(this.state.lowFade)}
                            {this.renderTestBox(0)}
                            {this.renderTestBox(this.state.low)}
                            {this.renderTestBox(this.state.medium)}
                            {this.renderTestBox(this.state.high)}
                        </div>
                    </div>
                </div>
            )
        } else {
            return ""
        }
    }
    renderBluringStatus() {
        if (this.state.hasExtension && this.state.adsBlurCount && this.state.searchBlurCount) {
            return (
                <div dir= "auto" className="home-side-extension-info-section">
                    <div className="home-side-status">
                        <div className="home-side-blur-control-text">{PremiumUtil.Translate("TotalBlurred", "total blurred").toUpperCase()}</div>
                        <div className="setBlurBox home-side-blurring-results">
                            <div className="text-capitalize">{PremiumUtil.Translate("AdsText", "Ads")}</div><div className="font-xlarge">{this.state.adsBlurCount}</div>
                        </div>
                        <div className="setBlurBox home-side-blurring-results">
                            <div className="text-capitalize">{PremiumUtil.Translate("SearchResultsText", "Search results")}</div><div className="font-xlarge">{this.state.searchBlurCount}</div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return ""
        }
    }
    renderExtensionInfo() {
        if (!this.state.hasExtension && !PremiumUtil.CanShowInstall()) {
            return "";
        } else {
            return (
                <div className="home-side-card">
                    {this.renderButton()}
                    {this.renderTrialInfo()}
                    {this.props.hideBlurInfo ? ("") :(
                        <>
                            {this.renderBlurIntensity()}
                            {this.renderBluringStatus()}
                            {this.renderExtensionInfoFooter()}
                        </>
                    )}
                </div>
            )
        }
    }
    renderBlurLogo() {
        if(this.props.showLogo) {
            return (
                <a href={"/" + CommonUtil.lang}>
                    <Image src='/images/Blur_logo_white.png' className="navmenu-logo-img" />
                </a>
            )
        } else {
            return "";
        }
    }
    render() {
        return (
            <div className="home-side-section">
                {this.renderBlurLogo()}
                {this.renderAffiliateInfo()}
                {this.renderExtensionInfo()}
            </div>
        )
    }
}

export default HomeSideBar
