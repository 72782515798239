import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class BlogCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        if(!this.props.blogTitlesAndUrls || this.props.blogTitlesAndUrls.length === 0) {
            return ("");
        }
        var settings = {
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 10000,
            slidesToShow: 3,
            slidesToScroll: 1
        };        
        return (
            <Slider {...settings}>
                {this.props.blogTitlesAndUrls.map((blogObj, i) => {
                    return (
                        <div>
                            <a href={encodeURI(blogObj.url)}>
                                <img key={i} src={blogObj.imgUrl} 
                                    style={{
                                        width:"200px", 
                                        height:"100px", 
                                        objectFit: "cover", 
                                        background: "white", 
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                        marginRight: "auto",
                                        marginLeft: "auto"
                                    }}
                                />
                                <p className="carousel-text" style={{width:"200px"}}>{blogObj.title}</p>
                            </a>
                        </div>
                    )
                })}
            </Slider>
          );
    }
}

export default BlogCarousel;
