import axios from 'axios';
import {Settings} from '../Settings.js';
import Parser from 'html-react-parser';
const queryString = require('query-string');
const constVars = require('./Const.js')
const domainStrings = require('./DomainStrings.js')
export class CommonUtil {
  //set the language for the browse
  static SetBrowserLang() {
    let lang = navigator.language.toLowerCase() || navigator.userLanguage.toLowerCase();
    CommonUtil.SetLanguage(lang);
  }
  //also verifies and filters languages
  static SetLanguage(lang) {
    let subLang = lang;
    if (lang && lang.length === 5) {
        subLang = lang.substr(0, 2);
    }
    if (lang) {

        //making sure that we always use supported languages for API calls
        if (constVars.languages.some(s => (s.code.toLowerCase() === lang.toLowerCase()))) {
            CommonUtil.lang = lang;
        } else if (constVars.languages.some(s => (s.code.toLowerCase() === subLang.toLowerCase()))) {
            CommonUtil.lang = subLang;
        } else {
            CommonUtil.lang = "en";
        }
    }
  }
  static UpdateLangPath(location, newLang, oldLang) {
    let paths = location.pathname.split("/");
    paths[1]= newLang;
    var params = queryString.parse(location.search);
    params = params ? params : {};
    if(oldLang) {
      params["olang"] = oldLang;
    } else if(params["olang"]) {
      delete params["olang"]
    }
    var search = queryString.stringify(params);
    if(search && search[0] != "?") {
      search = "?" + search;
    }
    return (paths.join("/") + search);
  }
  static SetBackOldLang() {
    if(CommonUtil.oldLang) {
      CommonUtil.SetLanguage(CommonUtil.oldLang);
    } else {
      CommonUtil.SetBrowserLang();
    }
    CommonUtil.oldLang = "";
  }
  static IsOldLangEnglish() {
    if(CommonUtil.oldLang && !CommonUtil.oldLang.toLowerCase().startsWith("en")) {
      return false;
    }
    return true;
  }
  static IsEnglishLang() {
    if(CommonUtil.lang && !CommonUtil.lang.toLowerCase().startsWith("en")) {
      return false;
    }
    return true;
  }
  static loadGCE() {
    //check if id exist then remove it and reinject
    //make sure we dont keep re-injecting in same page
    (function() {
      var cx = '53a164ad53951ac4a';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
    })();    
  }
  static IsHomePage(location) {
    if(location.pathname === "/") {
      return true;
    }
    if(location.pathname === ("/" + CommonUtil.lang + "/")) {
      return true;
    }
    if(location.pathname === ("/" + CommonUtil.lang)) {
      return true;
    }
    return false;
  }
  static IsDomainPage(location) {
    if(location.pathname.startsWith("/" + CommonUtil.lang + "/domains")) {
      return true;
    }
    if(location.pathname.startsWith("/" + CommonUtil.lang + "/querykey")) {
      return true;
    }
    if(location.pathname.startsWith("/" + CommonUtil.lang + "/adnetwork")) {
      return true;
    }
    if(location.pathname.startsWith("/" + CommonUtil.lang + "/download")) {
      return true;
    }
    return false;
  }
  static IsBlogPage(location) {
    if(location.pathname.startsWith("/blog")) {
      return true;
    }
    return false;
  }
  static IsNewtabPage(location) {
    if(location.pathname.startsWith("/newtab")) {
      return true;
    }
    if(location.pathname.startsWith("/" + CommonUtil.lang + "/newtab")) {
      return true;
    }
  }
  // domain count response -> [{ text: 'adobe.com', value: 200, }, {...}]
  static arrayToWordCloudList(domainCountData, targetArray) {
    let wordCloudList = [];
    domainCountData.forEach(domainEntry => {
      domainEntry[targetArray].forEach(targetObj => {
        // Add the values of entries with the same 'name' together; else add a new entry
        let objExists = false;
        if (targetObj.name !== null) {
          let name = targetObj.name.length > 13 ? targetObj.name.substr(0, 10) + "..." : targetObj.name;

          for(var i = 0; i < wordCloudList.length; i++) {
              if (wordCloudList[i].key == targetObj.name) {
                objExists = true;
                wordCloudList[i].value += 100 * targetObj.count;
                break;
              }
          }
          if (!objExists) {
            var wordCloudObj = {};
            wordCloudObj.key = targetObj.name;
            wordCloudObj.text = name;
            wordCloudObj.value = 100 * targetObj.count;
            wordCloudObj.type = targetArray;
            // wordCloudObj.that = this;
            wordCloudList.push(wordCloudObj);
          }
        }
      });
    });
    return wordCloudList;
  }

  static findHeaderString(key) {
    if(key && constVars.headerStrings[key]) {
      return constVars.headerStrings[key];
    }
    return key;
  }

  static findDomainString(key) {
    if(key && domainStrings.headerStrings[key]) {
      return domainStrings.headerStrings[key];
    }
    return key;
  }

  static parseDatewhen(datewhen) {
    let year = "20" + datewhen.slice(0, 2);
    let month = parseInt(datewhen.slice(2, 4)) - 1; // 0-index month
    let day = datewhen.slice(4, 6);
    let date = new Date(year, month, day);
    return date;
  }

  static getSupportedLanguageCode(lang) {
    let suppLang = "";
    let subLang = lang;
    if (lang) {
        subLang = lang.split("-")[0];
    }
    if (lang) {
        //making sure that we always use supported languages for API calls
        if (constVars.languages.some(s => (s.code.toLowerCase() === lang.toLowerCase()))) {
          suppLang = lang;
        } else if (constVars.languages.some(s => (s.code.toLowerCase() === subLang.toLowerCase()))) {
          suppLang = subLang;
        }
    }
    return suppLang
  }
  static findLanguage() {
    let lang = "";
    if(window.location && window.location.pathname) {
      var paths = window.location.pathname.split("/");
      if(paths.length > 1) {
        var ulang = paths[1];
        lang = CommonUtil.getSupportedLanguageCode(ulang)
      }
    }
    if(!lang) {
      var blang = navigator.language.toLowerCase() || navigator.userLanguage.toLowerCase();
      if(navigator.languages) {
        for(var ix in navigator.languages) {
          lang = CommonUtil.getSupportedLanguageCode(navigator.languages[ix]);
          if(lang) {
            break;
          }
        }        
      }
    }
    if(!lang) {
      lang = "en";
    }
    return lang;
  }
  static isBrowserFireFox() {
    if(navigator.userAgent.match(/(?=.*Firefox\/([6-9]\d|[1-9]\d{2,}))/gi)) {
      return true;
    } 
    return false;
  }
  static isBrowserEdge() {
    if(navigator.userAgent.match(/(?=.*Edg\/([7-9]\d|[1-9]\d{2,}))/gi)) {
      return true;
    } 
    return false;
  }
  static isAdTest() {
    if(window.location && window.location.href &&
      (window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("staging.blur.live") > -1)) {
        return "on";
   }
   return "off";
  }
  // Insert string between brackets {} for translations
  static insertInternalString(fullString, internalString) {
    return fullString.replace("{}", internalString);
  }
  // static async getLocalCountry() {  
  //   return axios.get(Settings.WhatAmIUrl).then((response) => {
  //     if (response.status === 200) {
  //       return response.data.country;
  //     } else {
  //       return false; // return false if country is unavailable
  //     }
  //   });
  //  }
  static getCountriesList() {
    return constVars.countries;
  }
  static encodeCountriesList(countriesList) {
    let encodedString = "";

    for (let i = 0; i < countriesList.length; i++) {
      let newString = this.encodeTranslationString(countriesList[i].name);
      encodedString += newString;
    }
    return encodedString;
  }
  static countriesListToDictionary(countriesList, translatedCountryNames) {
    let countriesDictionary = {};
    if (translatedCountryNames) {
      for (let i = 0; i < countriesList.length; i++) {  
        countriesDictionary[countriesList[i].code] = translatedCountryNames[i]; // Match country ISO with translated name
      }
    } else {
      for (let i = 0; i < countriesList.length; i++) {
        countriesDictionary[countriesList[i].code] = countriesList[i].name;
      }
    }
    return countriesDictionary;
  }
  static encodeTranslationString(string) {
    return "<p>" + string + "</p>";
  }
  static decodeTranslationString(encodedString) {
    let pipeSeparatedString = encodedString.replaceAll("<p>", "").replaceAll("</p>", "|");
    return pipeSeparatedString.split("|");
  }

  // Country name translation workflow:
  // 1. Encode all countries to a single, HTML separated string (<p> string </p>)
  //     | and # separated strings result in translation issues for some languages (especially DE)
  // 2. Translate countries string
  // 3. Decode countries string into array
  // 4. Return as dictionary (iso: countryName)
  static async getTranslatedCountriesDict(translateLang) {

    let countriesList = this.getCountriesList();
    if (translateLang == "en") {
      return this.countriesListToDictionary(countriesList, "");
    }

    let encodedCountriesString = this.encodeCountriesList(countriesList);
    const titleListString = "@country/countryNames"
    const titleDict = {
                      "country/countryNames": encodedCountriesString,
    };                          
    const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
    let hasCachedText;
    return axios.get(checkTextURL) // check if we need to send text over
    .then((response) => {
      if (response.data) {
        hasCachedText = response.data;
      } else {
        hasCachedText = false;
      }
      const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${translateLang}&isHTML=true&titles=${titleListString}&cachePage=country`
                            : `${Settings.TranslationURL}?fromLang=en&toLang=${translateLang}&isHTML=true&cachePage=country`;
      const data = (hasCachedText) ? null : titleDict;

      return axios.post(translateURL, data)
      .then((response) => {
        let translatedEncodedCountries = response.data["country/countryNames"];
        let decodedCountriesList = this.decodeTranslationString(translatedEncodedCountries);
        let translatedCountriesDict = this.countriesListToDictionary(countriesList, decodedCountriesList);
        return translatedCountriesDict;
      }, (error) => {
        //console.log("error: ", error);
        return error;
      })
    });
  }

}
CommonUtil.oldLang = "";
CommonUtil.lang = CommonUtil.findLanguage();
CommonUtil.langResolved = false;
CommonUtil.noApiCalls = false;

