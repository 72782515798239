import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { BlurMessages } from '../BlurMessages';

class SocialShare extends Component {
    constructor(props) {
        super(props);
        this.state = {isLoaded: false};
     }
    
     componentDidMount() {
        let lang = "";
        lang = navigator.language || navigator.userLanguage;
        BlurMessages.init(lang).then(() => {
            this.setState({isLoaded: true});
        }); 
     }

    render() {
        if (this.state.isLoaded) {

            // locolized URL of shared page
            const userLanguage = navigator.language || navigator.userLanguage;
            var sharedUrl = 'https://blur.live/lp';
            if (userLanguage) {
                sharedUrl += "/" + userLanguage;
            }
            // shared message
            let sharedMessage = BlurMessages.getMessage("ShareMessage", sharedUrl);
            // URLs for Social media sites
            const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${sharedUrl}&quote=${sharedMessage}`;
            const twitterUrl = `https://www.twitter.com/intent/tweet?status=${sharedMessage}`;
            const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${sharedUrl}`;

            return (
                <div className="socialShareContainer">
                        <span className="socialShare-icons">
                            <a href={facebookUrl} target="_blank"><Icon name='facebook' /></a>
                            <a href={twitterUrl} target="_blank"><Icon name='twitter square' /></a>
                            <a href={linkedinUrl} target="_blank"><Icon name='linkedin' /></a>
                        </span>
                    <span className="socialShare-text">{this.props.translatedShareText}</span>         
                </div>
            );
        } else 
        {
            return "";
        }
    }
}

export default SocialShare;