import React, { Component } from 'react';
import {Header} from 'semantic-ui-react';
import { PremiumUtil } from '../utils/PremiumUtil.js';

class Subscribed extends Component {
    render() {
      //we assume the component will be called after check from calling component PremiumBody.js
      return (
        <div>
          <br/><br/>
          <Header as='h2' color='blue'> {PremiumUtil.Translate("ppWelcome", "Welcome!")}</Header>
          <Header as='h5' color='blue'>{PremiumUtil.Translate("ppWelcomeMsg", "We love our premium customers")}</Header>                  
          <br/><br/>
        </div>                
      )
    }
}

export default Subscribed;
