let baseUrl = "https://api.blur.live";
//let baseUrl = "https://staging-dot-blur-live-project.appspot.com";
//let baseUrl= "http://localhost:5001";
export const Settings = {
    PingExtensionUrl: `${baseUrl}/license/pingextension`,
    WhatAmIUrl: `${baseUrl}/license/whatami`,
    ChromeStoreUrl: "https://chrome.google.com/webstore/detail/blurlive/elfhgaheiifomcjlchhhkfhkplhnkepl",
    ContactUrl: `${baseUrl}/blur/contact`,
    AnalyticsUrl: `${baseUrl}/blur/analytics`,
    SiteAnalyticsUrl: `${baseUrl}/blur/siteanalytics`,
    FeedbackUrl: `${baseUrl}/blur/sendfeedback`,
    SupportUrl: `${baseUrl}/blur/getsupport`,
    AppInfoUrl: `${baseUrl}/appInfo/v2/app`,
    WhoAmIUrl: `${baseUrl}/license/whoami`,
    WhoAmIWithSyncUrl: `${baseUrl}/license/whoami?sync=y`,
    SendAuthCodeUrl: `${baseUrl}/license/sendauthcode`,
    VerifyAuthCodeUrl: `${baseUrl}/license/verifyauthcode`,
    InviteUrl: `${baseUrl}/license/invite`,
    EnrollUrl: `${baseUrl}/license/enroll`,
    UnenrollMeUrl: `${baseUrl}/license/unenrollme`,
    UnenrollThemUrl: `${baseUrl}/license/unenrollthem`,
    SubscriptionBlurIdsUrl: `${baseUrl}/license/subscriptionblurids`,
    SignOutUrl: `${baseUrl}/license/signout`,
    RegisterAffiliateUrl: `${baseUrl}/license/registeraffiliate`,
    TranslationURL: `${baseUrl}/blur/translate`,
    TranslationWithoutTextURL: `${baseUrl}/blur/translateTitles`,
    CheckTextURL: `${baseUrl}/blur/hasText`,
    ConfigURL: `${baseUrl}/blur/config`,
    SearchAppsURL: `${baseUrl}/appInfo/search`,
    DomainCountsURL: `${baseUrl}/blur/domains`,
    TopDataUrl: `${baseUrl}/blur/tops`,
    RanksDataUrl: `${baseUrl}/blur/ranks`,
    FindDataUrl: `${baseUrl}/blur/find`,
    NamesUrl: `${baseUrl}/blur/names`,
    //for subscription and estimates
    EstimateUrl: `${baseUrl}/license/estimate`,
    SubscribeUrl: `${baseUrl}/license/subscribe`,
    BlurMessagesUrl: `${baseUrl}/blur/messages`,
    MyCommerceCart: `${baseUrl}/myCommerce/cart`,
    MyCommerceCarts: `${baseUrl}/myCommerce/carts`,
    MyCommerceManagePaymentUrl: `${baseUrl}/license/subscription/managepayment`,
    MyCommerceCancelSubscriptionUrl: `${baseUrl}/license/subscription/cancel`,
    //AppEsteem blog
    BlogContentLink: "https://blog.appesteem.com/syndication.axd",

    //other const settings
    RecaptchaSitekey: "6Ld2q7AUAAAAAAY28KNeGCrGySLRTMzT4i1gfvhB",
    TranslateApiKey: "AIzaSyCcM-iL53snK6mkRs5ARLEiRJnW_cMEafA",
    ChargebeeSiteKey: "blur",
    ChargebeePublishableKey: "live_jxGf7fypNFjXJg5uNp9zzt1cuxhRArYb7",
    ChargebeeYearlyPlanId: "blur.live-premium-ad-blurring-annual",
    ChargebeeHalfYearlyPlanId: "blur.live-premium-ad-blurring-six-months",
    ChargebeeMonthlyPlanId: "blur.live-premium-ad-blurring-one-months"
}
