export class ReactGAInstance {
  static Initialize() {
    ReactGAInstance.GTMId = 'UA-80789321-3';
    //need to find a way to wait for initialization

    //initalized by index.html
    //TagManager.initialize('UA-80789321-3', { 'optimize_id': 'GTM-KS5X96C'});
  }
  static GetGtag() {
    var loc = window.location.href;
    if(loc.indexOf("localhost:") > -1) {
      return null; //no need to give gtag reference if its localhost
    }
    if(window.gtag) {
      return window.gtag;
    }
    return null;
  }
  static Set(key, val) {
    if(!key) {
      return; //no point setting unknow key
    }
    //not sure about this yet
  }
  static PageView(path) {
    //do nothing here since its taken care by GTM
    let gtag_inst = ReactGAInstance.GetGtag();
    if(gtag_inst) {
      gtag_inst('config', ReactGAInstance.GTMId, {'page_path': path});      
    }

    if(path.indexOf("/lp") > -1) {
      ReactGAInstance.OptimizeEvent();
    }
    //now send the microsoft UET page view
    let loc = window.location.href;
    if(window.uetq && loc.indexOf("localhost:") === -1) {
      window.uetq = window.uetq || []; 
      window.uetq.push('event', 'page_view', { 'page_path': path });     
    }
  }
  static Event(category, action, label, value, otherDim) {
    //need to find a way to do this
    let gtag_inst = ReactGAInstance.GetGtag();
    var event = {
      'event_action': action,
      'event_label': label,
      'event_value': value,
      'event_category': category,
      'non_interaction': true
    };
    if(otherDim) {
      for(let key in otherDim) {
        if(key) {
          event[key] = otherDim[key];
        }
      }
    }
    if(gtag_inst) {
      gtag_inst('event', label, event);      
    }

    //now send the microsoft UET page view
    let loc = window.location.href;
    if(window.uetq && loc.indexOf("localhost:") === -1) {
      window.uetq = window.uetq || []; 
      window.uetq.push({ 'ec':category, 'ea':action, 'el':label, 'ev':value });     
    }

  }
  static PremiumSubscEvent(subscId) {
    if(!subscId) {
      return; //no point sending event if we dont know subscriptionId
    }
    let otherDim = {subscId: subscId};
    ReactGAInstance.Event("Premium", "Signup", "Premium Signup", 2, otherDim);
  }

  static OptimizeEvent() {
    let otherDim = {};
    ReactGAInstance.Event("optimize.activate", "activate", "optimize.activate", 2, otherDim);
  }
}
