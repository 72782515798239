import React, { Component } from 'react';
import { Container, Grid, Divider, Header, Form, Message} from 'semantic-ui-react';
import { PremiumUtil } from '../utils/PremiumUtil.js';
import AeConfirm from '../common/AeConfirm.js';


class PremiumFooter extends Component {
  constructor(props) {
    super(props);
  }
  manageMyCommercePayment() {
    this.props.manageMyCommercePayment(this.props.selectedSubscId);
  }
  cancelMyCommerceSubscription() {
    AeConfirm().then((resp) => {
      this.props.cancelMyCommerceSubscription(this.props.selectedSubscId);
    }).catch(err => {
      //do nothing here;
    })
  }
  renderBlurInfo() {
    let rows = [];
    let email = PremiumUtil.GetAuthenticatedEmail();
    let browserName = PremiumUtil.GetBrowserName() ;
    let browserCount = PremiumUtil.GetBrowserCount();
    let subscribedBy = PremiumUtil.GetSubscribedByEmail();
    if(email) {
      rows.push(
        <Grid.Row>
          <Grid.Column><label>{PremiumUtil.Translate("ppLoggedInAs", "Logged in as:")}</label> {email}</Grid.Column>
        </Grid.Row>
      );
    }
    if(browserName) {
      rows.push(
        <Grid.Row>
          <Grid.Column><label>{PremiumUtil.Translate("BrowserName", "Browser Name:")}</label> {browserName}</Grid.Column>
        </Grid.Row>
      );
    }
    if(browserCount > 0) {
      rows.push(
        <Grid.Row>
          <Grid.Column><label>{PremiumUtil.Translate("ppNumberOfBrowsersEnrolled", "Number of browsers enrolled:")}</label> {browserCount}</Grid.Column>
        </Grid.Row>
      );
    }
    if(subscribedBy) {
      rows.push(
        <Grid.Row>
          <Grid.Column><label>{PremiumUtil.Translate("ppSubscribedBy", "Subscribed By:")}</label> {subscribedBy}</Grid.Column>
        </Grid.Row>
      );
    }
    return (
      <Grid className="footerBlurInfo">
        {rows.map((row, i) => {
          return row;
        })}
      </Grid>
    )
  }
  renderAuthStatus() {
    if(!PremiumUtil.IsAuthenticated()) {
      //this is not authenticated
      return (
        <span>
          <a href="javascript:void(0)" onClick={this.props.signIn}>{PremiumUtil.Translate("ppSignIn", "Sign In")}</a>
        </span>
      )
    }

    return (
      <span>
        <a href="javascript:void(0)" onClick={this.props.signOut}>{PremiumUtil.Translate("ppSignOut", "Sign out")}</a>
      </span>
    )
  }
  renderManagePayment() {
    var ownedSubsc = PremiumUtil.getOwnedSubscription(this.props.selectedSubscId);
    if(ownedSubsc)
    {
      if(ownedSubsc.isChargeBee) {
        return (
          <span>
            <a href="javascript:void(0)" onClick={this.props.showManagePayment.bind(this)}>{PremiumUtil.Translate("ppManagePayment", "Manage Payment")}</a>
          </span>
        )
      }
      else if(ownedSubsc.isMyCommerce) {
        return [
          <span>
            <a href="javascript:void(0)" onClick={this.manageMyCommercePayment.bind(this)}>{PremiumUtil.Translate("ppManagePayment", "Manage Payment")}</a>
          </span>,
          <span>
          <a href="javascript:void(0)" onClick={this.cancelMyCommerceSubscription.bind(this)}>{PremiumUtil.Translate("ppCancelSubscription", "Cancel Subscription")}</a>
        </span>
      ]
      }
    }
  }
  renderQuickUnEnroll() {
    if(!PremiumUtil.GetSubscriptionObj()) {
      //if this is not subscribed
      return ("")
    }
    return (
      <span>
        <a href="javascript:void(0)" onClick={this.props.unEnrollMe}>{PremiumUtil.Translate("ppUnenrollTheBrowser", "Unenroll the browser")}</a>
      </span>
    );
  }
  renderManageEnrollment() {
    if(!PremiumUtil.IfShowManageEnrollment()) {
      //this is not authenticated
      return ("")
    }
    return (
      <span>
        <a href="javascript:void(0)" onClick={this.props.manageEnrollments}>{PremiumUtil.Translate("ppManageEnrollments", "Manage enrollments")}</a>
      </span>
    );
  }
  renderAffiliateLogo(affObj) {
    if(affObj.logo) {
      return (
        <img src={affObj.logo} alt="affiliate logo" width="auto" />
      )
    }
    //if not found
    return ("");
  }
  renderAffiliateStatus() {
    let affObj = PremiumUtil.GetAffiliateObj();
    if(affObj) {
      return [
        <Divider clearing style={{marginTop:"5px"}}> </Divider>,
        <Grid.Row className="footerAffiliate">
          <Grid.Column width="6" className="logoBox">
            {this.renderAffiliateLogo(affObj)}
          </Grid.Column>
          <Grid.Column width="10" className="affInfo">
              Blur.live {PremiumUtil.Translate("InstalledBy", "installed by")} <a href={affObj.infoPage ? affObj.infoPage : ''} target='_blank' rel='noopener noreferrer'><b>{affObj.affiliateString}</b></a>
          </Grid.Column>
        </Grid.Row>
      ]
    }
    return(
      <p>&nbsp;</p>
    )
  }
  render() {
    if(this.props.loading) {
      return (
        <Grid className="footerLoading">
          <Grid.Row>
            &nbsp;
          </Grid.Row>
        </Grid>
      )
    }
    return(
      <Grid>
        <Grid.Row>
          {this.renderBlurInfo()}
        </Grid.Row>
        <Grid.Row className="footerLinkRow">
          {this.renderAuthStatus()}
          {this.renderManagePayment()}
          {this.renderQuickUnEnroll()}
          {this.renderManageEnrollment()}
        </Grid.Row>
        {/* now show affiliates at the bottom of footer */}
        {this.renderAffiliateStatus()}
      </Grid>
    )
  }
}

export default PremiumFooter;