import React from 'react';
import axios from 'axios';
import {Settings} from '../Settings.js';
import { ReactGAInstance } from '../connectors/ReactGAInstance.js';
import {BlurMessages} from '../BlurMessages.js';
import Parser from 'html-react-parser';

const enableTimeOfDay = false;

export class PremiumUtil {
    static GetTopData() {
      var url = Settings.TopDataUrl;
      return axios.get(url, { withCredentials: true });      
    }
    static SearchFragData(type, frag, take = 10, skip = 0) {
        var url = Settings.FindDataUrl + '?type=' + type + 
                                         '&frag=' + frag +
                                         '&take=' + take +
                                         '&skip=' + skip;
        return axios.get(url, { withCredentials: true });      
    }
    static SendAnalytics(category, action, label) {
      var url = Settings.AnalyticsUrl;
      let data = {
        BrowserUserAgent: window.navigator.userAgent,
        BlurVersion: "",
        BlurTelemetryVersion: "",
        BlurSignature: "",
        BrowserLocale: window.navigator.userLanguage || window.navigator.language,
        AnalyticsType: "BrowserEvent",
        EventLabel: label,
        EventCategory: category,
        EventAction: action,
      }
      return axios.post(url, data, { withCredentials: true });
    }
    static SendSiteAnalytics(category, action, label, infoDict=null) {
      var url = Settings.SiteAnalyticsUrl;
      let data = {
        BrowserUserAgent: window.navigator.userAgent,
        BlurVersion: "",
        BlurTelemetryVersion: "",
        BlurSignature: "",
        BrowserLocale: window.navigator.userLanguage || window.navigator.language,
        AnalyticsType: "BrowserEvent",
        EventLabel: label,
        EventCategory: category,
        EventAction: action,
        InfoDict: infoDict
      }
      return axios.post(url, data, { withCredentials: true });
    }
    static GetCart(planKey) {
      //[TODO] pass the productId if someone need to choose differen subscription duration
      let uri = Settings.MyCommerceCart + "?planKey=" + planKey
      return axios.get(uri, { withCredentials: true });      
    }
    static GetCarts(planKeys) {
      //[TODO] pass the productId if someone need to choose differen subscription duration
      let uri = Settings.MyCommerceCarts + "?planKeys=" + planKeys
      return axios.get(uri, { withCredentials: true });      
    }
    static GetMyCommerceManagePaymentLink(subscId) {
      return axios.get(Settings.MyCommerceManagePaymentUrl + "?subscription=" + subscId, { withCredentials: true })
    }
    static CancelMyCommerceSubscription(subscId) {
      return axios.post(Settings.MyCommerceCancelSubscriptionUrl + "?subscription=" + subscId, {}, { withCredentials: true })
    }
    static GetWhoAmI(lang="") {
      PremiumUtil.WhoAmIObj = {};
      PremiumUtil.WhatAmI = {};
      PremiumUtil.blurIdsList = [];
      PremiumUtil.browserCount = 0;

      let promise = new Promise( (resolve, reject) => {
        let whoAmIUrl = Settings.WhoAmIUrl;
        if(lang) {
          whoAmIUrl += "?lang=" + lang
        }
        axios.get(whoAmIUrl, { withCredentials: true }).then(resp => {
         resolve(resp.data);
         PremiumUtil.WhoAmIObj = resp.data;
        }).catch(err => {
          reject(err);
        });
      });
      return promise;
    }
    static GetConfig() {
      PremiumUtil.ConfigObj = {};
      let promise = new Promise( (resolve, reject) => {
        axios.get(Settings.ConfigURL, { withCredentials: true }).then(resp => {
         resolve(resp.data);
         PremiumUtil.ConfigObj = resp.data;
        }).catch(err => {
          reject(err);
        });
      });
      return promise;
    }
		static CallWhatAmI() {
      let promise = new Promise( (resolve, reject) => {
        axios.get(Settings.WhatAmIUrl, { withCredentials: true }).then(resp => {
         resolve(resp.data);
         PremiumUtil.WhatAmI = resp.data;
        }).catch(err => {
          reject(err);
        });
      });
      return promise;
    }
    static CanShowInstall() {
      if(PremiumUtil.WhatAmI && PremiumUtil.WhatAmI.browser) {
        var browser = PremiumUtil.WhatAmI.browser;
        if(browser.startsWith("Chrome") || browser.startsWith("EdgeChromium") || 
           browser.startsWith("Yandex") || browser.startsWith("QQ Browser") ||
           (browser.startsWith("Firefox") && navigator.userAgent.match(/(?=.*Firefox\/([6-9]\d|[1-9]\d{2,}))/gi))) {
          return true
        }
      }
      return false;
    }
    static InstallClick(fromPage, lang) {
      ReactGAInstance.Event(fromPage, "InstallClick", "User Clicked Install", 1);
      var downloadUrl = "https://chrome.google.com/webstore/detail/browselive-web-safety/ghjakbehlhkjdiehaeffedomkakcenjo?hl=" + lang;
      var target= "_blank";				
      if(navigator.userAgent.match(/(?=.*Firefox\/([6-9]\d|[1-9]\d{2,}))/gi)) {
        downloadUrl = "https://addons.mozilla.org/firefox/downloads/latest/blur-live/addon-3903012-latest.xpi";
        target = "_self";
      } else {
        if (!lang || lang === "") {
          lang = navigator.language || navigator.userLanguage
        }
        if(downloadUrl.indexOf("?hl=en-") > -1) {
          downloadUrl = "https://chrome.google.com/webstore/detail/browselive-web-safety/ghjakbehlhkjdiehaeffedomkakcenjo?hl=en";
        }
      
        if (navigator.userAgent.match(/(?=.*QQBrowser\/)/gi)) {
          downloadUrl = "https://appcenter.browser.qq.com/search/detail?key=blur.live&id=ghjakbehlhkjdiehaeffedomkakcenjo&title=Blur.live";
        } else if(navigator.userAgent.match(/(?=.*Edg\/([7-9]\d|[1-9]\d{2,}))/gi)) {
          downloadUrl = "https://microsoftedge.microsoft.com/addons/detail/browselive-web-safety/eelchcnolfmggobnmfpmhnicpffodefe?hl=" + lang;
        }
        
        if(window.location.href) {
          var query_string_paramter = window.location.href.split("?")[1];   
          if(query_string_paramter) {
            downloadUrl += "&" + query_string_paramter;
          }
        }
      }
      window.open(downloadUrl, target);
    }
    static UpgradeClick(fromPage, lang) {
      ReactGAInstance.Event(fromPage, "UpgradeClick", "User Clicked Upgrade", 1);
      var downloadUrl = "/buy-premium";
      if (lang && lang !=="" && lang != 'en') {
        downloadUrl += "/" + lang;
      }
      if(window.location.href) {
        var query_string_paramter = window.location.href.split("?")[1];   
        if(query_string_paramter) {
          downloadUrl += "?" + query_string_paramter;
        }
      }
      window.open(downloadUrl, "_blank");
    }
    static GetBlurStatus() {
      let promise = new Promise( (resolve, reject) => {
        try {
          var extInfo = document.getElementById("bluelive-ext-installed");
          if(extInfo && extInfo.innerHTML !=="") {
            resolve(extInfo.innerHTML);
          } else {
            resolve("");
          }
        } catch(err) {
          reject("");
        }
      });
      return promise;
    }
    static GetBlurTrialMinsLeft() {
      let promise = new Promise( (resolve, reject) => {
        try {
          var minsLeft = document.getElementById("bluelive-ext-trial-mins-left");
          if(minsLeft && minsLeft.innerHTML !=="" && parseInt(minsLeft.innerHTML) !== NaN) {
            resolve(parseInt(minsLeft.innerHTML));
          } else {
            resolve("");
          }
        } catch(err) {
          reject("");
        }
      });
      return promise;
    }
    static GetBlurFreePremiumMinsLeft() {
      let promise = new Promise( (resolve, reject) => {
        try {
          var minsLeft = document.getElementById("bluelive-ext-premium-mins-left");
          if(minsLeft && minsLeft.innerHTML !=="" && parseInt(minsLeft.innerHTML) !== NaN) {
            resolve(parseInt(minsLeft.innerHTML));
          } else {
            resolve("");
          }
        } catch(err) {
          reject("");
        }
      });
      return promise;
    }
    static readExtensionInfo() {
      var info = {hasExtension: false, isPremium: false};
      var extInfo = document.getElementById("bluelive-ext-installed");
      if(!extInfo) {
        return info;
      }
      if(extInfo && (extInfo.innerHTML === "Yes"
      || extInfo.innerHTML === "trial"
      || extInfo.innerHTML === "trialEnd"
      || extInfo.innerHTML === "freemium"
      || extInfo.innerHTML === "premium"
      || extInfo.innerHTML === "premiumEnd"
      || extInfo.innerHTML === "freeBlurDay")) {
        info.hasExtension = true;
      }
      //return from here
      if(!info.hasExtension) {
        return info;
      }
      if(extInfo.innerHTML === "premium") {
        info.isPremium = true;
      }
      info.blurStatus = extInfo.innerHTML;

      //read triam mins left
      var minsLeft = document.getElementById("bluelive-ext-trial-mins-left");
      if(minsLeft && minsLeft.innerHTML !=="" && parseInt(minsLeft.innerHTML) !== NaN) {
        info.trialMinsLeft = parseInt(minsLeft.innerHTML);
      }
      //read premium mins left
      minsLeft = document.getElementById("bluelive-ext-premium-mins-left");
      if(minsLeft && minsLeft.innerHTML !=="" && parseInt(minsLeft.innerHTML) !== NaN) {
        info.freePremiumMinsLeft = parseInt(minsLeft.innerHTML);
      }

      var moreInfo = document.getElementById("bluelive-ext-blur-intensity");
      if(moreInfo && moreInfo.innerHTML !=="") {
        info.blurIntensity = moreInfo.innerHTML;
      }

      moreInfo = document.getElementById("bluelive-ext-search-blurcount");
      if(moreInfo && moreInfo.innerHTML !=="") {
        info.blurCount = moreInfo.innerHTML;
      }

      moreInfo = document.getElementById("bluelive-ext-ads-blurcount");
      if(moreInfo && moreInfo.innerHTML !=="") {
        info.adBlurCount = moreInfo.innerHTML;
      }

      var affInfo = document.getElementById("bluelive-ext-aff-name");
      if(affInfo && affInfo.innerHTML !=="") {
        info.affName = affInfo.innerHTML;
      }

      affInfo = document.getElementById("bluelive-ext-aff-url");
      if(affInfo && affInfo.innerHTML !=="") {
        info.affUrl = affInfo.innerHTML;
      }

      affInfo = document.getElementById("bluelive-ext-aff-logo");
      if(affInfo && affInfo.innerHTML !=="") {
        info.affLogo = affInfo.innerHTML;
      }

      var trialExt = document.getElementById("bluelive-ext-trial-extended");
      if(trialExt && !isNaN(trialExt.innerHTML)) {
        info.trialExtendedBy = parseInt(trialExt.innerHTML);
      }

      var extNeedsUpdate = document.getElementById("bluelive-ext-needs-update");
      if(extNeedsUpdate && extNeedsUpdate.innerHTML !=="") {
        info.extNeedsUpdate = extNeedsUpdate.innerHTML === "true";
      }

      var extVersion = document.getElementById("bluelive-ext-version");
      if(extVersion && extVersion.innerHTML !=="") {
        info.extVersion = extVersion.innerHTML;
      }
      return info;
    }
    static CheckBlurInstalled() {
      let promise = new Promise( (resolve, reject) => {
        try {
          var extInfo = document.getElementById("bluelive-ext-installed");
          if(extInfo && (extInfo.innerHTML === "Yes"
                       || extInfo.innerHTML === "trial"
                       || extInfo.innerHTML === "trialEnd"
                       || extInfo.innerHTML === "freemium"
                       || extInfo.innerHTML === "premium"
                       || extInfo.innerHTML === "premiumEnd"
                       || extInfo.innerHTML === "freeBlurDay")) {
            resolve(true);
          } else {
            resolve(false);
          }
        } catch(err) {
          reject(false);
        }
      });
      return promise;
    }
    static CheckBlurPremium() {
      let promise = new Promise( (resolve, reject) => {
        try {
          var extInfo = document.getElementById("bluelive-ext-installed");
          if(extInfo && (extInfo.innerHTML === "premium")) {
            resolve(true);
          } else {
            resolve(false);
          }
        } catch(err) {
          reject(false);
        }
      });
      return promise;
    }
    static GetSearchBlurcount() {
      let promise = new Promise( (resolve, reject) => {
        try {
          var extInfo = document.getElementById("bluelive-ext-search-blurcount");
          if(extInfo && extInfo.innerHTML !=="") {
            resolve(extInfo.innerHTML);
          } else {
            resolve("");
          }
        } catch(err) {
          reject("");
        }
      });
      return promise;
    }
    static GetAdsBlurcount() {
      let promise = new Promise( (resolve, reject) => {
        try {
          var extInfo = document.getElementById("bluelive-ext-ads-blurcount");
          if(extInfo && extInfo.innerHTML !=="") {
            resolve(extInfo.innerHTML);
          } else {
            resolve("");
          }
        } catch(err) {
          reject("");
        }
      });
      return promise;
    }
    static GetBlurIntensity() {
      let promise = new Promise( (resolve, reject) => {
        try {
          var extInfo = document.getElementById("bluelive-ext-blur-intensity");
          if(extInfo && extInfo.innerHTML !=="") {
            resolve(extInfo.innerHTML);
          } else {
            resolve("");
          }
        } catch(err) {
          reject("");
        }
      });
      return promise;
    }

    //Update checking is Firefox only for now (see Client for any changes to this)
    static CheckExtNeedsUpdate() {
        let promise = new Promise( (resolve, reject) => {
          try {
            var extNeedsUpdate = document.getElementById("bluelive-ext-needs-update");
            if(extNeedsUpdate && extNeedsUpdate.innerHTML !=="") {
              resolve(extNeedsUpdate.innerHTML === "true");
            } else {
              resolve("");
            }
          } catch(err) {
            reject("");
          }
        });
        return promise;
    }
    static GetExtensionVersion() {
        let promise = new Promise( (resolve, reject) => {
          try {
            var extVersion = document.getElementById("bluelive-ext-version");
            if(extVersion && extVersion.innerHTML !=="") {
              resolve(extVersion.innerHTML);
            } else {
              resolve("");
            }
          } catch(err) {
            reject("");
          }
        });
        return promise;
    }
    
    static LoadBlurIdsDetail(subscriptionsOwnedId) {
      let promise = new Promise( (resolve, reject) => {
        axios.get(Settings.SubscriptionBlurIdsUrl, { params: {subscription: subscriptionsOwnedId}, withCredentials: true }).then((response) => {
          let blurIdArray = response.data;
          const formattedIdArray = [];
          let ix = 1;
          for (let i=0; i<blurIdArray.length; i++) {
            let name;
            if (blurIdArray[i].descriptor) {
              name = blurIdArray[i].descriptor;
            } else {
              name = blurIdArray[i].browser + ' ' + (ix);
              ix++;
            }
            formattedIdArray.push({key: i, value: blurIdArray[i].Id, text: name});
          }
          PremiumUtil.blurIdsList = formattedIdArray;
          PremiumUtil.browserCount = blurIdArray.length;
          resolve(response.data);
        }).catch(err => {
          reject(err);
        });
      });
      return promise;
    }
    static SendCode(email) {
      return axios.get(Settings.SendAuthCodeUrl, { params: {email}, withCredentials: true });
    }
    static VerifyCode(email, code) {
      return axios.get(Settings.VerifyAuthCodeUrl, { params: {email: email, authcode: code.toUpperCase()}, withCredentials: true } )
    }
    static Invite(subscription, emails) {
      var data = {subscription: subscription, emails: emails};
      return axios.post(Settings.InviteUrl, data, {withCredentials: true, headers: { "Content-Type": "application/json; charset=utf-8"}})
    }
    static EnrollUser(subscriptionToEnroll, enrollDescriptor) {
      return axios.get(Settings.EnrollUrl, { params: {subscription: subscriptionToEnroll, descriptor: enrollDescriptor}, withCredentials: true });
    }
    static UnEnrollMe(subscriptionId) {
      return axios.get(Settings.UnenrollMeUrl, { params: {subscription: subscriptionId}, withCredentials: true });
    }
    static UnEnrollThem(blurIdToUnenroll, subscriptionsOwnedId) {
      return axios.get(Settings.UnenrollThemUrl, { params: {blurId: blurIdToUnenroll, subscription: subscriptionsOwnedId}, withCredentials: true });
    }
    static PingExtension() {
      return axios.get(Settings.PingExtensionUrl);
    }

        

    static IsSubscribed() {
      if(PremiumUtil.WhoAmIObj.subscriptionObject) {
        return true;
      }
      return false;
    }
    /*
      user needs to be authenticated
      we only want to show this if 
      or user is owner of subscription and 
        - has more than one subscription (numLicenses) or
        - multiple browsers (blurIds) enrolled
    */
    static IfShowManageEnrollment() {
      if(!PremiumUtil.IsAuthenticated()) {
        return false;
      }
      let obj = PremiumUtil.WhoAmIObj;
      if(obj.subscriptionsOwnedByThisEmail && obj.subscriptionsOwnedByThisEmail.length > 0)  {
        for(var ix in obj.subscriptionsOwnedByThisEmail) {
          var subscObj = obj.subscriptionsOwnedByThisEmail[ix];
          if(subscObj.numLicenses > 1) {
            return true
          } 
          if(subscObj.BlurIds && subscObj.BlurIds.length > 1)
          {
            return true
          }
        }
      }
      return false;
    }
    static GetBlurId() {
      if(PremiumUtil.WhoAmIObj.blurIdObject) {
        return PremiumUtil.WhoAmIObj.blurIdObject.Id;
      }
      return "";
    }
    static GetSubscriptionId() {
      if(PremiumUtil.WhoAmIObj.subscriptionObject) {
        return PremiumUtil.WhoAmIObj.subscriptionObject.id;
      }
      return "";
    }
    static GetOwnedSubscriptions() {
      if(PremiumUtil.WhoAmIObj.subscriptionsOwnedByThisEmail) {
        return PremiumUtil.WhoAmIObj.subscriptionsOwnedByThisEmail;
      }
      return [];
    }
    static GetOwnedSubscription(subscId) {
      let objs = PremiumUtil.GetOwnedSubscriptions();
      for(var ix in objs) {
        if(objs[ix].id === subscId) {
          return objs[ix];
        }
      }
      return null;
    }
    static GetRemainingLicense() {

    }
    static GetSubscriptionObj() {
      return PremiumUtil.WhoAmIObj.subscriptionObject;
    }
    static GetAffiliateObj() {
      return PremiumUtil.WhoAmIObj.affiliateObject;
    }
    static IsAuthenticated() {
      if(PremiumUtil.WhoAmIObj.isAuthenticated) {
        return true;
      }
      return false;
    }
    static GetAuthenticatedEmail() {
      return PremiumUtil.WhoAmIObj.authenticatedEmail;
    }
    static GetSubscribedByEmail() {
      let obj = PremiumUtil.WhoAmIObj;
      if(obj.subscriptionObject && obj.subscriptionObject.authenticatedAccountEmail) {
        return obj.subscriptionObject.authenticatedAccountEmail;
      }
      return "";
    }
    static GetBrowserName() {
      let obj = PremiumUtil.WhoAmIObj;
      if(obj.blurIdObject && obj.blurIdObject.descriptor) {
        return obj.blurIdObject.descriptor;
      }
      return "";
    }
    static GetBrowserCount() {
      let obj = PremiumUtil.WhoAmIObj;
      if(obj.subscriptionObject && obj.subscriptionObject.BlurIds) {
        return Object.keys(obj.subscriptionObject.BlurIds).length;
      }
      return 0;
    }
    static UserHasSubscription(includeInActive=false) {
      let obj = PremiumUtil.WhoAmIObj;
      if((obj.subscriptionsOwnedByThisEmail && obj.subscriptionsOwnedByThisEmail.length > 0 && obj.subscriptionsOwnedByThisEmail.filter(s=> (s.isActive || includeInActive)).length > 0) || 
         (obj.subscriptionsAvailableForThisEmail && obj.subscriptionsAvailableForThisEmail.length > 0  && obj.subscriptionsAvailableForThisEmail.filter(s=> (s.isActive || includeInActive)).length > 0) ||
         (obj.subscriptionObject != null && !obj.subscriptionObject.isFreePremium && !obj.subscriptionObject.isAffiliateSubscription)) {
        return true;
      }
      return false;
    } 
    static HasSubscriptions(includeInActive=true) {
      let obj = PremiumUtil.WhoAmIObj;
      if((obj.subscriptionsOwnedByThisEmail && obj.subscriptionsOwnedByThisEmail.length > 0 && obj.subscriptionsOwnedByThisEmail.filter(s=> (s.isActive || includeInActive)).length > 0) || 
         (obj.subscriptionsAvailableForThisEmail && obj.subscriptionsAvailableForThisEmail.length > 0  && obj.subscriptionsAvailableForThisEmail.filter(s=> (s.isActive || includeInActive)).length > 0) ||
         (obj.subscriptionsAvailableForThisAffiliatedBlurId && obj.subscriptionsAvailableForThisAffiliatedBlurId.length > 0  && obj.subscriptionsAvailableForThisAffiliatedBlurId.filter(s=> (s.isActive || includeInActive)).length > 0)) {
        return true;
      }
      return false;
    }
    static HasAffiliateSubscriptions() {
      let obj = PremiumUtil.WhoAmIObj;
      if(obj.subscriptionsAvailableForThisAffiliatedBlurId && obj.subscriptionsAvailableForThisAffiliatedBlurId.length > 0) {
        return true;
      }
      return false;
    }
    static HasIndvSubscriptions() {
      let obj = PremiumUtil.WhoAmIObj;
      if((obj.subscriptionsOwnedByThisEmail && obj.subscriptionsOwnedByThisEmail.length > 0) || 
         (obj.subscriptionsAvailableForThisEmail && obj.subscriptionsAvailableForThisEmail.length > 0)) {
        return true;
      }
      return false;
    }
    static getOwnedSubscription(subscId) {
      let obj = PremiumUtil.WhoAmIObj;
      if(obj.subscriptionsOwnedByThisEmail && obj.subscriptionsOwnedByThisEmail.length > 0) {
        for(var ix in obj.subscriptionsOwnedByThisEmail) {
          var subsc = obj.subscriptionsOwnedByThisEmail[ix];
          if(subsc.id === subscId && subsc.isActive) {
            return subsc;
          }
        }
      }
      return null;
    }
    static doOwnSubscription() {
      let obj = PremiumUtil.WhoAmIObj;
      if(obj.subscriptionsOwnedByThisEmail && obj.subscriptionsOwnedByThisEmail.length > 0) {
        return true;
      }
      return false;
    }
    static GetSubscriptions(includeAffiliate, includeInActive=true) {
      let obj = PremiumUtil.WhoAmIObj;
      let subsDict = PremiumUtil._mergeSubscription(obj.subscriptionsOwnedByThisEmail, null, "Owned", includeInActive);
      subsDict = PremiumUtil._mergeSubscription(obj.subscriptionsAvailableForThisEmail, subsDict, "Available", includeInActive);
      if(includeAffiliate) {
        subsDict = PremiumUtil._mergeSubscription(obj.subscriptionsAvailableForThisAffiliatedBlurId, subsDict, "Affiliate", includeInActive);
      }
      return subsDict;
    }
    static _mergeSubscription(subscriptions, subsDict, type, includeInActive=true) {
      if(!subsDict) {
        subsDict = {};
      }
      if(!subscriptions) {
        return subsDict;
      }
      for (let i=0; i<subscriptions.length; i++) {
        let obj = subscriptions[i];
        if(subsDict[obj.id]) {
          continue;
        }
        if(!includeInActive && obj.isActive === false) {
          continue;
        }
        var text = "";
        if(type === "Owned") {
          text = 'Owned ' + (obj.descriptor == null ? obj.authenticatedAccountEmail : obj.descriptor) + '(' + (obj.isActive ? 'active' : 'inactive') + (obj.isPaused ? ', paused' : '') + ')';
        } else if(type === "Available") {
          text = 'Available ' + (obj.descriptor == null ? obj.authenticatedAccountEmail : obj.descriptor) + '(' + (obj.isActive ? 'active' : 'inactive') + (obj.isPaused ? ', paused' : '') + ')';
        } else if(type === "Affiliate") {
          text = (obj.descriptor == null ? 'Affiliate subscription' : obj.descriptor) + ' (' + (obj.isActive ? 'active' : 'inactive') + (obj.isPaused ? ', paused' : '') + ')';
        }
        subsDict[obj.id] = {
          key: obj.id, 
          value: obj.id, 
          text: text
        }
      }
      return subsDict;
    }
    static IfShowCheckout() {
      if(!PremiumUtil.WhoAmIObj.subscriptionObject && !PremiumUtil.HasSubscriptions()) {
        return true;
      }
      return false;
    }
    static IsEmailValid(email) {
      if(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        return true;
      }
      return false;
    }
    static IsCodeValid(code) {
      if(code.match(/[0-9a-zA-Z]{6,}/)) {
        return true;
      }
      return false;
    }
    static GetCustomer(email) {
      let customer = {
        email: (email !== null && email !== "") ? email : PremiumUtil.WhoAmIObj.authenticatedEmail, 
        cf_authenticated_email: (email !== null && email !== "") ? email : PremiumUtil.WhoAmIObj.authenticatedEmail, 
        cf_internal_blur_id: PremiumUtil.WhoAmIObj.blurIdObject.Id
      }; 
      return customer;
    }
    static SignOut() {
      return axios.get(Settings.SignOutUrl, {withCredentials: true });
    }
    static GetBlurIdsDetails(subscriptionsOwnedId) {
      return PremiumUtil.blurIdsList.filter(bl => {return (bl.value != PremiumUtil.GetBlurId())});
    }
    static RenderError(errorMessage) {
      if(!errorMessage) {
        return ("");
      }
      return (
        <div className="ui negative message center aligned grid premium-error">
          <p>
            {errorMessage}
          </p>
        </div>
      )
    }

    //For calling subscription/estimate related call
    static GetEstimate(planId, userCnt, couponId) {
      return axios.get(Settings.EstimateUrl, { params: {planId: planId, userCnt: userCnt, couponId: couponId}, withCredentials: true });
    }
    static Subscribe(subscReq) {
      return axios.post(Settings.SubscribeUrl, subscReq, {withCredentials: true, headers: { "Content-Type": "application/json; charset=utf-8"}})
    }

    static DetectLanguage() {
      let navigatorLang = window.navigator.language;
      let parsedUrl = new URL(window.location.href);
			let language ="";
			if(parsedUrl.pathname) {
				let urlLang = parsedUrl.pathname.substring(parsedUrl.pathname.lastIndexOf('/') + 1);
				if (PremiumUtil.IsInLanguageList(urlLang)) {
					language = urlLang;
				} else {
					language = navigatorLang;
				}
			}
			const dashIndex = language.indexOf("-");
			const isoCode = (dashIndex === -1) ? language : language.slice(0, dashIndex);
			return (isoCode === "zh") ? language : isoCode;
    }
    static IsInLanguageList(value) {
      const languages = [
        {key: "da", value: "da", text: "Dansk"},
        {key: "de", value: "de", text: "Deutsch"},
        {key: "en", value: "en", text: "English"},
        {key: "es", value: "es", text: "Española"},
        {key: "fil", value: "fil", text: "Filipino"},
        {key: "fr", value: "fr", text: "Français"},
        {key: "hi", value: "hi", text: "हिन्दी"},
        {key: "it", value: "it", text: "Italiano"},
        {key: "nl", value: "nl", text: "Nederlands"},
        {key: "no", value: "no", text: "Norsk"},
        {key: "pt", value: "pt", text: "Português"},
        {key: "ru", value: "ru", text: "Русский"},
        {key: "fi", value: "fi", text: "Suomi"},
        {key: "sv", value: "sv", text: "Svenska"},
        {key: "zh-cn", value: "zh-cn", text: "中文 (简体)"}
      ];
      for (let i = 0; i < languages.length; i++) {
          if (languages[i].value === value) {
              return true
          }
      }
      return false;
    }
    static GetBrowserLanguage() {
      let lang = "";
      try {
        //pick language from the URL if its present
        let parsedUrl = new URL(window.location.href);
        if(parsedUrl.pathname) {
                if(parsedUrl.pathname.indexOf("/premium/") > -1) {
                    lang = parsedUrl.pathname.replace("/premium/", "");
                    //check if its part of more path
                    if(lang && lang.indexOf("/") > -1) {
                        lang = lang.split("/")[0];
                    }
                } else if(parsedUrl.pathname.indexOf("/buy-premium/") > -1) {
                    lang = parsedUrl.pathname.replace("/buy-premium/", "");
                    //check if its part of more path
                    if(lang && lang.indexOf("/") > -1) {
                        lang = lang.split("/")[0];
                    }
                }
            } 
        if(!lang) {
          lang = navigator.language || navigator.userLanguage;
        }
      }
      catch(err) {
        //some error happened we are not showing here
        //console.log("Error parsing url", err);
      }
      return lang;
    }

    // Translations
    static SetBlurMessages(messageIds='', delimiter=',', lang='') {
      if (!lang) {
        lang = PremiumUtil.GetBrowserLanguage();
      }
      return BlurMessages.init(lang, messageIds, delimiter);
    }
    static Translate(messageId, message) {
      var transMessg = BlurMessages.getMessage(messageId);
      if(transMessg === messageId) {
          return message;
      }
      transMessg = Parser(transMessg);
      return transMessg;
    }
    static TranslateWithParam(messageId, message, param) {
      var transMessg = BlurMessages.getMessage(messageId, param);
      if(transMessg === messageId) {
          return message;
      }
      transMessg = Parser(transMessg);
      return transMessg;
    }
    static TranslateWithParamWithoutParse(messageId, message, param) {
      var transMessg = BlurMessages.getMessage(messageId, param);
      if(transMessg === messageId) {
          return message;
      }
      return transMessg;
    }
    static selectCurrentBgImage() {
        let date = new Date(Date.now());
        let day = date.getDay();
        let hour = date.getHours();

        if ((hour >= 5 && hour <= 12) || !enableTimeOfDay) {
            hour = 1;
        } else if (hour > 12 && hour <= 20) {
            hour = 2;
        } else {
            hour = 3;
        }

        return "bg-" + (day + 1) + "-" + hour;
    }
    static preloadBgImage(path) {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.addEventListener('load', () => {resolve()});
            img.addEventListener('error', () => {reject()});
            img.src = path;
        });
    }
}