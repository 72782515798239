import React, { Component } from 'react';
import { SafetyUtil } from '../utils/SafetyUtil';
import debounce from 'lodash.debounce';

/* Props:
 * defaultItems:    Arr[], the default array of items to display when there's no query
 * itemsPerPage:    (optional) number, how many items to include on each page of autocomplete results (default 5)
 * getItemValue:    (item) => itemValue, function that selects which value in each item should be used for comparison
 * renderItem:      (item, key) => JSX, function that returns JSX describing how each item should be rendered, key required in top element of JSX
 * searchType:      string => type of data to request from autocomplete search query API ('domains' or 'apps')
 * apiDisabled      (optional) boolean, disable API results and search defaultItems instead
 */

const defaultPerPage = 5;
const debounceDelay = 250;

class SidebarAutoComplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acFindValue: '',
            acResults: [],
            acTotalItems: 0,
            acPage: 1,
            itemsPerPage: props.itemsPerPage || defaultPerPage,
            apiDisabled: props.apiDisabled || false,
        };

        this.setItemsApi = debounce(this.setItemsApi.bind(this), debounceDelay);
    }

    componentDidMount() {
        if (!this.props.defaultItems || !this.props.getItemValue || !this.props.renderItem || !this.props.searchType) {
            return;
        }

        this.setItemsDefault();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.acFindValue != prevState.acFindValue || 
            this.state.acPage != prevState.acPage || 
            this.props.defaultItems != prevProps.defaultItems) {

            if (this.state.acFindValue && this.state.acFindValue.trim().length > 0 && !this.state.apiDisabled) {
                this.setItemsApi();
            } else {
                this.setItemsDefault();
            }
        }
    }

    /* Set array of items as the current autocomplete search results 
     * totalNumResults: how many possible results exist for current query
     */
    setItems(items, totalNumResults) {
        this.setState(c => {
            let numPages = Math.ceil(totalNumResults / this.state.itemsPerPage);    
            return {
                acResults: items, 
                acTotalItems: totalNumResults,
                acPage: (c.acPage >= numPages && numPages > 0) ? numPages : c.acPage,
            }
        });
    }

    setItemsApi() {
        let skip = (this.state.acPage - 1) * this.state.itemsPerPage;

        SafetyUtil.SearchFragData(this.props.searchType, this.state.acFindValue, this.state.itemsPerPage, skip).then(resp => {
            if (resp && resp.data) {
                
                if (!resp.data.message) {

                    // make sure that if multiple query results are received, only latest one is displayed
                    if (this.state.acFindValue != resp.data.frag) {
                        return;
                    }

                    this.setItems(resp.data.list, resp.data.avail);
                } else {
                    this.setItemsDefault();
                }
                
            } else {
                this.setItems([], 0);   // clear autocomplete if no response
            }
        }).catch(err => {

            // poll every 500 ms if can't reach API    
            setTimeout(() => {
                if (this.state.acFindValue && this.state.acFindValue.trim().length > 0) {
                    this.setItemsApi();
                }
            }, 500);
        });
    }

    setItemsDefault() {
        let inclusiveMinIndex = (this.state.acPage - 1) * this.state.itemsPerPage;
        let exclusiveMaxIndex = (this.state.acPage - 1) * this.state.itemsPerPage + this.state.itemsPerPage
        let items = this.props.defaultItems;
        let itemsLength = this.props.defaultItems.length;

        if (this.state.apiDisabled) {
            items = items.filter(item => 
                this.props.getItemValue(item).toLowerCase().indexOf(this.state.acFindValue.toLowerCase()) > -1
            );
            itemsLength = items.length;
        }

        items = items.slice(inclusiveMinIndex, exclusiveMaxIndex);    
        this.setItems(items, itemsLength);
    }

    renderCurrentItems() {
        return this.state.acResults.map(this.props.renderItem)
    }

    renderPaginator() {
        let paginate = e => {
            this.setState(c => {
                return {
                    acPage: e.target.className.includes("cs-arrow-left") ? c.acPage - 1 : c.acPage + 1
                }
            });
        }

        let numPages = Math.ceil(this.state.acTotalItems / this.state.itemsPerPage);
        
        if (this.state.acTotalItems > this.state.itemsPerPage) {
            return (
                <div className="card-search-paginator">
                    <div className="cs-paginator-item">
                        <button className="cs-skip-arrow-left" 
                                style={(this.state.acPage <= 1) ? {opacity: '0.3', transform: 'none'} : {}}
                                onClick={() => this.setState({acPage: 1})}>
                        </button>
                    </div>
                    <div className="cs-paginator-item shift-margin" style={{marginLeft: 'auto'}}>
                        <button className="cs-arrow-left" disabled={this.state.acPage <= 1} onClick={paginate}></button>
                    </div>
                    <div className="cs-paginator-item shift-margin" style={{padding: '0px 10px 0px 10px'}}>{this.state.acPage} / {numPages}</div>
                    <div className="cs-paginator-item shift-margin" style={{marginRight: 'auto'}}>
                        <button className="cs-arrow-right" disabled={this.state.acPage >= numPages} onClick={paginate}></button>
                    </div>
                    <div className="cs-paginator-item">
                        <button className="cs-skip-arrow-right" 
                                style={(this.state.acPage >= numPages) ? {opacity: '0.3', transform: 'none'} : {}}
                                onClick={() => this.setState({acPage: numPages})}>
                        </button>
                    </div>
                </div>
            )
        } else {
            return "";
        }
    }

    render() {
        if (!this.props.defaultItems || !this.props.getItemValue || !this.props.renderItem || !this.props.searchType) {
            return "Required props missing";
        }

        return (
            <div>
                <div className="ui input fluid">
                    <input className="home-card-input" 
                           type="text" 
                           placeholder={this.props.findStr ? this.props.findStr : "Find..."}
                           onChange={e => this.setState({acFindValue: e.target.value, acPage: 1})}
                    />
                </div>
                <p className="card-search-results-text">{this.state.acTotalItems} results</p>
                <div className="card-apps-list">
                    {this.renderCurrentItems()}
                    {this.renderPaginator()}
                </div>
            </div>
        )
    }
}

export default SidebarAutoComplete