import React, { Component } from 'react';

class AppsImage extends Component { //this component includes the image + the on-click modal that allows it to expand
    constructor(props){
        super();
        this.size = 150;
        this.vImages = [];
    }

    render(){
        if(this.props.myType === "image"){
            return (
                <div>
                    <img  
                        className={this.props.className}
                        src={this.props.src}
                        alt={this.props.alt}
                        style={{
                            padding: '20px 0px',
                            maxHeight: '200px'
                        }}
                        onClick = {this.props.onClick}
                        >
                        </img>
                </div>
                
            );
        }
        else if(this.props.myType === "video"){
            return(
            <div>
                <video
                    controls
                    className={this.props.className}
                    style={{
                        padding: '20px 0px',
                        maxHeight: '200px'
                    }}
                    onClick={this.props.onClick}
                    >
                    <source src={this.props.src} type="video/mp4"/>
                </video>
            </div>
            );
        }
        else{
            return(<div></div>);
        }
    
    }
}

export default AppsImage