import React, { Component } from 'react';
import { Header, Button, Container, Grid } from 'semantic-ui-react';
import PremiumSignupPopup from './premium/PremiumSignupPopup';
import {ReactGAInstance} from './connectors/ReactGAInstance.js';
import Axios from 'axios';
import { Settings } from './Settings';

class SignupSuccess extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isSignedIn: false,
        userProfile: {},
        isDevMode: false,
        params: [],
        registerSuccess: true,
        email: "",
        registeredTime: "",
        whoamiIsFinished: false,
      };
      this.pingtimer = null;
      this.pingInterval = 5000;
    }

    componentDidMount () {
      this.queryServer();
    }

    componentWillUnmount () {
      if(this.pingtimer) {
        clearTimeout(this.pingtimer);
      }
    }

    queryServer() {
      //only do this if its successful
      if(this.props.match.params.subscription) {
        Axios.get(Settings.WhoAmIWithSyncUrl, { withCredentials: true }).then((resp) => {
          if(resp.data.subscriptionObject && resp.data.subscriptionObject.isActive) {
            ReactGAInstance.PremiumSubscEvent(this.props.match.params.subscription);
            Axios.get(Settings.PingExtensionUrl, { withCredentials: true });
          }
          else {
            setTimeout(this.queryServer.bind(this), this.pingInterval)
          }
        });
      }
    }
    // Test URL: http://localhost:3000/signupsuccess/blur/invoice/customer/email/firstname/lastname
    render() {
      return (
        <Container className="support-skinny">
          {this.props.match.params.subscription === null &&
            <Header>Payment could not be completed. Please try again.</Header>
          }
          {this.state.registerSuccess === true &&
            <Grid.Row>
              <br/>
              <Header as='h1'> Congratulations! Thank you {this.props.match.params.fname} for getting blur premium :)</Header>
              <br/><br/>
              <div>
                <PremiumSignupPopup trigger={<Button id='signupPopup' size='large' color='blue'>Manage Subscription</Button>}></PremiumSignupPopup>
              </div>
            </Grid.Row>
          }
        </Container>
      );
    }
}

export default SignupSuccess