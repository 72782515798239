import React, { Component } from 'react';
import { Container, Button, Grid, Header } from 'semantic-ui-react';
import PremiumSignupPopup from './premium/PremiumSignupPopup';
import { PremiumUtil } from './utils/PremiumUtil';
import SocialShare from './components/SocialShare.js';
import { Settings } from './Settings';
import axios from 'axios';
import Parser from 'html-react-parser';
import { CommonUtil } from './utils/CommonUtil';

class Welcome extends Component {
    constructor(props) {
      super(props);
      const heroTitle = `Installation successful!`;
      const heroText1 = `If you already purchased a subscription`;
      const heroText2 = `click here`;
      const heroText3 = `to activate in this browser.`;
      const bodyTitle = `Start using this browser extension`;
      const bodyStep0 = `<div class="welcome-video-step-description">
                          <div><b>Enable Blur.live extension</b></div>
                          <div class="welcome-video-step-description-text">
                          Go to Extensions settings and select the toggle next to the Blur.live extension.
                          </div>
                        </div>`;
      const bodyStep1 = `<div class="welcome-video-step-description">
                          <div><b>Open new tab</b></div>
                          <div class="welcome-video-step-description-text">
                          Click on the “+” icon next to the browser tab.
                          </div>
                        </div>`;
      const bodyStep2 = `<div class="welcome-video-step-description">
                          <div><b>Confirm to Keep Changes</b></div>
                          <div class="welcome-video-step-description-text">
                          Click on the Keep Changes button on the pop-up.
                          </div>
                        </div>`;
      const bodyStep3 = `<div class="welcome-video-step-description">
                          <div><b>See this browser extension at work</b></div>
                          <div class="welcome-video-step-description-text">
                            Start browsing and see ad and search results blurring.
                          </div>
                        </div>`;
      const bodySareText = `Share this browser extension with others`;
      this.state = {
        translatedHeroTitle: Parser(heroTitle),
        translatedHeroText1: Parser(heroText1),
        translatedHeroText2: Parser(heroText2),
        translatedHeroText3: Parser(heroText3),
        translatedBodyTitle: Parser(bodyTitle),
        translatedBodyStep0: Parser(bodyStep0),
        translatedBodyStep1: Parser(bodyStep1),
        translatedBodyStep2: Parser(bodyStep2),
        translatedBodyStep3: Parser(bodyStep3),
        translatedBodySareText: Parser(bodySareText),
        heroTitle,
        heroText1,
        heroText2,
        heroText3,
        bodyTitle,
        bodyStep0,
        bodyStep1,
        bodyStep2,
        bodyStep3,
        bodySareText,
        message: "",
        errorTranslation: true
      }
      this.translateLang = CommonUtil.lang;
    }
    
    componentDidMount() {
      window.scrollTo(0, 0);
      PremiumUtil.SendAnalytics("Install", "Installed", "Blur.live Welcome Page");
      this.getTranslation();
      this.setVideoUrl();
    }
    componentDidUpdate(prevProps) {
      if (this.translateLang !== CommonUtil.lang) {
          this.translateLang = CommonUtil.lang;
          this.getTranslation();
          this.setVideoUrl();
      }
    }
    parseEnTranslation() {
      this.setState( c => {
          c.translatedHeroTitle = Parser(this.state.heroTitle);
          c.translatedHeroText1 = Parser(this.state.heroText1);
          c.translatedHeroText2 = Parser(this.state.heroText2);
          c.translatedHeroText3 = Parser(this.state.heroText3);
          c.translatedBodyTitle = Parser(this.state.bodyTitle);
          c.translatedBodyStep0 = Parser(this.state.bodyStep0);
          c.translatedBodyStep1 = Parser(this.state.bodyStep1);
          c.translatedBodyStep2 = Parser(this.state.bodyStep2);
          c.translatedBodyStep3 = Parser(this.state.bodyStep3);
          c.translatedBodySareText = Parser(this.state.bodySareText);
        return c;
      });
    }
    getTranslation() {
      if (CommonUtil.IsEnglishLang()) {
        this.parseEnTranslation();
        return;
      }
      const titleListString = "welcome/heroTitle@welcome/heroText1@welcome/heroText2@welcome/heroText3@welcome/bodyTitle@welcome/bodyStep0@welcome/bodyStep1@welcome/bodyStep2@welcome/bodyStep3@welcome/bodySareText";
      const titleDict = {"welcome/heroTitle": this.state.heroTitle,
                          "welcome/heroText1": this.state.heroText1,
                          "welcome/heroText2": this.state.heroText2,
                          "welcome/heroText3": this.state.heroText3,
                          "welcome/bodyTitle": this.state.bodyTitle,
                          "welcome/bodyStep0": this.state.bodyStep0,
                          "welcome/bodyStep1": this.state.bodyStep1,
                          "welcome/bodyStep2": this.state.bodyStep2,
                          "welcome/bodyStep3": this.state.bodyStep3,
                          "welcome/bodySareText": this.state.bodySareText};
      const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
      let hasCachedText;
      axios.get(checkTextURL) // check if we need to send text over
      .then((response) => {
        hasCachedText = response.data;
      }, (error) => {
        hasCachedText = false;
      })
      .finally(() => {
        const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=welcome`
                              : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=welcome`;
        const data = (hasCachedText) ? null : titleDict;
        axios.post(translateURL, data)
        .then((response) => {
          this.setState( c => {
            c.translatedHeroTitle = Parser(response.data["welcome/heroTitle"]);
            c.translatedHeroText1 = Parser(response.data["welcome/heroText1"]);
            c.translatedHeroText2 = Parser(response.data["welcome/heroText2"]);
            c.translatedHeroText3 = Parser(response.data["welcome/heroText3"]);
            c.translatedBodyTitle = Parser(response.data["welcome/bodyTitle"]);
            c.translatedBodyStep0 = Parser(response.data["welcome/bodyStep0"]);
            c.translatedBodyStep1 = Parser(response.data["welcome/bodyStep1"]);
            c.translatedBodyStep2 = Parser(response.data["welcome/bodyStep2"]);
            c.translatedBodyStep3 = Parser(response.data["welcome/bodyStep3"]);
            c.translatedBodySareText = Parser(response.data["welcome/bodySareText"]);
            c.message = "";
            c.errorTranslation = false;
            return c;
          });
        }, (error) => {
          this.setState( c => {
            c.message = "API error, could not translate";
            c.errorTranslation = true;
            return c;
          });
        })
      });
    }
    setVideoUrl() {
      var lang = this.translateLang.toLowerCase();
      let videoUrl = "https://firebasestorage.googleapis.com/v0/b/blur-live-project.appspot.com/o/videos%2F";
      if (CommonUtil.isBrowserFireFox()) {
        videoUrl += "Firefox%2FBlur_success_install_firefox";
      } else
      if (CommonUtil.isBrowserEdge()) {
        videoUrl += "Edge%2FBlur_success_install_edge";
      } else {
        videoUrl += "Chrome%2FBlur_success_install_chrome";
      }
      if (!CommonUtil.IsEnglishLang() && PremiumUtil.IsInLanguageList(lang)) {
        videoUrl += "_" + lang + ".mp4?alt=media";
      } else {
        videoUrl += "_en.mp4?alt=media";
      }
      this.setState( c => {
        c.successInstallVideoUrl = videoUrl;
        return c;
      });
    }
    render() {
      return (
        <div>
          <PremiumSignupPopup open={this.state.openSignup} onClose={e => this.setState(c=> {c.openSignup = false; return c;})}/>
          <div>
            <Header as='h1'>
              {this.state.translatedHeroTitle}
            </Header>
            <div>
              {this.state.translatedHeroText1},&nbsp;
              <a href='#' onClick={e => this.setState(c=> {c.openSignup = true; return c;})}>
                {this.state.translatedHeroText2}
              </a>
              &nbsp;{this.state.translatedHeroText3}
            </div>
          </div>
          <div className='page-section-padding'>
            <Header as='h2' color='blue'>
              {this.state.translatedBodyTitle}
            </Header>
            <div className="page-subsection-padding">
              <div>
              <video className="welcome-video" autoPlay="autoplay" muted playsInline="" webkit-playsinline="" loop={true} poster="" width="100%">
                  <source src={this.state.successInstallVideoUrl} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
              </div>
              {
                CommonUtil.isBrowserEdge() ?
                (
                <div>
                  <div className="welcome-video-steps">
                    <div className="welcome-video-step-number">1</div>
                    {this.state.translatedBodyStep0}
                  </div>
                  <div className="welcome-video-steps">
                    <div className="welcome-video-step-number">2</div>
                    {this.state.translatedBodyStep1}
                  </div>
                  <div className="welcome-video-steps">
                    <div className="welcome-video-step-number">3</div>
                    {this.state.translatedBodyStep2}
                  </div>
                  <div className="welcome-video-steps">
                    <div className="welcome-video-step-number">4</div>
                    {this.state.translatedBodyStep3}
                  </div>
                </div>
                ) :
                (
                <div>
                  <div className="welcome-video-steps">
                    <div className="welcome-video-step-number">1</div>
                    {this.state.translatedBodyStep1}
                  </div>
                  <div className="welcome-video-steps">
                    <div className="welcome-video-step-number">2</div>
                    {this.state.translatedBodyStep2}
                  </div>
                  <div className="welcome-video-steps">
                    <div className="welcome-video-step-number">3</div>
                    {this.state.translatedBodyStep3}
                  </div>
                </div>
                )
              }
            </div>
          </div>
          <div className="page-section-padding">
            <div className="ui message welcome-sharelinks-section" >
              <SocialShare translatedShareText={this.state.translatedBodySareText}/>
            </div>
          </div>
        </div>
      );
    }
}

export default Welcome
