import React, { Component } from 'react';
import axios from 'axios';
import Parser from 'html-react-parser';
import { Settings } from './Settings';
import { CommonUtil } from './utils/CommonUtil';

class TermsOfUse extends Component {
  constructor(props) {
    super(props);
    const header = `<div className="page-header">
      <Header as='h1'>
        AppEsteem Terms of Use
      </Header>
    </div>`;
    const para1 = `<p><i><small>Updated: May 29, 2019</small></i></p>
    <br/>
    <p>
      We are glad to see you using our websites to access the information and
      services we offer. Your access and use of our websites, including use of
      our apps, services and information, is provided under these Terms of Use (“<strong>Terms</strong>”). In these Terms, “<strong>we</strong>” or “<strong>us</strong>” refers to AppEsteem Corporation (“<strong>AppEsteem</strong>”), located at 655 156<sup>th</sup> Ave SE, Suite
      275, Bellevue, WA 98007.
    </p>
    <p>
      We offer many different services, and sometimes additional terms and
      restrictions may apply under various agreements for these services included
      on our websites at <a href="http://www.blur.live" target="_blank" rel="noopener noreferrer" >www.blur.live</a> and <a href="http://www.appesteem.com" target="_blank" rel="noopener noreferrer" >www.appesteem.com</a>. Such additional
      agreements become part of these Terms if you use those services. If you
      have any questions about any of our services, please send an email to    <a href="mailto:info@appesteem.com" target="_blank" rel="noopener noreferrer" >info@appesteem.com</a>.
    </p>
    <p>
      <strong>
        By accessing this website and using our services, you are agreeing to
        these Terms. Please read the Terms carefully, and do not use this
        website or our services if you do not agree to all of the Terms.
      </strong>
      <strong> </strong>
    </p>
    <p>
      <strong>The Public Data</strong>
    </p>
    <p>
      In these Terms, “<strong>Public Data</strong>” refers to information we
      provide on our websites and in our apps about our Certification and
      Deceptor programs, which represent opposite ends of the consumer protection
      spectrum:
    </p>
    <ul>
      <li>
        <strong>The AppEsteem Certification Program</strong> identifies software products and services that have <u>met</u> 100% of the <a href="https://customer.appesteem.com/Home/AppCertReqs" target="_blank" rel="noopener noreferrer" > AppEsteem Certification Requirements</a> and that we believe engage in clean, safe, and appropriate behavior that is fair to consumers.
      </li>
      <li>
        <strong>The AppEsteem Deceptor program</strong> identifies software products and services that have <u>violated</u> at least one of the <a href="https://customer.appesteem.com/Home/Deceptor" target="_blank" rel="noopener noreferrer" >AppEsteem Deceptor Requirements</a>, which we believe include key identifiers of deceptive and risky behavior that could harm consumers.
      </li>
    </ul>
    <p>
      The Public Data may include names of companies that have certified
      products, software products or services we have certified (collectively, “<strong>Certified Products</strong>”), our electronic “    <strong>seal</strong>” data to identify Certified Products, products or
      services we believe have violated one of our Deceptor Requirements, which
      we call “<strong>Deceptors</strong>,” and products or services that are not
      currently identified as Deceptors but that we believe fail to meet our
      Certification Requirements (“<strong>Non-Certified Products</strong>”),
      additional data regarding our analysis of the foregoing, information
      regarding de-certification of Certified Products, and general
      communications regarding our Certification and Deceptor programs. We
      believe that these programs will help protect consumers and encourage
      software vendors to build software products with clean behavior. Therefore,
      we offer the Public Data on our website as a free service, subject to these
      Terms.
    </p>
    <p>
      <strong>The Better World Network® </strong>
    </p>
    <p>
      The Better World Network is an online marketplace on this website that
      lists various “<strong>Better World Members</strong>” that provide products
      and services in the software monetization industry. To join the Better
      World Network, companies must commit to maintaining ethical business
      practices. In addition, Better World Members with specified services, such
      as call centers, must go through a certification process to ensure their
      services are in compliance with our requirements. We hope the Better World
      Network will help encourage and incentivize vendors to certify their
      products with us, which we believe will drive ethical practices and protect
      consumers.
    </p>
    <p>
      <strong>Use of this Website and Our Services</strong>
    </p>
    <p>
      Our website and services, including the Public Data and Better World
      Network, may not be misused by you and may only be used as permitted by law
      (including applicable export and re-export control laws and regulations).
      Using this website or our services, registering for an account, or
      accessing any information we post does not give you ownership of any
      intellectual property rights in such website, service, data, account, or
      information. Other than as set forth below, you are not granted any rights
      to use our logo, branding, or other information we provide. In addition,
      you may not remove, obscure, or alter any legal notices that we provide on
      this website, including in any service, including the Public Data or Better
      World Network.
    </p>
    <p>
      All intellectual property rights, which include copyrights, patents,
      trademarks, and trade secrets, provided on or in connection with our
      website or any services (including the Public Data and Better World
      Network), are either owned exclusively by us or other parties whom we have
      licensed those rights from, or we have otherwise acquired or believe that
      we possess appropriate rights to use such intellectual property. Except as
      expressly permitted below, you may not copy, create derivative works of,
      distribute, publicly display, publicly perform, sell, or lease any part of
      our website or our services (including the Public Data and Better World
      Network), and you may not reverse engineer or attempt to extract the source
      code of the same, unless laws prohibit these restrictions or you have our
      written permission.
    </p>
    <p>
      <strong>Limited License to Use Our Website and Services </strong>
    </p>
    <p>
      To the extent legally permissible, we hereby give you a worldwide,
      royalty-free, non-assignable, non-exclusive, fully-revocable, limited
      license to use and access this website and our services, subject to the
      following restrictions:
    </p>
    <ol>
      <li>
        You may not use or access this website or our services to build a
        similar or competitive website, product, or service.
      </li>
      <li>
        You may not license, sell, rent, lease, host, or otherwise commercially
        exploit this website, or any information or services on this website, or
        use anything on this website competitively against AppEsteem or otherwise
        in a commercial manner.
      </li>
      <li>
        You may not modify, make derivative works of, disassemble, reverse
        compile, or reverse engineer any part of this website or our services.
      </li>
      <li>
        Services provided to you under the terms of a separate agreement with us
        will remain subject to the terms of such agreement. For example, our
        Certification and Deceptor Requirements, and access to our API and any
        “Secured Data,” are respectively provided under separate licenses. If there
        is any conflict between these Terms, and the terms relating to such
        separate agreement, then those separate terms will control for that
        conflict.
      </li>
    </ol>
    <p>
      <strong>Limited License to Use the Public Data </strong>
    </p>
    <p>
      To the extent legally permissible, we hereby give you a worldwide,
      royalty-free, assignable, non-exclusive, fully-revocable, limited license
      to use and access the Public Data (excluding any screen shots and other
      graphical representations of any third-party software applications), and
      under the terms of this license, you may:
    </p>
    <ol>
      <li>
        Use the Public Data to protect yourself and others from, and inform
        others about, security and performance issues related to the behavior of
        certain software products and vendors;
      </li>
      <li>
        Reproduce and share (but not sell) the Public Data, in whole or in part;
        and
      </li>
      <li>
        Modify and create derivative works of the Public Data, and reproduce,
        distribute, and publicly display the Public Data that you have modified or
        changed.
      </li>
    </ol>
    <p>
      Your exercise of these rights is subject to the following express
      conditions:
    </p>
    <ol>
      <li>
        You may not use the Public Data or any of our services to: (i) perform
        an action with the intent of harming AppEsteem, including through
        introduction of any virus, worm, malware, or other harmful items; (ii)
        unlawfully defame, abuse, or harm others; or (iii) develop, create, or
        commercialize a product or service that competes with our Certification and
        Deceptor programs or any of our services.
      </li>
      <li>
        If you share or republish the Public Data to any third party, whether or
        not you modified it, you must include an attribution notice, including: (i)
        a reference to the date of the Public Data, (ii) a link back to the source
        of the Public Data on our website, (iii) a list of any modifications you
        have made to the Public Data, and (iv) reference to these Terms (including
        specifically the disclaimer of warranties and terms of use of the Public
        Data). In the interests of clarification, this requirement does not apply
        to the inclusion of Public Data in a Security Company’s (defined below)
        product database used to provide protection to consumers. For your
        convenience, we have included a form of this attribution notice at the end
        of these Terms.
      </li>
      <li>
        If you distribute or otherwise publicize any Public Data that you have
        modified, you must use a reasonable method to indicate that AppEsteem does
        not endorse and did not participate in any such modifications. For the
        avoidance of doubt, we are not providing you with consent to share the
        Public Data for compensation from any third party.
      </li>
      <li>
        These Terms do not authorize you to use the Public Data to run any
        public or private comparative or performance test of a third-party security
        product. Therefore, you are prohibited from using the Public Data for such
        purposes, unless we have authorized this use in a separate written
        agreement with you.
      </li>
      <li>
        You understand and agree to the following    <strong>Terms of Use of Deceptor Data</strong>:
      </li>
      <ol type="a">
        <li>
          We use the term “<strong>Deceptor Requirements</strong>” to describe our
          requirements that represent a minimum bar that we believe all products and
          services must meet to avoid deceptive and risky behaviors that could harm
          customers, and we use the term “<strong>Deceptor</strong>” to describe
          products and services we believe have violated at least one of our Deceptor
          Requirements.
        </li>
        <li>
          We have made commercially reasonable efforts to ensure the accuracy of
          our determination that a software product or service has violated one of
          the AppEsteem Deceptor Requirements. However, we do not guarantee the
          accuracy of our determination. If you believe we have made a mistake,
          please notify us.
        </li>
        <li>
          If you are a company that is a provider of security-related (e.g.,
          anti-virus) software or services that will use the Public Data as part of
          your decision to block or permit installation or use of software products
          on your customers’ devices (or make recommendations to your customers about
          risks of using or installing software products) (a “    <strong>Security Company</strong>”), we recommend that you carefully
          complete an independent review of any software products or services that we
          have labeled a Deceptor.
        </li>
        <li>
          If a Security Company believes that a product or service could harm
          consumers after that independent review, we encourage that company to
          consider blocking the product or service from installing on a consumer
          machine, or warning the consumer about the behavior that we believe
          violates a Deceptor Requirement. We may encourage, but we will not direct,
          any Security Company to take a specific action based on the Public Data,
          including our list of Deceptors.
        </li>
        <li>
          We have included information on our website for any company that has a
          product or service that has been labeled as a Deceptor, including
          instructions on how to use the freely-available Deceptor Requirements to
          identify their deceptive behavior, and how to submit their product or
          service for a free reevaluation by us.
        </li>
        <ol type="i">
          <li>
            If we determine the product or service no longer violates a Deceptor
            Requirement, we will mark the issue as resolved on our website, and remove
            the product or service from our current Deceptor list. We encourage
            Security Companies to reevaluate the product or service if it has been
            removed from the current Deceptor list, and act on their own independent
            assessment to determine if the issue has been resolved.
          </li>
          <li>
            If the product or service continues to violate a Deceptor Requirement,
            we will continue to identify the product or service as a Deceptor, and
            notify the company, which will then have the opportunity for continue to
            resubmit their product or service for a free reevaluation by us.
          </li>
        </ol>
        <li>
          <strong>
            <u>
              A determination to take any action regarding software products or
              services we label as a Deceptor must be based on your own
              independent assessment and investigation, and will be at your sole
              discretion and risk.
            </u>
          </strong>
        </li>
      </ol>
      <li>
        If you are a Security Company, you understand and agree to the following    <strong>Terms of Use of Certification Data by Security Companies</strong>:
      </li>
      <ol type="a">
        <li>
          You agree to implement necessary processes and act in good faith to
          honor such designation and allow the Certified Product to pass through your
          detection without being generically or heuristically blocked from
          operation. If you choose to not include these processes, and not to honor
          the designation of a Certified Product in any one or more of your products
          or services, you agree to notify us as to the reason why you chose to block
          the Certified Product.
        </li>
        <li>
          You agree to not use the Public Data identifying a company that has a
          Certified Product, or a Certified Product, as a basis for blocking or
          otherwise identifying such company or Product, except with regard to:
        </li>
        <ol type="i">
          <li>
            Our identification of a Certified Product as “consumer-only,” in which
            case you may, at your option, block such product in your designated
            enterprise product; and
          </li>
          <li>
            A company’s identification of the Certified Product as being limited to
            a specific category of end user (which may include a category of age
            appropriateness), in which case you may, at your option, block such product
            in other relevant specific categories, at the designation of your customer.
          </li>
        </ol>
        <li>
          <strong>
            <u>
              A determination to take any action regarding a Certified Product
              (or Non-Certified Product or service) will be at your sole
              discretion and risk
            </u>
          </strong>
          . You retain the right to use the Public Data as part of your decision to
          block or permit installation or use of software products on your customers’
          devices (or make recommendations to your customers about risks of using or
          installing software products), for any actionable reason. However, if you
          block a Certified Product, you agree to notify us of such block as soon as
          possible, and provide us with the actionable reason for such block. You
          further agree to work with us and the blocked vendor in good faith to
          remediate the reason for your block.
        </li>
        <li>
          We may include information about Non-Certified Products or services on
          our website, and may identify one or more Certification Requirements that
          we believe such Non-Certified Products or services fail to meet. We include
          this data for informational purposes only, and our list of Non-Certified
          Products or services will not be used by you as an indication that any such
          product or service is a Deceptor, that any such product or service may or
          may not become a Certified Product, or that we have included a complete
          list of any such Non-Certified Product or service’s compliance with our
          Certification Requirements. You agree to carefully complete an independent
          review of any such information to make an independent determination before
          you act on any such data.
        </li>
      </ol>
    </ol>`;
    const para2 = `<p>
    <strong>Disclaimer of Warranties</strong>
  </p>
  <p>
    We provide the information on our website and certain services (including
    the Public Data and Better World Network) for free to you. However, there
    are certain things that we do not guarantee or claim about this website or
    our services (including the Public Data and Better World Network):
  </p>
  <p>
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE PROVIDE THIS WEBSITE
    AND OUR SERVICES, INCLUDING THE PUBLIC DATA AND BETTER WORLD NETWORK, UNDER
    THESE TERMS OF USE AS IS AND AS-AVAILABLE, WITHOUT ANY REPRESENTATIONS OR
    WARRANTY OF ANY KIND COVERING OUR WEBSITE OR SERVICES, INCLUDING THE PUBLIC
    DATA AND BETTER WORLD NETWORK, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
    OTHER, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF TITLE,
    NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
    ABSENCE OF LATENT OR OTHER DEFECTS, ACCURACY, OR THE PRESENCE OR ABSENCE OF
    ERRORS, WHETHER OR NOT KNOWN OR DISCOVERABLE. NO ADVICE PROVIDED BY US TO
    YOU WILL CREATE A WARRANTY OF ANY KIND UNDER ANY JURISDICTION, AND WE
    SPECIFICALLY DO NOT PROVIDE ANY ADVICE ABOUT TAKING ANY SPECIFIC ACTION
    REGARDING OUR WEBSITE OR SERVICES, INCLUDING THE PUBLIC DATA AND BETTER
    WORLD NETWORK. WHERE DISCLAIMERS OF WARRANTIES ARE NOT ALLOWED IN FULL OR
    IN PART, THIS DISCLAIMER MAY NOT APPLY TO YOU.
  </p>
  <p>
    <strong>Limitation of Liability</strong>
    . TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT BE LIABLE
    TO YOU UNDER ANY LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE)
    OR OTHERWISE FOR ANY INTERRUPTION OR DAMAGE TO YOUR BUSINESS, OR FOR ANY
    INDIRECT, EXEMPLARY, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING
    FROM (OR RELATED TO) THIS AGREEMENT, OR YOUR USE OF OUR WEBSITE OR
    SERVICES, INCLUDING THE PUBLIC DATA OR BETTER WORLD NETWORK, OR OTHERWISE
    IN CONNECTION WITH THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE
    POSSIBILITY OF SUCH LOSSES, COSTS, EXPENSES, OR DAMAGES. IN NO EVENT, UNDER
    ANY CIRCUMSTANCES, WILL OUR AGGREGATE LIABILITY TO YOU IN CONNECTION WITH
    OUR WEBSITE OR SERVICES, INCLUDING THE PUBLIC DATA AND BETTER WORLD
    NETWORK, WHETHER FROM CONTRACT OR TORT OR OTHERWISE, EXCEED THE AMOUNT OF
    FIFTY ($50) US DOLLARS. THIS LIMITATION OF LIABILITY IS CRITICAL TO US, AND
    FORMS AN ESSENTIAL BASIS OF THIS AGREEMENT AND OUR RELATIONSHIP WITH YOU,
    AND WE WOULD NOT ENTER INTO THIS AGREEMENT ON THESE TERMS IF THE
    LIMITATIONS OF LIABILITY WERE NOT INCLUDED.
  </p>
  <p>
    The disclaimer of warranties and limitation of liability provided above
    will be interpreted in a manner that, to the extent possible, most closely
    approximates an absolute disclaimer and waiver of all liability.
  </p>
  <p>
    <strong>Accuracy</strong>
    . We have worked to ensure the accuracy of the information we provide on
    our website, and through our services, including the Public Data. This
    information reflects our research at the time of publication. However, this
    information frequently relies on data obtained from many sources, and
    accordingly, we cannot guarantee the accuracy of the information provided
    or any analysis based thereon. Moreover, in light of evolving regulatory
    and market conditions, including Requirements, this information may no
    longer be current. Specifically, products are Certified against a list of
    Certification Requirements in effect on the date of Certification, and must
    remain in compliance with those Requirements, excluding any subsequent or
    modified Requirements, for one year to retain compliance, along with
    meeting other terms and conditions of Certification. Therefore, we
    recommend that you carefully review the Public Data, including the date of
    Certification. If you believe we have made a mistake in the Public Data or
    otherwise on our website or in our services, please notify us by sending an
    email to <a href="mailto:info@appesteem.com" target="_blank" rel="noopener noreferrer" >info@appesteem.com</a>.
  </p>
  <p>
    <strong>Equitable Remedy</strong>
  </p>
  <p>
    Any material breach of these ‎Terms by you, or your directors, officers,
    employees, affiliates, or other representatives may cause immediate and
    ‎irreparable harm, for which monetary damages would be inadequate or
    difficult to ascertain. ‎You therefore agree that upon the existence of any
    such breach or threatened breach, we may immediately seek a temporary
    restraining order or other appropriate ‎form of equitable relief, without
    posting a bond or other form of security, from any court ‎having
    jurisdiction over the matter. This paragraph will not limit our rights to
    obtain ‎monetary damages in addition to or as substitution for such
    equitable relief.‎
  </p>
  <p>
    <strong>Business Use and Indemnification</strong>
  </p>
  <p>
    If you are accessing this website or our services (including registration
    for any free service, such as Deceptor notification, or accessing the
    Public Data or Better World Network) on behalf of a business, either
    directly or through a registered account, you represent and warrant that
    you are authorized to do so and that such business accepts these Terms.
    <strong>
      In addition, you represent and agree that such business will hold
      harmless and indemnify AppEsteem and its affiliates, officers, agents,
      and employees from any claim, suit, or action arising from or related
      to your use of our website or our services (including use of the Public
      Data and Better World Network), or violation of these Terms, including
      any liability or expense arising from claims, losses, damages, suits,
      judgments, litigation costs, and attorneys’ fees.
    </strong>
    <strong>
      If you are a Security Company and have executed the Secured Data
      Agreement with us, the indemnification provision in such Agreement
      supersedes the foregoing provision.
    </strong>
  </p>
  <p>
    <strong>Your AppEsteem Account</strong>
  </p>
  <p>
    You are required to register for an account, or complete an application, to
    use some of the services, including to register with us or apply to have a
    software product certified by us. Although you are not obligated to
    complete this registration or application once you start it, please
    understand that the information you fill into the registration or
    application form, regardless of whether you submit it, is considered by us
    to become “<strong>Account Information</strong>.” In accessing such
    registration or application form, you are committing to us that you have
    proper authority from your company to provide the Account Information to
    us. You may delete your account with us at any time, for any reason.
  </p>
  <p>
    By providing any Account Information, feedback, suggestions,
    recommendations, or otherwise providing information on this website, you
    give us (and our affiliates) an irrevocable, worldwide, royalty-free,
    assignable, and non-exclusive license to reproduce, distribute, publicly
    display, prepare derivative works of, and otherwise use such information,
    for our business purposes. This license continues even if you stop using
    this website or any of our services. Therefore, please ensure that you have
    the necessary rights to grant us this license for any information,
    including Account Information, feedback and recommendations, that you
    submit or provide to us.
  </p>
  <p>
    You are responsible for maintaining the confidentiality of your account
    login information, and for any activities that occur under your account. If
    you learn of any unauthorized use of your account login information, or
    other access to your account, please contact us as soon as possible.
  </p>
  <p>
    <strong>Modifying and Terminating this Website or the Public Data</strong>
  </p>
  <p>
    We are constantly changing and improving this website and our services
    (including the Public Data and Better World Network), and we reserve the
    right to make any such changes, or suspend or terminate this website, any
    service, or your account, at any time, in our sole discretion, without any
    liability or notice to you. You may stop using our website or our services
    (including the Public Data and Better World Network), at any time in your
    sole discretion, subject to the conditions of any separate agreement
    covering such use.
  </p>
  <p>
    Notwithstanding the foregoing, if you are a Security Company that has
    executed the API License Agreement, and such Agreement is current in
    effect, if we cease to provide the Public Data on our website, we will use
    our best efforts to notify you in writing at the email address listed in
    your Security Company registration, no later than 30 days prior to such
    termination.
  </p>
  <p>
    <strong>Privacy and Copyright Protection</strong>
  </p>
  <p>
    We respect your privacy and encourage you to read our <Link to="/privacypolicy">Privacy Statement</Link>
    , which explains our data
    processing practices, including how we collect and store your personal data, your rights regarding such
    data, and how we protect your privacy when you use this website. By accessing our website or using our
    services (including accessing the Public Data or Better World Network, or providing Account Information),
    you signify to us that you have read and understand how we may use your personal data in accordance
    with our Privacy Statement.
  </p>
  <p>
    We respect the intellectual property of others and ask that you do the same. We will respond to notices of
    alleged copyright infringement and terminate registered accounts or access to this website of repeat
    infringers according to the process set forth in the U.S. Digital Millennium Copyright Act. If you think that
    someone is violating your copyright, or that of another party, please submit a notification to us at 
    <a href="mailto:legal@appesteem.com" target="_blank" rel="noopener noreferrer" > legal@appesteem.com</a>.
  </p>
  <p>
    <strong>Parental Control Protections</strong>
  </p>
  <p>
    Pursuant to Title 47 U.S.C. § 230(d), we hereby notify you that parental
    control protections (such as computer hardware, software or filtering
    services) are commercially available that may assist you in limiting access
    to material that is harmful to minors. Information identifying current
    providers of such protections is available from the <a href="http://www.getnetwise.org/" target="_blank" rel="noopener noreferrer" >Internet Education Foundation</a> and
    the <a href="https://www.consumer.ftc.gov/topics/protecting-kids-online" target="_blank" rel="noopener noreferrer" >Federal Trade Commission</a>
    . Please note that we are not affiliated with and do not endorse any of the
    products or services listed on such sites.
  </p>
  <p>
    <strong>General Information About These Terms</strong>
  </p>
  <p>
    We may revise or amend these Terms at any time, in our discretion. We
    recommend that you review these Terms regularly, and we will post a
    notification on this page in and when our Terms materially change. Any
    material changes that we make to these Terms will become effective no
    sooner than 10 days after they are posted, unless such changes are legally
    required to be made immediately effective. If you do not agree with any
    changes we make, please discontinue your access and use of our website and
    our services.
  </p>
  <p>
    Notwithstanding the foregoing, if you are currently registered with us to receive Deceptor Notification or as
    a Customer, or if you have executed the API License Agreement, and such Agreement is currently in effect,
    if we make any material changes to these Terms, we will notify you in writing at the email address listed in
    your registration, and the change to these Terms will become effective for you 30 days from the date of such notice,
    unless you notify us in writing that you do not agree to such change within
    that 30-day period, in which case, please discontinue your access and use
    of our website and our services.
  </p>
  <p>
    These Terms are between AppEsteem and you, and do not create any
    third-party beneficiary rights.
  </p>
  <p>
    The sections titles in these Terms are for convenience only and have no
    legal or contractual effect.
  </p>
  <p>
    If you click on any link to a third-party website from this website, please note that these websites are not
    under our control and we have not reviewed such websites and are not responsible for the content. We
    provide links to third-party sites only as a convenience and for informational purposes, and the inclusion
    of such links does not imply AppEsteem endorsement of the site, the organization operating such site, or
    any products or services of such organization. A visit to any third-party website or page from our website
    via any such link is done entirely at your own risk.
  </p>
  <p>
    Our failure to exercise or enforce any right or provision of these Terms is
    not a waiver of such right or provision.
  </p>
  <p>
    If any provision of these Terms is, for any reason, held to be invalid or
    unenforceable, the other provisions of these Terms will be unimpaired and
    the invalid or unenforceable provision will be deemed modified so it is
    valid and enforceable to the maximum extent permitted by law.
  </p>
  <p>
    The laws of the State of Washington, not including any conflict of laws
    rules, will apply to any disputes arising out of or in relation to these
    Terms, or our website (including the Public Data and Better World Network).
    Any claims arising out of or relating to these Terms, our website, or our
    services (including the Public Data and Better World Network), will be
    brought exclusively in the federal or state courts of King County,
    Washington, USA, and both you and we agree to submit to the personal
    jurisdiction of such courts.
  </p>
  <div>
    <p align="center">
      <strong><u>ATTRIBUTION NOTICES</u></strong>
    </p>
    <p>
      If you provide the Public Data to any third party, whether or not you
      modified it, you must include an AppEsteem attribution notification,
      which must include a reference to the date of the Public Data, a link
      back to the source of the Public Data, a list of any modifications made
      to the Public Data, and reference to these Terms.
    </p>
    <p>
      1. <strong>For Unmodified Data</strong>: This list of AppEsteem
      Certified Products, Uncertified Products or services, Deceptors, and
      related information, was provided by AppEsteem Corporation [<strong>Include Hyperlink to Source</strong>] on [        <strong>Date of Source</strong>] and is licensed under the AppEsteem
      Terms of Use, which includes a specific disclaimer of warranties and
      waiver of liability that applies to this information. Any access and
      use of this information is subject to the user’s sole judgment and at
      their sole risk.
    </p>
    <p>
      2. <strong>For Modified Data, Add to Foregoing Attribution</strong>:
      This information has been modified by [Company Name], and any such
      modifications have not been reviewed, and are not approved or endorsed,
      by AppEsteem Corporation, and AppEsteem Corporation will not be liable
      for any such modified data, including any inaccuracies contained
      therein.
    </p></div>`;
    const englishTerms = `<div>
        ${header}
        ${para1}
        ${para2}
    </div>`;
    let translatedTerms = "";
    this.state = {
      translatedTerms,
      englishTerms,
      header,
      para1,
      para2,
      loading: true,
    }
    this.translateLang = CommonUtil.lang;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTranslation();
}
  componentDidUpdate(prevProps) {
    if (this.translateLang !== CommonUtil.lang) {
      this.translateLang = CommonUtil.lang;
      this.getTranslation();
    }
  }
  getTranslation() {
    if (CommonUtil.IsEnglishLang()) {
      this.setState( c => {
        c.loading = false;
        return c;
      });
      return;
    }
    const titleListString = "termsofuse/header@termsofuse/para1@termsofuse/para2";
    const titleDict = {"termsofuse/header": this.state.header, "termsofuse/para1":this.state.para1, "termsofuse/para2":this.state.para2};
    const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
    let hasCachedText;
    axios.get(checkTextURL) // check if we need to send text over
    .then((response) => {
      hasCachedText = response.data;
    }, (error) => {
      hasCachedText = false;
    })
    .finally(() => {
      const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=termsofuse`
                            : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=termsofuse`;
      const data = (hasCachedText) ? null : titleDict;
      axios.post(translateURL, data)
      .then((response) => {
        let translatedTerms = `<div><Container>
                                ${response.data["termsofuse/header"]}
                                ${response.data["termsofuse/para1"]}
                                ${response.data["termsofuse/para2"]}
                              </Container></div>`;
        this.setState({
          translatedTerms,
          loading: false,
        });
      }, (error) => {
        this.setState({
          translatedTerms: "API error, could not translate",
          loading: false,
        });
      })
    });
  }
  render() {
    const englishTermsHTML = Parser(this.state.englishTerms);
    const translatedTermsHTML = Parser(this.state.translatedTerms);
    return (
      (!CommonUtil.IsEnglishLang()) 
      ? <div>
            {(!this.state.loading) 
                ? <div>{translatedTermsHTML}</div> 
                : <div style={{display:"flex", "justify-content":"center"}}>
                    <div className="dimmable" style={{margin:"0 auto", width:"100px", height:"100px", top:"50%", position:"fixed"}}>
                        <div className="ui active inverted dimmer">
                            <div className="ui massive text loader" ></div>
                        </div>
                    </div>
                </div>}
          </div>
      :   <div>{englishTermsHTML}</div>
    );
  }
}

export default TermsOfUse;