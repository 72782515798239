import React, { Component } from 'react';
import {Grid,Image, Card, Form} from 'semantic-ui-react';
import Validator from 'validator';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reprocess: false,
            errorDict: {},
            hasError: false
        }
    }
    componentDidMount() {
    }
    validateForm(action) {
        var hasError = false;
        var errorDict = {};
        if((action && action.validate) || this.state.hasError) {
            var fields = [];
            if(this.props.fields) {
                fields = this.props.fields;
            }
            for(var i in fields) {
                var field = fields [i];
                if(!field.id || !field.show) {
                    continue;
                }
                errorDict[field.id] = false;
                if(field.required && !field.value) {
                    hasError = true;
                    errorDict[field.id] = true;
                } else if(field.required && field.ctype === "email" && !Validator.isEmail(field.value)) {
                    errorDict[field.id] = true;
                    hasError = true;
                }
            }
            this.setState(c=> {
                c.hasError = hasError;
                c.errorDict = errorDict;
                return c;
            });
        }
        return hasError;
    }
    submitForm(action) {
        if(!this.validateForm(action)) {
            action.onClick(action.id)
        }
    }
    renderChoice(field) {
        return (
            <Card.Group>
                {field.choices.map(choice => {
                    if(!choice.show) {
                        return ("");
                    }
                    return (
                        <Card onClick={(e) => {choice.onClick(choice.id)}}>
                            <Card.Content>
                                <Card.Description className="center aligned">
                                <img className="supp-card-img" src={choice.imagesrc} alt="tile image" />
                                <div className="supp-card-desc">
                                    {choice.description}
                                </div>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    )
                })}
            </Card.Group>
        )
    }
    renderTextInput(field) {
        return(
            <Form.Group>
                <Form.Field required={field.required} width={16} className="supp-card-field">
                    <label>{field.label}</label>
                    <Form.Input error={this.state.errorDict[field.id]} placeholder={field.placeholder} value={field.value} onChange={(e)=> {this.validateForm();var val = e.target.value; field.onChange(field.id, val)}}/>
                </Form.Field>
            </Form.Group>        
        )
    }
    renderTextArea(field) {
        return(
            <Form.Group>
                <Form.Field required={field.required} width={16} className="supp-card-field">
                    <label>{field.label}</label>
                    <Form.TextArea error={this.state.errorDict[field.id]} placeholder={field.placeholder} value={field.value} onChange={(e)=> {this.validateForm(); var val = e.target.value; field.onChange(field.id, val)}}/>
                </Form.Field>
            </Form.Group>        
        )
    }
    renderCheckbox(field) {
        return(
            <Form.Group>
                <Form.Field required={field.required} width={16} className="supp-card-field">
                    <Form.Checkbox label={field.label} checked={field.checked} onClick={(e)=> {field.onClick(field.id)}}/>
                </Form.Field>
            </Form.Group>        
        )
    }
    renderActionButton(field) {
        return (
            <Grid>
                {field.actions.map(action => {
                    if (action.show) {
                        return (
                            <Grid.Column className="supp-card-field" textAlign={action.align} width={action.width ? action.width : 3}>
                                <div className="supp-div-btn">
                                    <button className={`ui button ${action.clsName}`} onClick={() => this.submitForm(action)}>{action.content}</button>
                                </div>
                            </Grid.Column>
                        )
                    }
                    else return "";
                    
                })}
            </Grid>
        )   
    }
    render() {
        if(!this.props.show) {
            return ("");
        }
        var fields = [];
        if(this.props.fields) {
            fields = this.props.fields;
        }
        return (
            <>
                {fields.map((field) => {
                    if(!field.show) {
                        return ("");
                    } else if(field.type === "choice") {
                        return this.renderChoice(field);                    
                    } else if(field.type === "text") {
                        return this.renderTextInput(field);                    
                    } else if(field.type === "textArea") {
                        return this.renderTextArea(field);                    
                    } else if(field.type === "checkbox") {
                        return this.renderCheckbox(field);                    
                    } else if(field.type == "action") {
                        return this.renderActionButton(field);                    
                    } else {
                        return "";
                    }
                })}
            </>
        ) 
    }
}

export default ContactForm;
