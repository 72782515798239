import React, { Component } from 'react';
import { Button, Modal, Grid} from 'semantic-ui-react';
import PremiumHeader from './PremiumHeader.js';
import PremiumBody from './PremiumBody.js';
import PremiumFooter from './PremiumFooter.js';
import { PremiumUtil } from '../utils/PremiumUtil.js';
import {ReactGAInstance} from '../connectors/ReactGAInstance.js';

class PremiumSignupPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInit: false,
      isLoading: true,
      isLoadingBlurMessgs: true,
      loadingMessage: PremiumUtil.Translate("ppLoading", "Loading ..."),
      errorMessage: "",
      renderStatus: "",
      selectedSubscId: "",
      bodyErrorMessg: ""
    }
  }
  componentDidMount(){
    PremiumUtil.SetBlurMessages().then((resp)=> {}).catch((err)=>{}).then(() => {
      this.setState(c=> {
        c.isLoadingBlurMessgs = false;
        return c
      })
    });
  }
  componentDidUpdate(prevProps) {
    //only update this is value is updated from parent
    if(this.props.open !== prevProps.open && this.props.open) {
      this.init();
    }
  }
  selectSubscription(subscId) {
    this.setState(c => {
      c.selectedSubscId = subscId;
      return c;
    })
  }
  init() {
    ReactGAInstance.PageView("/premium/popup");
    this.setState(c=> {
      c.isInit = true;
      c.errorMessage = "";
      return c;
    })
    PremiumUtil.View = "";
    this.reRender(true, true, PremiumUtil.Translate("ppLoading", "Loading ..."));
  }
  signIn() {
    PremiumUtil.View = "signIn";
    ReactGAInstance.PageView("/premium/popup/signIn");
    this.setState(c=> {
      c.errorMessage = "";
      return c;
    });
  }
  signOut() {
    this.setState(c=> {
      c.isLoading = true; 
      c.loadingMessage = PremiumUtil.Translate("ppSigningOut", "Signing Out ..."); 
      c.errorMessage = "";
      return c;
    });
    ReactGAInstance.PageView("/premium/popup/signOut");
    PremiumUtil.SignOut().then(resp => {
      PremiumUtil.View = "";
      this.reRender(true, true, "Loading ...");
    }).catch(err => {
      PremiumUtil.View = "";
      this.reRender(true, true, PremiumUtil.Translate("ppLoading", "Loading ..."), PremiumUtil.Translate("ppErrorSigningOutMsg", "Error signing out, please try again!"));
    })
  }
  unEnrollMe() {
    ReactGAInstance.PageView("/premium/popup/unEnrollMe");
    this.setState(c=> {
      c.isLoading = true; 
      c.loadingMessage = PremiumUtil.Translate("ppUnenrolling", "Unenrolling ..."); 
      c.errorMessage = "";
      return c;
    });
    PremiumUtil.UnEnrollMe(PremiumUtil.GetSubscriptionId()).then(resp => {
      PremiumUtil.View = "";
      this.reRender(true, true, PremiumUtil.Translate("ppLoading", "Loading ..."));
    }).catch(err => {
      PremiumUtil.View = "";
      this.reRender(true, true, PremiumUtil.Translate("ppLoading", "Loading ..."), PremiumUtil.Translate("ppErrorWhileUnenrollingMsg", "Error while unenrolling, please try again!"));
    }).then(() => {
      PremiumUtil.PingExtension();
    });
  }
  //for mycommerce
  manageMyCommercePayment(subscId) {
    this.setState(c=> {
      c.isLoading = true; 
      c.loadingMessage = PremiumUtil.Translate("ppLoading", "Loading ...");
      c.bodyErrorMessg = "";
      return c;
    });
    PremiumUtil.GetMyCommerceManagePaymentLink(subscId).then(resp => {
      if(resp.data.paymentLink) {
        window.open(resp.data.paymentLink, "_blank");
      }
    }).catch(err => {
      this.setState(c=> {
        c.bodyErrorMessg = PremiumUtil.Translate("ppErrorPleaseTryAgain", "Error, please try again!");
        return c;
      });
    }).then(() => {
      this.setState(c=> {
        c.isLoading = false; 
        return c;
      });
    });
  }
  cancelMyCommerceSubscription(subscId) {
    this.setState(c=> {
      c.isLoading = true; 
      c.loadingMessage = PremiumUtil.Translate("ppCancelling", "cancelling ...");
      c.bodyErrorMessg = "";
      return c;
    });
    PremiumUtil.CancelMyCommerceSubscription(subscId).then(resp => {
      this.init();
      PremiumUtil.PingExtension();
    }).catch(err => {
      this.setState(c=> {
        c.isLoading = false; 
        c.bodyErrorMessg = PremiumUtil.Translate("ppErrorPleaseTryAgain", "Error, please try again!");
        return c;
      });
    }).then(() => {
    });
  }
  //for chargeBee
  showManagePayment() {
    ReactGAInstance.PageView("/premium/popup/mangePayment");
    let clickon = document.querySelectorAll('div#BlurManage a')[0];
    clickon.click();
  }
  manageEnrollments() {
    ReactGAInstance.PageView("/premium/popup/manageEnrollment");
    PremiumUtil.View = "manageEnrollment";
    this.setState(c=> {
      c.errorMessage = "";
      return c;
    });
  }
  onClose() {
    if(this.props.onClose) {
      this.props.onClose();
    }
  }
  reRender(loadWhoAmI, isLoading, loadingMessage, errorMessage) {
    this.setState(c=> {
      c.bodyErrorMessg = "";
      return c;
    });
    
    if(loadWhoAmI || isLoading) {
      this.setState(c=> {
        c.isLoading = true;
        c.loadingMessage = loadingMessage;
        return c;
      })
    }
    if(errorMessage) {
      this.setState(c=> {
        c.isLoading = false;
        c.errorMessage = errorMessage;
        return c;
      })
    }
    if(loadWhoAmI) {
      PremiumUtil.GetWhoAmI().catch(err => {
        this.setState(c=> {
          c.errorMessage = PremiumUtil.Translate("ppErrorLoadingMsg", "Error loading, please try again!");
          return c;
        })
      }).then(() => {
        this.setState(c=> {
          c.isLoading = false;
          return c;
        })
      });
    } else if(!isLoading){
      this.setState(c => {
        c.isLoading = false;
        return c;
      })
    } else {
      this.setState(c => {
        c.renderStatus = "complete";
        return c;
      })
    }
  }
  renderPremium() {
    if(this.state.errorMessage) {
      return (
        <Grid centered padded>
          <PremiumHeader reRender={this.reRender.bind(this)}/>
          <Grid.Row className="signupBody">
            {PremiumUtil.RenderError(this.state.errorMessage)}          
          </Grid.Row>
          <PremiumFooter 
            reRender={this.reRender.bind(this)} 
            loading={this.state.isLoading} 
            loadingMessage={this.state.loadingMessage} 
            unEnrollMe={this.unEnrollMe.bind(this)}
            signOut={this.signOut.bind(this)}
            signIn={this.signIn.bind(this)}
            showManagePayment={this.showManagePayment.bind(this)}
            manageEnrollments={this.manageEnrollments.bind(this)}/>
        </Grid>
      )
    }
    if(this.state.isInit) {
      return (
        <Grid centered padded>
          <PremiumHeader reRender={this.reRender.bind(this)}/>
          <Grid.Row className="signupBody">
            <PremiumBody 
              selectedSubscId={this.state.selectedSubscId}
              errorMessage={this.state.bodyErrorMessg}
              reRender={this.reRender.bind(this)}
              onClose={this.onClose.bind(this)}
              loading={this.state.isLoading || this.state.isLoadingBlurMessgs} 
              loadingMessage={this.state.loadingMessage}
              selectSubscription={this.selectSubscription.bind(this)}
              unEnrollMe={this.unEnrollMe.bind(this)}/>
          </Grid.Row>
          <PremiumFooter 
            reRender={this.reRender.bind(this)} 
            loading={this.state.isLoading || this.state.isLoadingBlurMessgs} 
            loadingMessage={this.state.loadingMessage} 
            selectedSubscId={this.state.selectedSubscId}
            unEnrollMe={this.unEnrollMe.bind(this)}
            signOut={this.signOut.bind(this)}
            signIn={this.signIn.bind(this)}
            showManagePayment={this.showManagePayment.bind(this)}
            manageMyCommercePayment={this.manageMyCommercePayment.bind(this)}
            cancelMyCommerceSubscription={this.cancelMyCommerceSubscription.bind(this)}
            manageEnrollments={this.manageEnrollments.bind(this)}/>
        </Grid>
      )
    } else {
      return ("");
    }
    
  }
  render() {
    return (
      <div>
        <Modal size='tiny' open={this.props.open} trigger={this.props.trigger} closeIcon onOpen={() => this.init()} onClose={() => this.onClose()}>
            {this.renderPremium()}
        </Modal>
      </div>
    );
  }
}
export default PremiumSignupPopup