import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Header, Form, Button, Icon} from 'semantic-ui-react';
import {PremiumUtil} from '../utils/PremiumUtil';
import { ReactGAInstance } from '../connectors/ReactGAInstance';

class OfferPremium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      authcode: "",
      emailSyntaxError: "",
      codeSent: false,
      isLoading: false,
      errorMessage: "",
    }
  }
  componentDidMount(){
  }
  handleInputChange(event) {
    let svar = event.target.name;
    let sval = event.target.value;
    if(!svar) {
      return;
    }
    this.setState(c => { 
      c[svar] = sval;
      return c; 
    });
  }

  sendCode(ev) {
    this.setState(c=>{
      c.emailSyntaxError = false;
      c.errorMessage = "";
      c.codeSent = false;
      c.isLoading = true;
      return c;
    });
    ReactGAInstance.PageView("/premium/popup/sendcode");
    if (!PremiumUtil.IsEmailValid(this.state.email)) {  // correct email syntax
      this.setState(c=>{
        c.emailSyntaxError = true;
        c.errorMessage = PremiumUtil.Translate("ppEnterEmailInFormatMsg", "Please enter email in format name@example.com");
        c.isLoading = false;
        return c;
      });
      return; //no need to move fwd
    }
    PremiumUtil.SendCode(this.state.email).then((resp) => {
      this.setState(c=>{
        c.codeSent = true;
        c.isLoading = false;
        return c;
      });
      PremiumUtil.View = "emailVerification";
      this.props.reRender();
    }).catch((error) => {
      this.setState(c=>{
        c.errorMessage = PremiumUtil.Translate("ppErrorSendingCodeMsg", "Error sending code, please try again!");
        c.codeSent = false;
        c.isLoading = false;
        return c;
      });
    });
  }

  verifyCode(ev) {
    ReactGAInstance.PageView("/premium/popup/verifycode");
    this.setState(c=>{
      c.authcodeSyntaxError = false;
      c.errorMessage = "";
      c.isLoading = true;
      c.loadingMessage = PremiumUtil.Translate("ppVerifyingCode", "Verifying code ...");
      return c;
    });
    if (!PremiumUtil.IsCodeValid(this.state.authcode)) {  // correct code syntax: (6 characters)
      this.setState(c=>{
        c.authcodeSyntaxError = false;
        c.errorMessage = PremiumUtil.Translate("ppPleaseEnterCodeInCorrectFormatMsg", "Please enter code in correct format (6 characters)");
        c.isLoading = false;
        return c;
      });
      return; //no need to go fwd
    }

    PremiumUtil.VerifyCode(this.state.email, this.state.authcode).then((resp) => {
      PremiumUtil.GetWhoAmI().then(()=> {
        PremiumUtil.View = "";
        this.props.reRender(true, false, PremiumUtil.Translate("ppLoading", "Loading ..."), "");
      })
    }).catch((api_error) => {
      this.setState(c=>{
        c.errorMessage = PremiumUtil.Translate("ppErrorVerifyingCodeMsg", "Error verifying code, please try again!");
        c.isLoading = false;
        return c;
      });
    });
  }

  startOver() {
    PremiumUtil.View = "signIn";
    this.props.reRender();
  }

  render() {
    //we reached to this component that means 
    //blur is neither subscribed nor any subscriptions available (individual or affiliates)
    if(this.state.isLoading) {
      return (
        <div className="ui inverted active dimmer">
          <div className="ui massive text loader">{this.state.loadingMessage}</div>
        </div>
      )
    } else if(PremiumUtil.IsAuthenticated()) {
      return (
        <Form>  
          <Header as='h5' color='blue'>{PremiumUtil.Translate("ppYouHaveNoSubscriptionsAvailable", "You have no subscriptions available")}</Header>
          <Button className="btm_button" fluid color='blue' onClick={()=>{this.props.history.push("/buy-premium");this.props.onClose();}}>
            {PremiumUtil.Translate("lpBuyPremium", "Buy Premium")}
          </Button>
        </Form>
      )
    } else if(PremiumUtil.View === "emailVerification") {
      return (
        <Form>
          <div className="codesent_email">
            <Icon name='user circle' size='big' color='blue'/>
            <span>{this.state.email}</span>
          </div>
          <div className="popup_header">
            {PremiumUtil.Translate("ppCheckYourEmailToGetVerificationCodeMsg", "Check your email to get the verification code (look for a message from Blur.live support)")}
          </div>
          <Form.Input
            name="authcode"
            size="large"
            placeholder={PremiumUtil.Translate("ppEnterVerificationCode", "Enter verification code")}
            value={this.state.authcode.toUpperCase()}
            error={this.state.authcodeSyntaxError}
            onChange={this.handleInputChange.bind(this)}
          />
          <div className="resend_otp">
            <a href="javascript:void(0)" onClick={this.startOver.bind(this)} >
              {PremiumUtil.Translate("ppResendOneTimePassword", "Resend the one time password")}
            </a>
          </div>
          {PremiumUtil.RenderError(this.state.errorMessage)}          
          <Button className="btm_button" fluid color='blue' disabled={!this.state.authcode} 
            onClick={this.verifyCode.bind(this)}>
            {PremiumUtil.Translate("ppLogin", "Login")} →
          </Button>
        </Form>
      )
    } else {
      return (
        <Form>
          <div className="popup_header">
            {PremiumUtil.Translate("ppLoginToEnrollThisBrowserMsg", "Login to enroll this browser or other users to an existing subscription")}
          </div>
          <Form.Input
            fluid
            name="email"
            placeholder={PremiumUtil.Translate("ppYourEmail", "Your email")}
            value={this.state.email}
            error={this.state.emailSyntaxError}
            onChange={this.handleInputChange.bind(this)}
          />
          {PremiumUtil.RenderError(this.state.errorMessage)}          
          <Button className="btm_button" fluid color='blue' disabled={!this.state.email} 
            onClick={this.sendCode.bind(this)}>
            {PremiumUtil.Translate("ppContinue", "Continue")} →
          </Button>
        </Form>
      )
    } 
  }
}

export default withRouter(OfferPremium);