import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Container, Grid, Header, Image, List, Segment, Button } from 'semantic-ui-react';
import { Settings } from '../Settings';
import axios from 'axios';
import Parser from 'html-react-parser';
import { CommonUtil } from '../utils/CommonUtil';
import { PremiumUtil } from '../utils/PremiumUtil';


class SiteFooter extends Component {
  constructor(props) {
    super(props);
    const companyTitle  = `Company`;
    const docTitle  = `Documents`;
    const docLink1 = `Terms and Conditions`;
    const docLink2 = `Privacy Statement`;
    const docLink3 = `Cookie Usage`;
    const docLink4 = `Uninstall Instructions`;
    const docLink5 = `Support`;
    const btnLearnMore = `Learn More`;
    //this.footer = React.createRef();
    this.state = {
      forcevar: false,
      translatedCompanyTitle: Parser(companyTitle),
      translatedDocTitle: Parser(docTitle),
      translatedDocLink1: Parser(docLink1),
      translatedDocLink2: Parser(docLink2),
      translatedDocLink3: Parser(docLink3),
      translatedDocLink4: Parser(docLink4),
      translatedDocLink5: Parser(docLink5),
      translatedBtnLearnMore: Parser(btnLearnMore),
      companyTitle,
      docTitle,
      docLink1,
      docLink2,
      docLink3,
      docLink4,
      docLink5,
      btnLearnMore,
      message: "",
      errorTranslation: true
    }
    this.translateLang = CommonUtil.lang;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTranslation();
  }
  componentDidUpdate(prevProps) {
    if (this.translateLang !== CommonUtil.lang) {
        this.translateLang = CommonUtil.lang;
        this.getTranslation();      
    }
  }
  parseEnTranslation() {
    this.setState( c => {
        c.translatedCompanyTitle = Parser(this.state.companyTitle);
        c.translatedDocTitle = Parser(this.state.docTitle);
        c.translatedDocLink1 = Parser(this.state.docLink1);
        c.translatedDocLink2 = Parser(this.state.docLink2);
        c.translatedDocLink3 = Parser(this.state.docLink3);
        c.translatedDocLink4 = Parser(this.state.docLink4);
        c.translatedDocLink5 = Parser(this.state.docLink5);
        c.translatedBtnLearnMore = Parser(this.state.btnLearnMore);
      return c;
    });
  }
  getTranslation() {
    if (CommonUtil.IsEnglishLang()) {
      this.parseEnTranslation();
      return;
    }
    const titleListString = "footer/companyTitle@footer/docTitle@footer/docLink1@footer/docLink2@footer/docLink3@footer/docLink4@footer/docLink5@footer/btnLearnMore";
    const titleDict = {"footer/companyTitle": this.state.companyTitle,
                        "footer/docTitle": this.state.docTitle,
                        "footer/docLink1": this.state.docLink1,
                        "footer/docLink2": this.state.docLink2,
                        "footer/docLink3": this.state.docLink3,
                        "footer/docLink5": this.state.docLink5,
                        "footer/btnLearnMore": this.state.btnLearnMore};
    const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
    let hasCachedText;
    axios.get(checkTextURL) // check if we need to send text over
    .then((response) => {
      hasCachedText = response.data;
    }, (error) => {
      hasCachedText = false;
    })
    .finally(() => {
      const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=footer`
                            : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=footer`;
      const data = (hasCachedText) ? null : titleDict;
      axios.post(translateURL, data)
      .then((response) => {
        this.setState( c => {
          c.translatedCompanyTitle = Parser(response.data["footer/companyTitle"]);
          c.translatedDocTitle = Parser(response.data["footer/docTitle"]);
          c.translatedDocLink1 = Parser(response.data["footer/docLink1"]);
          c.translatedDocLink2 = Parser(response.data["footer/docLink2"]);
          c.translatedDocLink3 = Parser(response.data["footer/docLink3"]);
          c.translatedDocLink5 = Parser(response.data["footer/docLink5"]);
          c.translatedBtnLearnMore = Parser(response.data["footer/btnLearnMore"]);
          c.message = "";
          c.errorTranslation = false;
          return c;
        });
      }, (error) => {
        this.setState( c => {
          c.message = "API error, could not translate";
          c.errorTranslation = true;
          return c;
        });
      })
    });
  }
  render() {
    let d = new Date();
    let currentYear = d.getFullYear();
    if(this.props.location.pathname.indexOf("/search") > -1) {
      return ("");
    }
    if (CommonUtil.IsHomePage(this.props.location)) {
      return (
        <div className="site-footer-copyright" style={{color: "#EEE"}}>
          <span style={{padding:"4px", borderRadius:"3px", backgroundColor: "#506D84"}}>
          {PremiumUtil.Translate("safetyPortal")} Copyright&copy; 2019-{currentYear} AppEsteem Corporation
          </span>
        </div>
      )
    }

    return (
      <div>
          <Segment inverted vertical style={{ padding: '5em 0em' }}>
                <Container textAlign='center'>
                  <Grid divided inverted stackable>
                    <Grid.Column width={3}>
                      <Header inverted as='h4' content={this.state.translatedCompanyTitle} />
                      <List link inverted size="mini">
                        <List.Item as='a'>AppEsteem Corporation</List.Item>
                        <List.Item>
                          655 156th Ave SE, Suite 275
                        </List.Item>
                        <List.Item>
                          Bellevue, WA 98007
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Header inverted as='h4' content={this.state.translatedDocTitle} />
                      <List link inverted size='mini'>
                        <List.Item>
                          <a href={"/" + CommonUtil.lang + "/termsofuse"}>{this.state.translatedDocLink1}</a>
                        </List.Item>
                        <List.Item>
                          <a href={"/" + CommonUtil.lang + "/privacypolicy"}>{this.state.translatedDocLink2}</a>
                        </List.Item>
                        <List.Item>
                          <a href={"/" + CommonUtil.lang + "/cookieusage"}>{this.state.translatedDocLink3}</a>
                        </List.Item>
                        <List.Item>
                          <a href={"/" + CommonUtil.lang + "/support/uninstallHow"}>{this.state.translatedDocLink4}</a>
                        </List.Item>
                        <List.Item>
                          <a as href={"/" + CommonUtil.lang + "/support"}>{this.state.translatedDocLink5}</a>
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                      <a href="/">
                        <Image src='/images/Blur_logo_white.png' className="site-footer-logo" />
                      </a>
                      <a href={!CommonUtil.IsEnglishLang() ? "/lp/" + this.translateLang + "?source=footer" : "/lp?source=footer"}><Button inverted className="site-footer-cta">{this.state.translatedBtnLearnMore}</Button></a>
                    </Grid.Column>
                  </Grid>
                </Container>
              </Segment>
              <div className="site-footer-copyright">
              {PremiumUtil.Translate("safetyPortal")} Copyright &copy; 2019-{currentYear} AppEsteem Corporation
                <div className="ui right floated button tiny research-button-footer">
                  <a href="/research">Research</a>
                </div>
              </div>
        </div>
    );
  }
}

export default withRouter(SiteFooter)
