import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Button } from 'semantic-ui-react';
import Home from './Home';
import SiteFooter from './components/SiteFooter.js';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import CookieUsage from './CookieUsage';
import PremiumPage from './PremiumPage';
import 'semantic-ui-less/semantic.less';
import './theme/custom.css';
import './theme/domains.css';
import Apps from './Apps';
import Covid from './Covid';
import {ReactGAInstance} from './connectors/ReactGAInstance.js';
import Contact from './Contact.js';
import SignupSuccess from './SignupSuccess';
import SiteHeader from './components/SiteHeader.js';
//import lp from './lp.js';
import Welcome from './Welcome.js';
import { PremiumUtil } from './utils/PremiumUtil';
import SideBar from './components/SideBar';
import { CommonUtil } from './utils/CommonUtil';
import { BlurMessages } from './BlurMessages';
import Parser from 'html-react-parser';
import AdSenseSettings from './utils/AdSenseSettings';
import Page404 from './components/Page404';

var constVars = require('./utils/Const');

class BlurApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false,
      loading: true,
      deviceType: ""
    }
    this.translateLang = CommonUtil.lang;
    this.count = 1;
    this.advertisementStr = Parser(CommonUtil.findHeaderString('Advertisement'));
  }
  componentDidMount() {    
    window.addEventListener('resize', this.findDeviceType.bind(this));
    this.findDeviceType();  
    this.translateLang = CommonUtil.lang;
    this.translate();
    ReactGAInstance.PageView(this.props.location.pathname);
    PremiumUtil.PingExtension().then().catch().then(() => {
      this.setState(c=> {
        c.loading = false;
        return c;
      })
    });
  }
  componentDidUpdate(prevProps) {
    if(this.props.location !== prevProps.location) {
      //we will set updated to know if the url is landing url or result of navigation inside app
      this.translateLang = CommonUtil.lang;
      this.translate();
      this.setState(c => {
        c.updated = true;
        return c;
      });
      ReactGAInstance.PageView(this.props.location.pathname)
    };
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.findDeviceType.bind(this));
  }
  findDeviceType() {
    this.setState(c=> {
      c.deviceType = AdSenseSettings.getDeviceType();
      return c;
    });
  }
  translate() {
    BlurMessages.init(this.translateLang).then(() => {
      BlurMessages.translate();
    }).catch((err) => {
        //do nothing right now
    });
  }
  setLanguage(match) {
    CommonUtil.SetLanguage(match.params.lang);
    if(this.translateLang !== CommonUtil.lang) {
      this.translateLang = CommonUtil.lang;
      this.setState(c => {
        c.updated = true;
        return c;
      });
    }
  }
  renderRoutes(match, location) {
    let lang = match.params.lang;
    let subLang = match.params.lang;
    if(lang && lang.length === 5) {
      subLang = lang.substr(0, 2);
    }
    if(constVars.languages.some(s => (s.code.toLowerCase() === lang.toLowerCase() || s.code.toLowerCase() === subLang.toLowerCase()))) {
      this.setLanguage(match);
      return (
        <>
        <Switch>
          <Route path="/:lang/" exact render={(matchProps) =><Home {...matchProps} {...this.props}  updated={this.state.updated}/> }  />
          <Route path="/:lang/signupsuccess/:subscription/:invoice/:customer/:email/:fname/:lname" component={SignupSuccess} />
          <Route path="/:lang/signupsuccess/:subscription/:invoice/:customer/:email/:fname" component={SignupSuccess} />
          <Route path="/:lang/signupsuccess/:subscription/:invoice/:customer/:email" component={SignupSuccess} />
          <Route path="/:lang/signupsuccess/:subscription" component={SignupSuccess} />
          <Route path="/:lang/privacypolicy" render={(matchProps) =><PrivacyPolicy {...matchProps} {...this.props} /> } />
          <Route path="/:lang/termsofuse" render={(matchProps) =><TermsOfUse {...matchProps} {...this.props} /> } />
          <Route path="/:lang/pagepremium" component={PremiumPage} />
          <Route path="/:lang/cookieusage" render={(matchProps) =><CookieUsage {...matchProps} {...this.props} /> } />
          <Route path="/:lang/apps/:appId" render={(matchProps) =><Apps {...this.props} {...matchProps} {...matchProps.match.params} /> } />
          <Route path="/:lang/contact/:contactType/:problem" render={(matchProps) =><Contact {...this.props} {...matchProps} {...matchProps.match.params} /> } />
          <Route path="/:lang/contact/:contactType" render={(matchProps) =><Contact {...matchProps} {...this.props} {...matchProps.match.params} /> } />
          <Route path="/:lang/contact" render={(matchProps) =><Contact {...matchProps} {...this.props} /> } />
          <Route path="/:lang/support/:problem" render={(matchProps) =><Contact {...matchProps} {...this.props} {...matchProps.match.params} contactType="support" /> } />
          <Route path="/:lang/support" render={(matchProps) =><Contact {...matchProps} {...this.props}  contactType="support" /> } />
          <Route path="/:lang/feedback/:problem" render={(matchProps) =><Contact {...matchProps} {...this.props} {...matchProps.match.params} contactType="feedback" /> } />
          <Route path="/:lang/feedback" render={(matchProps) =><Contact {...matchProps} {...this.props}  contactType="feedback" /> } />
          <Route path="/:lang/uninstalled" render={(matchProps) =><Contact {...matchProps} {...this.props}  contactType="uninstall" /> } />
          <Route path="/:lang/covid" component={Covid} />
          <Route path="/:lang/welcome" render={(matchProps) =><Welcome {...matchProps} {...this.props} /> } />
          <Route path={["/:lang/domains/:domain/:country", "/:lang/domains/:domain", "/:lang/domains"]} render={(matchProps) =><Page404 {...this.props} {...matchProps} {...matchProps.match.params} /> } />
          <Route path={["/:lang/querykey/:querykey/:country", "/:lang/adnetwork/:adnetwork/:country", "/:lang/download/:download/:country", "/:lang/referrer/:referrer/:country"]} render={(matchProps) =><Page404 {...this.props} {...matchProps} {...matchProps.match.params} /> } />
          <Route path={["/:lang/querykey/:querykey", "/:lang/adnetwork/:adnetwork", "/:lang/download/:download", "/:lang/referrer/:referrer"]} render={(matchProps) =><Page404 {...this.props} {...matchProps} {...matchProps.match.params} /> } />
          <Route path={["/:lang/ranks/:country", "/:lang/ranks"]} render={(matchProps) =><Page404 {...this.props} {...matchProps} {...matchProps.match.params} /> } />
        </Switch>
        </>
      )
    } else {
      return this.renderRedirect(match, location);
    } 
  }
  renderRedirect(match, location) {
    return <Redirect to={"/" + CommonUtil.lang + location.pathname.replace(`/${CommonUtil.lang}`, "") + location.search}/>
  }
  // ToDo: removing this.renderSiteHeader() fixes continous render problem
  render() {
    //making sure we got the cookie first thru pingExtension call in componentDidMount
    if (this.state.loading) {
      return ( 
        <div className="Site">
          <div className="Site-content"></div>
        </div>
      )
    }

    return (
      <div className={CommonUtil.IsHomePage(this.props.location) ? "Site-home" : "Site"}>
        <SiteHeader {...this.props}/>
        <div className={CommonUtil.IsHomePage(this.props.location) ? "home-page-content" : "Site-content"}>
          <div className={CommonUtil.IsHomePage(this.props.location) ? "" : "page-content"}>
            <Switch>
              <Route path="/:lang([a-z]{2}|[a-z]{2}\-[a-z]{2}|fil)/">
                {({match, location}) => this.renderRoutes(match, location)}
              </Route>
              <Route path="*">
                {({match, location}) => this.renderRedirect(match, location)}
              </Route>
            </Switch>
          </div>
          <SideBar {...this.props} deviceType={this.state.deviceType}/>
        </div>
        <SiteFooter {...this.props} />
      </div>
    );
  }
}
class Index extends Component {
  render() {

    //Attempt to preload an image into cache (async)
    /*
    let currImg = PremiumUtil.selectCurrentBgImage();
    PremiumUtil.preloadBgImage("/images/home/" + currImg + ".jpg").catch(() =>
        PremiumUtil.preloadBgImage("/images/home/" + currImg + ".png").catch(() =>
            PremiumUtil.preloadBgImage("/images/home/bg-1-1.jpg")
        )
    );
    */
    return (
      <Router>
        <div hidden id="BlurManage">
          <Button fluid color='blue' 
                  href="javascript:void(0)" 
                  data-cb-type="portal" 
                  data-cb-ga-enabled ="true" />
        </div>
        <Route component={BlurApp} />
      </Router>
    );
  }
}
ReactGAInstance.Initialize();
ReactDOM.render(<Index />, document.getElementById('root'));
