import React, { Component } from 'react';
import Parser from 'html-react-parser';
import { Settings } from './Settings';
import axios from 'axios';
import { CommonUtil } from './utils/CommonUtil';

class CookieUsage extends Component {
  constructor(props) {
    super(props);
    const header = 
      `<div class="page-header">
        <h1 class="ui header">Cookie Usage</h1>
      </div>
      <p><i><small>Updated: October 17, 2019</small></i></p>`;
    const body = 
      `<br/>
      <p>
        <strong>What are cookies?</strong>
      </p>
      <p>
        We use the term cookie to describe cookies and similar technologies such as tags and pixels. 
        Cookies are small data files that websites place on your computer, laptop or mobile device.
      </p>
      <br/>
      <p>
        <strong>Our use of cookies</strong>
      </p>
      <p>
        We use different types of cookies for different things, such as:
        <ul>
          <li>Analyzing how you use Blur.live</li>
          <li>Giving you a better, more personalized experience</li>
          <li>Managing payment</li>
          <li>Delivering relevant online advertising</li>
          <li>Recognizing when you’ve signed in</li>
        </ul>
      </p>
      <br/>
      <p>
        <strong>List of cookies we use</strong>
      </p>
      <table class="ui celled collapsing table">
        <thead>
          <tr>
            <th>Cookie name</th>
            <th>What it’s for</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="notranslate">blurId<br/>.blur.live.session<br/>__blur_opt_value</td>
            <td>Cookies used for session and privacy management</td>
          </tr>
          <tr>
            <td class="notranslate">__cfduid<br/>buySAFEUID</td>
            <td>Cookies used for security and safety (Cloudflare and BuySafe)</td>
          </tr>
          <tr>
            <td class="notranslate">__stripe_mid</td>
            <td>Cookie used to manage payment</td>
          </tr>
          <tr>
            <td class="notranslate">_ga<br/>_gaexp<br/>_gat_gtag_UA_80789321_3<br/>_gid</td>
            <td>Cookies used for Google Analytics to measure how users interact with website content</td>
          </tr>
          <tr>
            <td class="notranslate">DSID<br/>_fbp<br/>id<br/>permutive-id<br/>permutive-session<br/>_uetsid<br/>_uetvid</td>
            <td>Cookies used for advertising</td>
          </tr>
        </tbody>
      </table>`;
      const englishCookieUsage = `<div>
          ${header}
          ${body}
      </div>`;
    let translatedCookieUsage = "";
    this.state = {
      translatedCookieUsage,
      englishCookieUsage,
      header,
      body,
      loading: true,
    }
    this.translateLang = CommonUtil.lang;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTranslation();
  }
  componentDidUpdate(prevProps) {
    if (this.translateLang !== CommonUtil.lang) {
        this.translateLang = CommonUtil.lang;
        this.getTranslation();
    }
  }
  getTranslation() {
    if (CommonUtil.IsEnglishLang()) {
      this.setState( c => {
        c.loading = false;
        return c;
      });
      return;
    }
    const titleListString = "cookieusage/header@cookieusage/body";
    const titleDict = {"cookieusage/header": this.state.header, "cookieusage/body":this.state.body};
    const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
    let hasCachedText;
    axios.get(checkTextURL) // check if we need to send text over
    .then((response) => {
      hasCachedText = response.data;
    }, (error) => {
      hasCachedText = false;
    })
    .finally(() => {
      const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=cookieusage`
                            : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=cookieusage`;
      const data = (hasCachedText) ? null : titleDict;
      axios.post(translateURL, data)
      .then((response) => {
        let translatedCookieUsage = `<div><Container>
                                ${response.data["cookieusage/header"]}
                                ${response.data["cookieusage/body"]}
                              </Container></div>`;
        this.setState({
          translatedCookieUsage,
          loading: false,
        });
      }, (error) => {
        this.setState({
          translatedCookieUsage: "API error, could not translate",
          loading: false,
        });
      })
    });
  }
  render() {
    const englishCookieUsageHTML = Parser(this.state.englishCookieUsage);
    const translatedCookieUsageHTML = Parser(this.state.translatedCookieUsage);
    return (
      (!CommonUtil.IsEnglishLang())
      ? <div>
            {(!this.state.loading) 
                ? <div>{translatedCookieUsageHTML}</div> 
                : <div style={{display:"flex", "justify-content":"center"}}>
                    <div className="dimmable" style={{margin:"0 auto", width:"100px", height:"100px", top:"50%", position:"fixed"}}>
                        <div className="ui active inverted dimmer">
                            <div className="ui massive text loader" ></div>
                        </div>
                    </div>
                </div>}
        </div>
      : <div>{englishCookieUsageHTML}</div>
    );
  }
}

export default CookieUsage;