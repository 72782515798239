import React, { Component } from 'react';
import {ReactGAInstance} from '../connectors/ReactGAInstance.js';
import HomeSideBar from './HomeSideBar.js';
import HomeBlogs from './HomeBlogs.js';
import { Settings } from '../Settings';
import axios from 'axios';
import Parser from 'html-react-parser';
import '../theme/sidebar.css';
import { CommonUtil } from '../utils/CommonUtil.js';

class SideBar extends Component {
  constructor(props) {
    super(props);
    const blogsHeading = CommonUtil.findHeaderString('Blog Header');

    this.state = {
      updated: false,
      isLoading: true,
      translatedBlogsHeading: Parser(blogsHeading),
      blogsHeading,
      message: "",
      errorTranslation: true
    }
    this.translateLang = CommonUtil.lang;
    this.parsedUrl = new URL(window.location.href);
  }
  componentDidMount() {
    if (!CommonUtil.IsHomePage(this.props.location)) {
      ReactGAInstance.PageView(this.parsedUrl.pathname);
      this.getTranslation();
    }
  }
  componentDidUpdate(prevProps) {
    if(this.translateLang !== CommonUtil.lang) {
      this.translateLang = CommonUtil.lang;
      this.homeUrl = "/" + this.translateLang + "/";
      this.getTranslation();      
    }
  }
  componentWillUnmount() {
  }
  parseEnTranslation() {
    this.setState( c => {
        c.translatedBlogsHeading = Parser(this.state.blogsHeading);
    });
  }
  getTranslation() {
    if (CommonUtil.IsEnglishLang()) {
      this.parseEnTranslation();
      return;
    }

    const titleListString = "search/blogsHeading@search";
    const titleDict = {
                       "search/blogsHeading": this.state.blogsHeading
    };
                        
    const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
    let hasCachedText;
    axios.get(checkTextURL) // check if we need to send text over
    .then((response) => {
      hasCachedText = response.data;
    }, (error) => {
      hasCachedText = false;
    })
    .finally(() => {
      const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=sidebar`
                            : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=sidebar`;
      const data = (hasCachedText) ? null : titleDict;
      axios.post(translateURL, data)
      .then((response) => {
        this.setState( c => {
          c.translatedBlogsHeading = Parser(response.data["search/blogsHeading"]);
          c.message = "";
          c.errorTranslation = false;
          return c;
        });
      }, (error) => {
        this.setState( c => {
          c.message = "API error, could not translate";
          c.errorTranslation = true;
          return c;
        });
      })
    });
  }

  render() {
    if (CommonUtil.IsHomePage(this.props.location)) {
      return ("");
    }
    return (
      <div className="sidebar-wrap">
        <div className="sidebar-panel">
          <div>
              <HomeSideBar {...this.props}  showAd={this.props.deviceType === "desktop"} showLogo={false} hideBlurInfo={true}/>
          </div>
          <div className="sidebar-cards-wrap">
            <div id="topBlogsId" className="home-card blog-card">
                <HomeBlogs {...this.props} usedLang={this.translateLang}  topsData={this.state.topsData} topsDataCount={5} translatedHeading={this.state.translatedBlogsHeading}/>
            </div>
          </div> 
        </div>
      </div>        
    );
  }
}

export default SideBar
