import React, { Component } from 'react';
import axios from 'axios';
import { Settings } from '../Settings';
import Parser from 'html-react-parser';
import { CommonUtil } from '../utils/CommonUtil';
import SidebarAutoComplete from './SidebarAutoComplete';
import { PremiumUtil } from '../utils/PremiumUtil';
import sanityClient from "../blog/client.js";

class HomeBlogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            blogTitlesAndUrls: [],
            translatedBlogTitlesAndUrls: [],
            message: "",
            errorTranslation: true,
        };
        this.blogTitles = [];
        this.blogUrls = [];
        this.translateLang = CommonUtil.lang;
    }

    componentDidMount() {
        this.checkAndLoadBlogs();
    }
    componentDidUpdate(prevProps) {
        if(this.translateLang !== CommonUtil.lang) {
            this.translateLang = CommonUtil.lang;
            this.checkAndLoadBlogs();      
        }
        if(this.props.blogTitlesAndUrls !== this.props.blogTitlesAndUrls) {
            this.setState(c => {
                c.blogTitlesAndUrls = this.props.blogTitlesAndUrls;
                return c;
            }, this.getTranslation.bind(this));
        }
    }
    checkAndLoadBlogs() {
        if(this.props.noApiCall) {
            //[TODO] fill with passed data
            this.setState(c => {
                c.blogTitlesAndUrls = this.props.blogTitlesAndUrls;
                return c;
            }, this.getTranslation.bind(this))
        } else {
            this.getBlogLinksAndUrls();
        }

    }
    getBlogLinksAndUrls() {
        let lang = CommonUtil.lang.split("-")[0];
        sanityClient
          .fetch(
            `*[_type == "post"]{
            "title": title["${lang}"],
            slug,
            mainImage{
              asset->{
              _id,
              url
            }
          },
          authors[]{
            author->{
              image,
              name
            }
          }
        }`
        ).then((data) => {
            let temp = []
            if(data && data.length > 0) {
                for (var ix in data) {
                    if(data[ix].title && data[ix].slug && data[ix].slug.current) {
                        temp.push({'title': data[ix].title, 'url':"/"+ CommonUtil.lang + "/blog/" + data[ix].slug.current});
                    }
                }
            }
            this.setState(c => {
                c.blogTitlesAndUrls = temp;
                c.translatedBlogTitlesAndUrls = temp;
                return c;
            }, this.getTranslation.bind(this));
        }).catch(() => {});

    }
    getTranslation() {
        if (CommonUtil.IsEnglishLang() && !this.props.noApiCall) {
            return;
        }
        if(this.props.noApiCall) {
            this.setState(c => {
                for (let i = 0; i < this.state.blogTitlesAndUrls.length; i++) {
                    c.translatedBlogTitlesAndUrls[i] = {title: PremiumUtil.Translate(this.state.blogTitlesAndUrls[i].title, this.state.blogTitlesAndUrls[i].title), url: this.state.blogTitlesAndUrls[i].url}
                }
                return c;
            });
            //dont do any thing after this
            return;
        }
    }
    renderBlogItem(item, key) {
        return (
            <div className="card-apps-link" key={key}>
                <i className="list alternate outline icon"></i>
                <a href={encodeURI(item.url)} target="_top">{item.title || item.item}</a> <br/>
            </div>
        )
    }
    render() {
        let blogTitlesAndUrls = (CommonUtil.IsEnglishLang() && !this.props.noApiCall) ? this.state.blogTitlesAndUrls : this.state.translatedBlogTitlesAndUrls;

        if(this.state.isLoading || !blogTitlesAndUrls || blogTitlesAndUrls.length === 0) {
            //dont show loading
            return ("");
        } else { 
            return (
                <div>
                    <div className={`card-header ${this.props.hideHeader ? "displayNone" : "" }`}>
                        <strong>{this.props.translatedHeading}</strong>
                    </div>
                    <SidebarAutoComplete
                        defaultItems = {blogTitlesAndUrls}
                        itemsPerPage = {this.props.topsDataCount}
                        getItemValue = {(item) => item.title || item.item}
                        renderItem = {this.renderBlogItem}
                        searchType = 'blogs'
                        apiDisabled = {true}
                    />
                </div>
            )
        }
    }
}

export default HomeBlogs
