import React, { Component } from 'react';
import { Container, Grid, Header, Form, Message, Image } from 'semantic-ui-react';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import ContactForm from './components/ContactForm.js';
import Axios from 'axios';
import validator from 'validator';
import { Settings } from './Settings';
import PremiumSignupPopup from './premium/PremiumSignupPopup.js';
import { PremiumUtil } from './utils/PremiumUtil.js';
import Parser from 'html-react-parser';
import {MediaContextProvider, mediaStyles, Media} from './common/Const'
import { CommonUtil } from './utils/CommonUtil.js';


class Contact extends Component {
    constructor(props) {
      super(props);
      this.state = {
        translations: {
          heroContactTitle: {isHtml: false, enUs: `Contact Us`, translation: `Contact Us`},
          heroFeedbackTitle: {isHtml: false, enUs: `We'd love your feedback!`, translation: `We'd love your feedback!`},
          heroSupportTitle: {isHtml: true, enUs: `<div>Thanks for installing this browser extension<br/>Got a problem? We can help!</div>`, translation: Parser(`<div>Thanks for installing this browser extension<br/>Got a problem? We can help!</div>`)},
          heroUninstallTitle: {isHtml: false, enUs: `Uninstall instructions`, translation: `Uninstall instructions`},
          heroCancelTitle: {isHtml: false, enUs: `Subscription cancelation instructions`, translation: `Subscription cancelation instructions`},
          heroUninstalledWhyTitle: {isHtml: false, enUs: `Browser extension has been uninstalled`, translation: `Browser extension has been uninstalled`},
          heroSupportSubtitle: {isHtml: false, enUs: `Please choose an option bellow:`, translation: `Please choose an option bellow:`},
          heroUninstallSubtitle: {isHtml: false, enUs: `Please follow the steps below to uninstall browser extension:`, translation: `Please follow the steps below to uninstall browser extension:`},
          heroCancelSubtitle: {isHtml: false, enUs: `Please follow the steps below to cancel the subscription:`, translation: `Please follow the steps below to cancel the subscription:`},
          heroProblemSubtitle: {isHtml: false, enUs: `Please fill in following fields:`, translation: `Please fill in following fields:`},
          heroUninstalledWhySubtitle1_1: {isHtml: true, enUs: `<td>Did you uninstall <b>browser extension</b> by accident?</td>`, translation: Parser(`<td>Did you uninstall <b>browser extension</b> by accident?</td>`)},
          heroUninstalledWhySubtitle1_2: {isHtml: true, enUs: `Reinstall now`, translation: `Reinstall now`},
          heroUninstalledWhySubtitle2_1: {isHtml: false, enUs: `Are you a premium customer?`, translation: `Are you a premium customer?`},
          heroUninstalledWhySubtitle2_2: {isHtml: false, enUs: `Manage my subscription`, translation: `Manage my subscription`},
          problemPageNotBlur: {isHtml: false, enUs: `Page is not being blurred`, translation: `Page is not being blurred`},
          problemAdNotBlur: {isHtml: false, enUs: `Ad is not being blurred`, translation: `Ad is not being blurred`},
          problemSlowComputer: {isHtml: false, enUs: `Browser extension slows down my computer`, translation: `Browser extension slows down my computer`},
          problemRefund: {isHtml: false, enUs: `I want a refund`, translation: `I want a refund`},
          problemUninstallHow: {isHtml: false, enUs: `I want to uninstall this browser extension`, translation: `I want to uninstall this browser extension`},
          problemCancelHow: {isHtml: false, enUs: `I want to cancel my subscription`, translation: `I want to cancel my subscription`},
          problemOther: {isHtml: false, enUs: `Other`, translation: `Other`},
          backText: {isHtml: false, enUs: `Back`, translation: `Back`},
          problemDescEmail: {isHtml: false, enUs: `please enter your email`, translation: `please enter your email`},
          problemDescUrl: {isHtml: false, enUs: `please enter the URL of the page`, translation: `please enter the URL of the page`},
          problemDescMoreInfo: {isHtml: false, enUs: `please provide additional information to explain your problem`, translation: `please provide additional information to explain your problem`},
          problemDescComments: {isHtml: false, enUs: `Comments`, translation: `Comments`},
          submitText: {isHtml: false, enUs: `Submit`, translation: `Submit`},
          uninstallStep1: {isHtml: false, enUs: `Right click on the icon with glasses in the upper right-hand corner of your browser.`, translation: `Right click on the icon with glasses in the upper right-hand corner of your browser.`},
          uninstallStep2_1: {isHtml: false, enUs: `In opened drop down menu, choose the`, translation: `In opened drop down menu, choose the`},
          uninstallStep2_2: {isHtml: false, enUs: `option.`, translation: `option.`},
          uninstallStep3: {isHtml: false, enUs: `In opened popup, click on the “Remove” button.`, translation: `In opened popup, click on the “Remove” button.`},
          stepText: {isHtml: false, enUs: `Step`, translation: `Step`},
          cancelPremiumStep1: {isHtml: false, enUs: `Open Blur.live extension popup and click on the "Manage" link.`, translation: `Open Blur.live extension popup and click on the "Manage" link.`},
          cancelPremiumStep2: {isHtml: false, enUs: `The Manage Subscription popup will be opened. Click on the "Manage Payment" link at the bottom part of popup.`, translation: `The Manage Subscription popup will be opened. Click on the "Manage Payment" link at the bottom part of popup.`},
          cancelPremiumStep3: {isHtml: false, enUs: `Enter the email you subscribed with and click on the Continue button.`, translation: `Enter the email you subscribed with and click on the Continue button.`},
          cancelPremiumStep4: {isHtml: false, enUs: `The verification code will be sent to your email with subject "Your One Time Password (OTP)". Copy verification code, enter it to the prompt, and click on the Login button.`, translation: `The verification code will be sent to your email with subject "Your One Time Password (OTP)". Copy verification code, enter it to the prompt, and click on the Login button.`},
          cancelPremiumStep5: {isHtml: false, enUs: `The popup will be updated with Manage Subscription view. Chose the plan you want to cancel.`, translation: `The popup will be updated with Manage Subscription view. Chose the plan you want to cancel.`},
          cancelPremiumStep6: {isHtml: false, enUs: `Click on the Cancel Subscription button.`, translation: `Click on the Cancel Subscription button.`},
          cancelPremiumStep7: {isHtml: false, enUs: `Enter your reason of cancellation and click on the Confirm Cancellation button.`, translation: `Enter your reason of cancellation and click on the Confirm Cancellation button.`},
          cancelPremiumStep8: {isHtml: false, enUs: `You will see confirmation of your cancellation.`, translation: `You will see confirmation of your cancellation.`},
          heroUninstallWhyTitle: {isHtml: false, enUs: `We're sorry to see you go! Can you tell us why you're uninstalling?`, translation: `We're sorry to see you go! Can you tell us why you're uninstalling?`},
          problemSlowBrowser: {isHtml: false, enUs: `Browser extension slowed down my browser`, translation: `Browser extension slowed down my browser`},
          problemNeedAdBlocker: {isHtml: false, enUs: `What I really want is an ad blocker`, translation: `What I really want is an ad blocker`},
          problemDontLikeSearchBlur: {isHtml: false, enUs: `I don't like having my search results blurred`, translation: `I don't like having my search results blurred`},
          problemUnawareInstallation: {isHtml: false, enUs: `I don't know how browser extension got onto my computer`, translation: `I don't know how browser extension got onto my computer`},
          problemDontLikeAdBlur: {isHtml: false, enUs: `I don't like having my ads blurred`, translation: `I don't like having my ads blurred`}
        },
        currentStep: 1,
        data:  {email: "",
                contactType: null,
                problem: null,
                url: "",
                comments: "",
                captcha: "",
                uninstallReasons: {}
              },
        currStage: null,
        isLoading: false,
        loadingMessage: "Sending query",
        browserName: "chrome",
        removeMessage: "Remove from Chrome ...",
        translationMessage: "",
        errorTranslation: true
      };
      this.translateLang = CommonUtil.lang;
      this.myCaptcha = "";
      this.recaptchaSitekey = Settings.RecaptchaSitekey;
    }
    componentDidMount(){
      window.scrollTo(0, 0);
      loadReCaptcha(this.recaptchaSitekey);
      this.setStage();
      //check for passed params
      if(this.props.contactType === "uninstall") {
        PremiumUtil.SendAnalytics("Install", "UnInstalled", "Blur.live UnInstalled Page")
      }
      this.getTranslation();
    }
    componentDidUpdate(prevProps) {
      if(this.props.contactType != this.state.data.contactType) {
        this.setStage();
      } else if(this.props.problem != this.state.data.problem) {
        this.setStage();
      }
      // if translation language got changed in header, then set translation language and translate
      if(this.translateLang !== CommonUtil.lang) {
        this.translateLang = CommonUtil.lang;
        this.getTranslation();      
      }
    }
    getEnTranslation() {
      const keys = Object.keys(this.state.translations); // get array of translations keys
      this.setState( c => {
        for(let i=0; i < keys.length; i++) {
          c.translations[keys[i]].translation =Parser(c.translations[keys[i]].enUs);
        }
        return c;
      });
    }
    getTranslation() {
      if (CommonUtil.IsEnglishLang()) {
        this.setState( c => {
          c.translationMessage = "";
          c.errorTranslation = false;
          return c;
        }, this.getEnTranslation.bind(this));
        return;
      }
      const keys = Object.keys(this.state.translations);
      let titleListString = "";
      let titleDict = {};
      for(let i=0; i < keys.length; i++) {
        // prepare titleListString = contact/heroFeedbackTitle@contact/heroSupportTitle...
        // prepare titleDict = {"welcome/heroTitle": <enUs translation>, ...
        titleListString += "contact/" + keys[i];
        titleDict["contact/" + keys[i]] = this.state.translations[keys[i]].enUs;
        if (i != keys.length - 1) {
          titleListString += "@";
        }
      }
      const checkTextURL = `${Settings.CheckTextURL}?requestTitlesString=${titleListString}`;
      let hasCachedText;
      Axios.get(checkTextURL) // check if we need to send text over
      .then((response) => {
        hasCachedText = response.data;
      }, (error) => {
        hasCachedText = false;
      })
      .finally(() => {
        const translateURL = (hasCachedText) ? `${Settings.TranslationWithoutTextURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&titles=${titleListString}&cachePage=contact`
                              : `${Settings.TranslationURL}?fromLang=en&toLang=${this.translateLang}&isHTML=true&cachePage=contact`;
        const data = (hasCachedText) ? null : titleDict;
        Axios.post(translateURL, data)
        .then((response) => {
          this.setState( c => {
            const keys = Object.keys(this.state.translations);
            for(let i=0; i < keys.length; i++) {
              c.translations[keys[i]].translation = Parser(response.data["contact/" + keys[i]]);
            }
            c.translationMessage = "";
            c.errorTranslation = false;
            return c;
          });
        }, (error) => {
          this.setState( c => {
            c.translationMessage = "API error, could not translate";
            c.errorTranslation = true;
            return c;
          });
        })
      });
    }
    submitData() {
      //check the value
      this.setState(c=> {
        c.isLoading = true;
        return c;
      })
      var contactJson = {
        email: this.state.data.email,
        contactType: this.state.data.contactType,
        problem: this.state.data.problem,
        url: this.state.data.url,
        comments: this.state.data.comments,
        captcha: this.state.data.captcha,
        uninstallReasons: this.state.data.uninstallReasons
      }
      var data = JSON.stringify(contactJson);
      Axios.defaults.withCredentials = true;
      Axios.post(Settings.ContactUrl, data, {headers: { "Content-Type": "application/json; charset=utf-8"}}).then(resp=> {
        //not sure if we need to validate recaptcha, if it fails that mean this malicious activity
        this.resetFormData();
        this.setState(c=> {
          c.isLoading = false;
          c.messageState = "success";
          return c;
        });
      }).catch(e => {
        //not sure if we need to validate recaptcha, if it fails that mean this malicious activity
        this.setState(c=> {
          c.isLoading = false;
          c.messageState = "error";
          return c;
        });
      });
    }    
    resetFormData() {
      this.setState(c=> {
        c.data.email = "";
        c.data.url = "";
        c.data.comments = "";
        c.data.uninstallReasons = {};
        return c;
      });
    }
    setStage() {
      this.setState(c => {
        c.messageState = "none";
        c.currStage = "contactType";
        c.data["contactType"] = this.props.contactType;
        if(this.props.contactType) {
          c.currStage = "problemType";
          c.data["problem"] = this.props.problem;
          if(this.props.problem) {
            c.currStage = "problemDesc";
          }
          if(this.props.problem === "uninstallHow") {
            c.isLoading = true;
            c.loadingMessage = "Loading ...";
          }
        }
        return c;
      });

      //in case of install how
      if(this.props.problem === "uninstallHow") {
        var browserName = "chrome";
        var removeMessage = "";
        PremiumUtil.CallWhatAmI().then(resp => {
					var browser = (resp && resp.browser) ? resp.browser : "";
					if (browser.startsWith("Chrome")) {
            browserName = "chrome";
            removeMessage = "Remove from Chrome ...";
					} else if (browser.startsWith("EdgeChromium")) {
            browserName = "edge";
            removeMessage = "Remove from Microsoft Edge ...";
					} else if (browser.startsWith("Firefox")) {
            browserName = "firefox";
            removeMessage = "Remove Extension ...";
					}
        }).catch((err) => {}).then(() => {
          this.setState(c=> {
            c.browserName = browserName;
            c.removeMessage = removeMessage;
            c.isLoading = false;
            return c;
          })
        })
      }
    }
    getTranslatedText(text) {
      if (this.state.translations && this.state.translations[text]) {
        return this.state.translations[text].translation;
      }
      return "";
    }
    getCurrFields = () => {
      if(!this.state.currStage) {
        return [];
      }
      let stages = {
        contactType: [
          {type: "action", show: (this.state.data.contactType !== "uninstall"), actions: [
            {id: "feedback", clsName:"twitter", content: "I am here to provide feedback", onClick: this.oncontactTypeClick.bind(this), show: true, align: "left", width: 12, validate: false}, 
          ]},
          {type: "action", show: (this.state.data.contactType !== "uninstall"), actions: [
            {id: "support", type: "action", clsName:"facebook", content: "I want to contact support", onClick: this.oncontactTypeClick.bind(this), show: true, align: "left", width: 12, validate: false}
          ]}
        ],
        problemType: [
          {type: "choice",  show: (this.state.data.contactType != "uninstall"), choices: [
            {id:"pageNotBlur", imagesrc:"/images/Blur_support_q_page.png", description: this.getTranslatedText("problemPageNotBlur"), onClick: this.onProblemClick.bind(this), show: true},
            {id:"adNotBlur", imagesrc:"/images/Blur_support_q_ad.png", description: this.getTranslatedText("problemAdNotBlur"), onClick: this.onProblemClick.bind(this), show: true},
            {id:"slowComputer", imagesrc:"/images/Blur_support_q_comp.png", description: this.getTranslatedText("problemSlowComputer"), onClick: this.onProblemClick.bind(this), show: true},
            {id:"refund", imagesrc:"/images/Blur_support_q_refund.png", description: this.getTranslatedText("problemRefund"), onClick: this.onProblemClick.bind(this), show: (this.state.data["contactType"] === "support")},
            {id:"uninstallHow", imagesrc:"/images/Blur_support_q_uninstall.png", description: this.getTranslatedText("problemUninstallHow"), onClick: this.onProblemClick.bind(this), show: (this.state.data["contactType"] === "support")},
            {id:"cancelHow", imagesrc:"/images/Blur_support_q_cancel.png", description: this.getTranslatedText("problemCancelHow"), onClick: this.onProblemClick.bind(this), show: (this.state.data["contactType"] === "support")},
            {id:"other", imagesrc:"/images/Blur_support_q_other.png", description: this.getTranslatedText("problemOther"), onClick: this.onProblemClick.bind(this), show: true},
          ]},
          {type: "checkbox", id:"slowBrowser", checked:this.state.data.uninstallReasons["slowBrowser"], label:this.getTranslatedText("problemSlowBrowser"), show: (this.state.data.contactType === "uninstall"), onClick:this.uninstallReasonClick.bind(this)},
          {type: "checkbox", id:"needAdBlocker", checked:this.state.data.uninstallReasons["needAdBlocker"], label:this.getTranslatedText("problemNeedAdBlocker"), show: (this.state.data.contactType === "uninstall"), onClick:this.uninstallReasonClick.bind(this)},
          {type: "checkbox", id:"dontLikeSearchBlur", checked:this.state.data.uninstallReasons["dontLikeSearchBlur"], label:this.getTranslatedText("problemDontLikeSearchBlur"), show: (this.state.data.contactType === "uninstall"), onClick:this.uninstallReasonClick.bind(this)},
          {type: "checkbox", id:"UnawareInstallation", checked:this.state.data.uninstallReasons["UnawareInstallation"], label:this.getTranslatedText("problemUnawareInstallation"), show: (this.state.data.contactType === "uninstall"), onClick:this.uninstallReasonClick.bind(this)},
          {type: "checkbox", id:"dontLikeAdBlur", checked:this.state.data.uninstallReasons["dontLikeAdBlur"], label:this.getTranslatedText("problemDontLikeAdBlur"), show: (this.state.data.contactType === "uninstall"), onClick:this.uninstallReasonClick.bind(this)},
          {type: "checkbox", id:"Other", checked:this.state.data.uninstallReasons["Other"], label:this.getTranslatedText("problemOther"), show: (this.state.data.contactType === "uninstall"), onClick:this.uninstallReasonClick.bind(this)},
          {type: "textArea", id: "comments", label: this.getTranslatedText("problemDescComments"), placeholder: "", required: false, 
          show: (this.state.data.contactType === "uninstall"), value: this.state.data.comments, onChange: this.formChange.bind(this)},
          {type: "action", show: (this.state.data.contactType !== "uninstall"), actions: [
            {id: "back", clsName:"default", content: this.getTranslatedText("backText"), onClick: this.onActionClick.bind(this), show: true, align: "right", width: 13}
          ]},
          {type: "action", show: (this.state.data.contactType === "uninstall"), actions: [
            {id: "submituninstall", clsName:"primary", content: this.getTranslatedText("submitText"), onClick: this.onActionClick.bind(this), show: true, align: "left", width: 4}
          ]}
        ],
        problemDesc: [
            {type: "text", label: this.getTranslatedText("problemDescEmail"), placeholder: "", required: true, 
             show: (this.state.data["contactType"] === "support" && this.state.data["problem"] !== "uninstallHow" && this.state.data["problem"] !== "cancelHow"), 
             id: "email", ctype: "email",value: this.state.data.email, onChange: this.formChange.bind(this)},
            {type: "text", label: this.getTranslatedText("problemDescUrl"), placeholder: "", required: true, 
             show: (this.state.data["problem"] === "pageNotBlur" || this.state.data["problem"] === "adNotBlur"), 
             id: "url", ctype: "url", value: this.state.data.url, onChange: this.formChange.bind(this)},
            {type: "textArea", label: this.getTranslatedText("problemDescMoreInfo"), placeholder: "", required: false, 
             show: (this.state.data["problem"] !== "uninstallHow" && this.state.data["problem"] !== "cancelHow"), 
             id: "comments", value: this.state.data.comments, onChange: this.formChange.bind(this)},
            {type: "action", show: true, actions: [
              {id: "back", clsName:"default", content: this.getTranslatedText("backText"), onClick: this.onActionClick.bind(this), show: true, 
                align: (this.state.data["problem"] !== "uninstallHow" && this.state.data["problem"] !== "cancelHow") ? "right" : "left", width: 7}, 
              {id: "submit", clsName:"primary", content: this.getTranslatedText("submitText"), onClick: this.onActionClick.bind(this), 
                show: (this.state.data["problem"] !== "uninstallHow" && this.state.data["problem"] !== "cancelHow"), 
                align: "right", width: 2, validate: true}, 
            ]},
            
        ]
      }
      if(stages[this.state.currStage]) {
        return stages[this.state.currStage];
      }
      return [];
    }
    captchaCallback = (recaptchaToken) => {
      this.setState(c=> {
        c.data.captcha = recaptchaToken;
        return c;
      });
    }
    uninstallReasonClick(id) {
      this.setState(c=> {
        c.data.uninstallReasons[id] = !c.data.uninstallReasons[id];
        return c;
      });
    }
    oncontactTypeClick(id) {
      this.props.history.push(`${this.props.location.pathname}/${id}`);
    }
    onProblemClick(id) {
      this.props.history.push(`${this.props.location.pathname}/${id}`);
    }
    onActionClick(id) {
      if(id === "back") {
        this.props.history.goBack();
      } else if(id === "submit") {
        this.submitData();
      } else if(id === "submituninstall") {
        this.submitData();
      }
    }
    formChange(id, value) {
      this.setState(c=> {
        c.data[id] = value;
        c.messageState = "none";
        return c;
      });
    }
    renderForm() {
      var fields = this.getCurrFields();
      return (
        <Form className="contact-form-margin">
          <ContactForm fields={fields} show={true}/>
        </Form>         
      )
    }
    renderSuccess() {
      if(this.state.messageState === "success") {
        if(this.state.data["contactType"] === "feedback") {
          return (
            <Grid.Row className="page-subsection-padding">
              <Message success header='Submitted !' content="Thank you for your feedback" />
            </Grid.Row>
          );
        }
        else if(this.state.data["contactType"] === "support") {
          return (
            <Grid.Row className="page-subsection-padding">
              <Message success header='Submitted !' content="Thank you, we will get back to you soon!" />
            </Grid.Row>
          );
        }
        else {
          return (
            <Grid.Row className="page-subsection-padding">
              <Message success header='Submitted !' content="Thank you for your submission" />
            </Grid.Row>
          );
        }
      }
      return ("");
      //here we will render the success page
    }
    renderError() {
      if(this.state.messageState === "error") {
        return (
          <Grid.Row className="page-subsection-padding">
            <Message error header='Error in submission !' content="Please try again in a while" />
          </Grid.Row>
        );
      }
      return ("");
      //here we will render the success page
    }
    renderLoader() {
      if(this.state.isLoading) {
        return (
          <div className="ui inverted active dimmer">
            <div className="ui massive text loader">{this.state.loadingMessage}</div>
          </div>
        )
      }      
      return ("");
    }
    renderRecaptcha() {
      if(this.state.messageState === "success") {
        return ("");
      }
      if(this.state.currStage === "problemDesc" || (this.state.currStage === "problemType" && this.state.data.contactType === "uninstall")) {
        return (
          <ReCaptcha
          sitekey={this.recaptchaSitekey}
          action={this.state.data.contactType}
          verifyCallback={this.captchaCallback.bind(this)} />
        )
      }
      return ("");
    }
    renderHeader() {
      if(this.state.data.contactType === "uninstall") {
        return [
          <div>
            <PremiumSignupPopup open={this.state.openSignup} onClose={e => this.setState(c=> {c.openSignup = false; return c;})}/>
            <div>
              <Header as='h1' style={{fontSize: "2em"}}>
                  {this.getTranslatedText("heroUninstalledWhyTitle")}
              </Header>
              <table className="uninstalled-hero-table">
                <tr>
                  {this.getTranslatedText("heroUninstalledWhySubtitle1_1")}
                  <td>
                    {this.translateLang && !this.translateLang.includes("en") ? 
                      (<a href={'/lp?source=uninstall'}> {this.getTranslatedText("heroUninstalledWhySubtitle1_2")} </a>) :
                      (<a href='/lp?source=uninstall'> {this.getTranslatedText("heroUninstalledWhySubtitle1_2")} </a>)
                    }
                  </td>
                </tr>
                <tr>
                  <td>{this.getTranslatedText("heroUninstalledWhySubtitle2_1")}</td>
                  <td><a href='#' onClick={e => this.setState(c=> {c.openSignup = true; return c;})}>{this.getTranslatedText("heroUninstalledWhySubtitle2_2")}</a></td>
                </tr>
              </table>
              <style>{mediaStyles}</style>
            </div>
          </div>
        ]
      } else {
        var headerStr = this.getTranslatedText("heroContactTitle"); // "Contact Us";
        var subheaderStr = this.getTranslatedText("heroSupportSubtitle"); // "Please choose an option bellow:";
        if (this.state.data.contactType) {
          // title on selection question page or form page
            if(this.state.data.contactType === "feedback") {
              headerStr = this.getTranslatedText("heroFeedbackTitle"); //"We'd love your feedback!"
            } else if(this.state.data.contactType === "support"){
              if (this.state.data["problem"] === "uninstallHow") {
                headerStr = this.getTranslatedText("heroUninstallTitle"); //"Uninstall instructions"
              } else if (this.state.data["problem"] === "cancelHow") {
                headerStr = this.getTranslatedText("heroCancelTitle"); //"Subscription cancelation instructions"
              } else {
                headerStr = this.getTranslatedText("heroSupportTitle"); //<div>Thanks for installing browser extension<br/>Got a problem? We can help!</div>
              }
            }
            if (this.state.data["problem"]) {
              if (this.state.data["problem"] === "uninstallHow") {
                subheaderStr = this.getTranslatedText("heroUninstallSubtitle"); // "Please follow the steps below to uninstall browser extension:";
              } else if (this.state.data["problem"] === "cancelHow") {
                subheaderStr = this.getTranslatedText("heroCancelSubtitle"); // "Please follow the steps below to cancel the subscription:";
              } else{
                subheaderStr = this.getTranslatedText("heroProblemSubtitle"); // "Please fill in following fields:";
              }
            }
          }
        return [
          <div>
            <Header as='h1'>
              {headerStr}
            </Header>
            <div className="hero-top-margin hero-bottom-margin">
              {subheaderStr}
            </div>
        </div>
        ]
      }
    }
    renderBodyText() {
      let uninstallHowData= [
        {title: this.getTranslatedText("stepText") + " 1", description: this.getTranslatedText("uninstallStep1"), imgUrl: `/images/browser/${this.state.browserName}/uninstall_01.png`},
        {title: this.getTranslatedText("stepText") + " 2", description: this.getTranslatedText("uninstallStep2_1") + ` “${this.state.removeMessage}” ` + this.getTranslatedText("uninstallStep2_2"), imgUrl: `/images/browser/${this.state.browserName}/uninstall_02.png`},
        {title: this.getTranslatedText("stepText") + " 3", description: this.getTranslatedText("uninstallStep3"), imgUrl: `/images/browser/${this.state.browserName}/uninstall_03.png`},
      ];
      let cancelHowData= [
        {title: this.getTranslatedText("stepText") + " 1", description: this.getTranslatedText("cancelPremiumStep1"), imgUrl: `/images/cancel/cancel_01.png`},
        {title: this.getTranslatedText("stepText") + " 2", description: this.getTranslatedText("cancelPremiumStep2"), imgUrl: `/images/cancel/cancel_02.png`},
        {title: this.getTranslatedText("stepText") + " 3", description: this.getTranslatedText("cancelPremiumStep3"), imgUrl: `/images/cancel/cancel_03.png`},
        {title: this.getTranslatedText("stepText") + " 4", description: this.getTranslatedText("cancelPremiumStep4"), imgUrl: `/images/cancel/cancel_04.png`},
        {title: this.getTranslatedText("stepText") + " 5", description: this.getTranslatedText("cancelPremiumStep5"), imgUrl: `/images/cancel/cancel_05.png`},
        {title: this.getTranslatedText("stepText") + " 6", description: this.getTranslatedText("cancelPremiumStep6"), imgUrl: `/images/cancel/cancel_06.png`},
        {title: this.getTranslatedText("stepText") + " 7", description: this.getTranslatedText("cancelPremiumStep7"), imgUrl: `/images/cancel/cancel_07.png`},
        {title: this.getTranslatedText("stepText") + " 8", description: this.getTranslatedText("cancelPremiumStep8"), imgUrl: `/images/cancel/cancel_08.png`}
      ];
      if(this.state.data.contactType === "uninstall") {
        return [
          <div>
            <b>{this.getTranslatedText("heroUninstallWhyTitle")}</b>{/*We're sorry to see you go! Can you tell us why you're uninstalling?*/}
          </div>
        ]
      } else if (this.state.data.contactType && this.state.data["problem"]) {
        if (this.state.data["problem"] === "uninstallHow") {
          return (
            <div>
              <Grid className="stackable">
                {uninstallHowData.map(stepsData => {
                  return (
                    <Grid.Row className="page-section-padding">
                      <Grid.Column width={8}>
                        <Image size='large' centered={true} src={stepsData.imgUrl} />
                      </Grid.Column>
                      <Grid.Column width={8} className='vertical-middle'>
                        <p>
                          <span className='get-premium-subtitle'>{stepsData.title}</span>
                        </p>
                        <p>
                          {stepsData.description}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )
                })}
                
              </Grid>
            </div>
          );
        } else if (this.state.data["problem"] === "cancelHow") {
          return (
            <div>
              <Grid className="stackable">
                {cancelHowData.map(stepsData => {
                  return (
                    <Grid.Row className="page-section-padding">
                      <Grid.Column width={8}>
                        <Image size='large' centered={true} src={stepsData.imgUrl} />
                      </Grid.Column>
                      <Grid.Column width={8} className='vertical-middle'>
                        <p>
                          <span className='get-premium-subtitle'>{stepsData.title}</span>
                        </p>
                        <p>
                          {stepsData.description}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )
                })}
                
              </Grid>
            </div>
          );
        }
      } else return "";
    }

    render() {
      if(this.state.isLoading) {
        return (
          <div className="ui inverted active dimmer">
            <div className="ui massive text loader">{this.state.loadingMessage}</div>
          </div>
        )
      }
      return(
        <div>
          {this.renderHeader()}
          <div>
            {this.renderLoader()}
            {this.renderError()}
            {this.renderSuccess()}
            {this.renderBodyText()}
            {this.renderForm()}
            {this.renderRecaptcha()}
          </div>
      
        </div>
      );
    }
   }

   export default Contact