import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';

class Violation extends Component {
    constructor(props){
        super();
        this.size = 150;
        this.vImages = [];
        this.myImage = "";
    }

    drawManyImages(){
        let theArray = [];

        for(let i = 0; i < this.props.mimg.length; i++){
            theArray.push(<div key={i*17+9}>{this.props.mimg[i]}</div>)
        }
        return theArray;
    }

    imageSection(){
        if(this.props.mimg.length > 0){
            return (
                <div className="violation-section">
                    <div className="ui grid">{this.drawManyImages()}</div>
                </div>
            );
        }
    }

    render(){
        return (
            <div>
                <div className="violation-section">
                    <div><strong>{this.props.number + 1}.&nbsp;</strong></div>
                    <div>{this.props.actual}</div>
                </div>
                {this.imageSection()}
            </div>

        );
    }
}

export default Violation