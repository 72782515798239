import React, { Component } from 'react';
import { Button, Image} from 'semantic-ui-react';
import { PremiumUtil } from '../utils/PremiumUtil';
import axios from 'axios';
import { Settings } from '../Settings';
import { CommonUtil } from '../utils/CommonUtil';

// ToDo: quickly add translation
class InstallAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasExtension: true,
            hasPremium: false,
            adText: "",
            buttonText: ""
        };
        this.adTextObj = {
            hasPremium: 'Thanks for being our Premium customer',
            hasExtension: 'Please consider joining the fight against internet fraud',
            install: 'Want all deceptive apps and search results to be blurred?',
            verboseInstall: 'Want all deceptive apps and search results to be blurred? \n Install Browse.live Web Safety today and get safer browsing.',
            installButton: 'Install Browse.live Web Safety',
            upgradeButton: 'Upgrade to Premium',
        };
        this.translateLang = CommonUtil.lang;
    }

    componentDidMount() {
        this.checkBlurInstalled().then(() => {
            this.initTextOptions();
        });
    }
    componentDidUpdate(prevProps) {
        if (this.translateLang !== CommonUtil.lang) {
            this.translateLang = CommonUtil.lang;
            this.initTextOptions();
        }
    }

    initTextOptions() {
        if (this.state.hasPremium) {
            this.getAdText('hasPremium');
        } else if (this.state.hasExtension) {
            if (this.props.noPremOffer) {
                return "";
            } else {
                this.getAdText('hasExtension', 'upgradeButton');
            }
        } else {
            if (this.props.verboseDesc) {
                this.getAdText('verboseInstall', 'installButton');
            } else {
                this.getAdText('install', 'installButton');
            }
        }
    }

    getAdText(adType, buttonType) {
        if (!CommonUtil.IsEnglishLang()) {
            var data = {ad: this.adTextObj[adType], button: this.adTextObj[buttonType]};
            axios.post(Settings.TranslationURL, data, { 'params': {'fromLang': 'en', 'toLang': this.translateLang}}).then((response) => {
                this.setState({ adText: response.data.ad, buttonText: response.data.button });
            });
        } else {
            this.setState({ adText: this.adTextObj[adType], buttonText: this.adTextObj[buttonType] })
        }
    }

    checkBlurInstalled() {
        return PremiumUtil.CallWhatAmI().then(resp => {
            var doCheck = true;
            if(this.props.checkSupport && !PremiumUtil.CanShowInstall()) {
                doCheck = false;
            }
            if(doCheck) {
                PremiumUtil.CheckBlurInstalled().then((resp) => {
                    this.setState(c=> {
                        c.hasExtension = resp;
                        return c;
                    })
                }).catch((err) => {
                    this.setState(c=> {
                        c.hasExtension = false;
                        return c;
                    });
                })
                PremiumUtil.CheckBlurPremium().then((resp) => {
                    this.setState(c=> {
                        c.hasPremium = resp;
                        return c;
                    })
                }).catch((err) => {
                    this.setState(c=> {
                        c.hasPremium = false;
                        return c;
                    });
                })
            }
        }).catch(err => {});
    }
    installClick() {
        if(!CommonUtil.IsEnglishLang()) {
            PremiumUtil.InstallClick(this.props.fromPage, this.translateLang);
        } else {
            PremiumUtil.InstallClick(this.props.fromPage);
        }
    }
    upgradeClick() {
        if(!CommonUtil.IsEnglishLang()) {
            PremiumUtil.UpgradeClick(this.props.fromPage, this.translateLang);
        } else {
            PremiumUtil.UpgradeClick(this.props.fromPage);
        }
    }
    render() {
        let myclass = (this.props.showLogo && !this.props.hideDesc) ? "apps-hero-deceptor-cicle" : "";
        if(this.state.hasPremium) { // say thanks if we should offer premium
            return (
                <div className={myclass}>
                    {this.props.showLogo ? (<Image src='/images/Blur_logo.png' className="apps-hero-logo-img" />) : ("")}
                    {this.props.hideDesc ? ("") :(<div>{this.state.adText}</div>)}
                </div>
            )
        } else if(this.state.hasExtension) { // offer premium
            if(this.props.noPremOffer) {
                return "";
            } else {
                return (
                    <div className={myclass}>
                        {this.props.showLogo ? (<Image src='/images/Blur_logo.png' className="apps-hero-logo-img" />) : ("")}
                        {this.props.hideDesc ? ("") :(<div>{this.state.adText}</div>)}
                        <Button color='green' onClick={this.upgradeClick.bind(this)}>{this.state.buttonText}</Button>
                    </div>
                )
            }
        } else { // offer install
            if (this.props.verboseDesc) {
                return (
                    <div className='verbose-ad-card'>
                        {this.props.showLogo    ? (<Image src='/images/Blur_logo.png' className="apps-hero-logo-img" />) : ("")}
                        <div>
                            {this.state.adText}
                        </div>
                        <Button color='green' onClick={this.installClick.bind(this)}>{this.state.buttonText}</Button>
                    </div>
                )
            } else {
                return (
                    <div className={myclass}>
                        {this.props.showLogo ? (<Image src='/images/Blur_logo.png' className="apps-hero-logo-img" />) : ("")}
                        {this.props.hideDesc ? ("") :(<div>{this.state.adText}</div>)}
                        <Button color='green' onClick={this.installClick.bind(this)}>{this.state.buttonText}</Button>
                    </div>
                )
            }
        }
    }
}

export default InstallAd
